import {handleValidationChange} from "../Validations/CommonValidation";

export const validateAddEmploymentInformation = (data, setIsError) => {

    var isValid = true;
    if(data.alumni_employment_id === "" ) {
      handleValidationChange("alumni_employment_id", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("alumni_employment_id", false, setIsError);
      
    }

    if(data.institution_type === "") {
        handleValidationChange("institution_type", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("institution_type", false, setIsError);
        
    }

    if(data.sector === "") {
        handleValidationChange("sector", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("sector", false, setIsError);
        
    }

    if(data.company_institution === "") {
        handleValidationChange("company_institution", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("company_institution", false, setIsError);
        
    }

    // if(data.is_international === "" || data.valid_to === "") {
    //     handleValidationChange("is_international", true, setIsError);
    //     isValid = false;
    // } else {
    //     handleValidationChange("is_international", false, setIsError);
        
    // }

    if(data.position === "") {
        handleValidationChange("position", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("position", false, setIsError);
        
    }

    // if(data.specialization === "") {
    //     handleValidationChange("specialization", true, setIsError);
    //     isValid = false;
    // } else {
    //     handleValidationChange("specialization", false, setIsError);
        
    // }

    // if(data.monthly_salary === "") {
    //     handleValidationChange("monthly_salary", true, setIsError);
    //     isValid = false;
    // } else {
    //     handleValidationChange("monthly_salary", false, setIsError);
        
    // }

    if(data.type === "") {
        handleValidationChange("type", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("type", false, setIsError);
        
    }

    if(data.extent === "") {
        handleValidationChange("extent", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("extent", false, setIsError);
        
    }
    // if(data.description === "") {
    //     handleValidationChange("description", true, setIsError);
    //     isValid = false;
    // } else {
    //     handleValidationChange("description", false, setIsError);
        
    // }

    // if(data.is_current === "") {
    //     handleValidationChange("is_current", true, setIsError);
    //     isValid = false;
    // } else {
    //     handleValidationChange("is_current", false, setIsError);
        
    // }

    if(data.date_started === "") {
        handleValidationChange("date_started", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("date_started", false, setIsError);
        
    }

    // if(data.date_ended === "") {
    //     handleValidationChange("date_ended", true, setIsError);
    //     isValid = false;
    // } else {
    //     handleValidationChange("date_ended", false, setIsError);
        
    // }

    return isValid
  
}