import Navbar from "../../Components/Navbar/Navbar";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import dashhead from "../../Assets/Images/Dashboard/dashboard_head.png";
import { Navigate, useNavigate } from "react-router-dom";
import { getScholarship } from "../../ApiCalls/Scholarships/ScholarshipsApi";
import "./Scholarships.css";

function StudentCard({ setIsChecked, isChecked }) {
  const navigateto = useNavigate();
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [id, setId] = useState();
  const [scholarshipData, setScholarshipData] = useState([
    {
      id: id,
      name: "",
      budget: "",
      description: "",
      tag_1: "",
      tag_2: "",
      tag_3: "",
      tag_4: "",
      tag_5: ""
    },
  ]);

   function formatAmount (value) {
    const amount: number = value;
    const formattedAmount: string = amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedAmount;
   }

  async function fetchScholarship() {
    setScholarshipData([]);
    const response = await getScholarship(id);
    if (response.data) {
      setScholarshipData(response.data);
    }
  }

  useEffect(() => {
    fetchScholarship();
  }, []);

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, []);


  return (
    <div className="page">
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
      <div className="ml-100 pt-0">
        <div className="container pt-0 ml-0 pl-0 pr-0 ml--50">
          <div className="row mb-4 pl-0 pr-0 dash">
            <img src={dashhead} alt="dashboard-header" />
            <div className=" pt-0 dash-containers form-control top-br">
              <div className="row">
                <div className="row p-40">
                  <h1 className="title ms-5">SCHOLARSHIPS</h1>
                </div>
                <div className="cont"> </div>
              </div>
          
              <div className="row p-20 projects mb-5 ">
                <div className="row proj-container">
                  {scholarshipData.map((data, key) => {
                    return (
                      <div
                        className="col-4 dark-grey m-3 rounded"
                        key={key}
                        onClick={() =>
                          navigateto("/scholarship-details/" + data.id)
                        }
                      >
                        <Row className="mt-2">
                          {
                            (data.tag_1) ? (
                                <Col className="label-gold ms-1 me-1 mt-2">
                                  {data.tag_1}
                                </Col>
                            ) : (
                              <p></p>
                            )
                          }

                          {
                            (data.tag_2) ? (
                                <Col className="maroon-tag ms-1 me-1 mt-2">
                                  {data.tag_2}
                                </Col>
                            ) : (
                              <p></p>
                            )
                          }

                          {
                            (data.tag_3) ? (
                                <Col className="green-tag ms-1 me-1 mt-2">
                                  {data.tag_3}
                                </Col>
                            ) : (
                              <p></p>
                            )
                          }

                          {
                            (data.tag_4) ? (
                                <Col className="label-gold ms-1 me-1 mt-2">
                                  {data.tag_4}
                                </Col>
                            ) : (
                              <p></p>
                            )
                          }

                          {
                            (data.tag_5) ? (
                                <Col className="maroon-tag ms-1 me-1 mt-2">
                                  {data.tag_5}
                                </Col>
                            ) : (
                              <p></p>
                            )
                          }
                          
                        </Row>

                        <Row className="ms-1 mt-3 scholarship-name">
                          <h2>{data.name?data.name.toLocaleUpperCase():""}</h2>
                        </Row>

                        <Row className="ms-3">
                          PHP {data.budget?formatAmount(data.budget):""}
                        </Row>

                        <Row className="px-4 py-4">
                          {data.description}
                        </Row>
                        
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

StudentCard.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
};

export default StudentCard;
