import React, { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import { loginUser } from "ApiCalls/authApi";
import { validateLogin } from "Helpers/Validations/LoginValidation";
import InputError from "Components/InputError/InputError";
import { Col, Row } from "react-bootstrap";
import { refreshPage } from "Helpers/Utils/Common";
import { toastStyle } from "Helpers/Constants/StyleConstants";
import ModalPopUp from "Components/Modal/Modal";
import ReactLoading from "react-loading";
import { Navigate, useNavigate } from "react-router-dom";
import "./Login.css";

//images
import Logo3 from "../Assets/Images/Login/logo_3.png";
import upCebuLogo from "../Assets/Images/Login/up-cebu-logo.png";
import csLogo from "../Assets/Images/Login/cs-logo.png";
import Input from "Components/Input/Input";
import upaa from "../Assets/Images/Navbar/UPAA.png";

const Login: React.FC = () => {
  //States
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginCredentials, setLoginCredentials] = useState({
    username: "",
    password: "",
  });
  const navigateTo = useNavigate();
  const [isError, setError] = useState({
    username: false,
    password: false,
  });

  const [isClicked, setClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState("");
  const navigate = useNavigate();

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setLoginCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function clearCacheAndNavigate(url) {
    try {
      // Open the cache and delete it
      const cacheKeys = await caches.keys();
      await Promise.all(cacheKeys.map(key => caches.delete(key)));
  
      // Navigate to the desired URL
      navigateTo(url);
    } catch (error) {
      console.error("Error clearing cache:", error);
    }
  }

  async function submit() {
    setIsLoading(true)
    setTimeout(async function () {
      await clearCacheAndNavigate("/data-privacy");
    }, 800);
    
  }

  // function navigateTo(url) {
  //   // Your navigation logic here (e.g., window.location.href = url;)
  // }

  // async function submit() {
  //   navigateTo("/data-privacy");
  // }
  
  const handleCloseTab = () => {
    window.close();
  };

  const handleResetPassword= () => {
    setShowModal(false)
  }

  if (redirect === "login") {
    return <Navigate to="/personal-information" />;
}

if (redirect === "sign-up") {
  // localStorage.setItem("sign_up", JSON.stringify("sign-up"));
  return <Navigate to="/data-privacy-registration" />;
}

  return (
    <div>
      <div className="login-container">
        <div className="login-header ms-5">
          <img alt="login-logo2" src={upCebuLogo} className="login-logo" />
          <img alt="login-logo1" src={upaa} className="login-logo1" />
          {/* <img alt="login-logo3" src={csLogo} className="login-logo" /> */}
        </div>
        <div className="mt-5 header-label-cont d-flex justify-content-center">
          <h1 className="login-header-end">
          Congratulations and welcome to UP Alumni Association Cebu Chapter! 
          We will keep in touch with you on how you can complete your membership registration. 
          As a valued member, you will have access to a wide range of benefits and opportunities within our organization.
          </h1>
        </div>
        {/* <div className="input-cont">
          <Input
            type="username-login"
            name="username"
            placeholder={"Enter Student ID"}
            onChange={handleChange}
          />
          <InputError
            isValid={isError.username}
            message={"Username is required"}
          />
          <Input
            type="password-login"
            name="password"
            showPassword={showPassword}
            togglePassword={togglePassword}
            placeholder={"Enter Password"}
            onChange={handleChange}
          />
          <InputError
            isValid={isError.password}
            message={"Password is required"}
          />
        </div> */}
        {/* <div className="d-flex justify-content-center">
          <p className="forgot-password">
            Forgot Password?
          </p>
        </div> */}
        <div className="mt-3 d-flex justify-content-center d-none">
          <button className="login-btn" onClick={submit}>
          {isLoading ? 
              <div className="load">
                <ReactLoading
                type="spinningBubbles"
                color="#ff6700"
                height={28}
                width={30}
              />
              </div> :
              "Sign-Up Again"
            }
           
          </button>
          {/* <button className="login-btn" onClick={()=>window.open('','_self').close()}>
            Close
          </button> */}
        </div>
        {/* <div className="mt-3 d-flex justify-content-center">
          <label className="red" onClick={()=>setRedirect("sign-up")}>
            Sign up!
          </label>
        </div>
        <ModalPopUp
          type="reset-password"
          show={showModal}
          handleClose={() => setShowModal(false)}
        /> */}
      </div>
    </div>
  );
};

export default Login;