import { getUser, getDataId, getToken, getKey, removeSession, refreshPage } from "Helpers/Utils/Common";
import { postAPICall, postAPICallCustom } from "../axiosMethodCalls";

/***************************
 * USER
 ***************************/

const token = getToken();
const user = getUser();
// const id = getDataId();

//POST

export const getProjects= async (id) => {

  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "projects/get", {
          requester: user,
          token: token,
          project_id: id,
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}
export const createPledge = async (data) => {
      try {
          const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_pledges/add", {
              requester: user,
              token: token,  
              alumni_id: data.alumni_id,
              project_id: data.project_id?data.project_id:"",
              scholarship_id: data.scholarship_id?data.scholarship_id:"",
              in_kind: data.in_kind===true?1:0,
              in_kind_remarks: data.in_kind_remarks,
              monetary: data.monetary,
              anonymous: data.anonymous===true?1:0,
              status: data.status,
          });
          return ({data:response});   
      } catch (error) {
          if (error.response.status === 401) {
            removeSession();
            refreshPage();
          }
          return ({error: error.response});
      }
    }

    export const createProjectMessage = async (id, data) => {
          try {
              const response = await postAPICall(process.env.REACT_APP_LINK + "project_messages/send", {
                  requester: user,
                  token: token,  
                  project_id: id,
                  message: data.message,
              });
              return ({data:response});   
          } catch (error) {
              if (error.response.status === 401) {
                removeSession();
                refreshPage();
              }
              return ({error: error.response});
          }
        }


//POST
export const editUser = async (info, branches, file) => {
  var branchesData = [];

  branches.map((data) => (
    branchesData.push(data.value)
  ));
  try {
    const formData = new FormData();
    //Change param name according to the asked param from API
    formData.append("file_result", file[0]);
    const response = await postAPICallCustom(
      process.env.REACT_APP_LINK + "/login",
      {
        // requester: user,
        token: token,
        username: info.username,
        password: info.password,
        last_name: info.last_name,
        first_name: info.first_name,
        middle_name: info.middle_name,
        email: info.email_address,
        type: info.user_type,
        role_id: info.role,
        branch_ids: branchesData.join(","),
      },
      {
        "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
        // "user-key": userKey,
        "Content-Type": "multipart/form-data",
      },
    );

    return { data: response };
  } catch (error) {
    return { data: error.response.data };
  }
};