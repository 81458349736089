import { postAPICall } from "./axiosMethodCalls";
import { getBranch, getUser, getKey, getToken, refreshPage } from "../Helpers/Utils/Common";

/***************************
 * LOGIN
 ***************************/

//POST
export const loginUser = async (student_id, password) => {
  try {
    const response = await postAPICall(process.env.REACT_APP_LINK + "login", {
      student_id: student_id,
      password: password,
    });
    return { data: response};
  } catch (error) {
    return { data: error.response.data };
  }
};

/***************************
 * LOGOUT
 ***************************/

 export const logoutUser = async () => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "logout", {
          requester: getUser(),
          // current_branch: getBranch().replace(/['"]+/g, ''),
          // api_key: getKey().replace(/['"]+/g, ""),
          token: getToken().replace(/['"]+/g, ""),
          alumni_id: getUser().replace(/['"]+/g, ""),
      });
      refreshPage();
      localStorage.removeItem("user"); 
      return response
  } catch (error) {
      refreshPage()
      localStorage.removeItem("user");
      return ({error: error.response});
  }
}
// export const logoutUser = async () => {
//   try {
//       const response = await postAPICall(process.env.REACT_APP_LINK + 'logout', {
//           // requester: getUser(),
//           // current_branch: getBranch().replace(/['"]+/g, ''),
//           api_key: getKey().replace(/['"]+/g, ''),
//           token: getToken().replace(/['"]+/g, ''),
//       });
//       refreshPage();
//       localStorage.removeItem('user'); 
//       return response
//   } catch (error) {
//       refreshPage()
//       localStorage.removeItem('user');
//       return ({error: error.response});
//   }
// }