import Header from "../../Components/Header/Header";
import Navbar from "../../Components/Navbar/Navbar";
import PropTypes from "prop-types";
import {Row, Col, Container} from "react-bootstrap";
import { useNavigate, Navigate, useParams} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./StudentProfile.css"
import Table from "Components/Table/Table";
import { CSVLink } from "react-csv";

//assets
import dashhead from "../../Assets/Images/Dashboard/dashboard_head.png";
import userpic from "../../Assets/Images/Dashboard/user_profile.png";
import gradcap from "../../Assets/Images/Dashboard/GraduationCap.png";
import residence from "../../Assets/Images/Dashboard/HomePage.png";
import { getEmploymentInformation } from "ApiCalls/EmploymentInformationApi/EmploymentInformationApi";
import {getAcademicInformation} from "../../ApiCalls/AcademicInformationApi/AcademicInformationApi";
import { getUser, refreshPage } from "Helpers/Utils/Common";
import {getMembership} from "../../ApiCalls/MembershipApi/MembershipApi"
import {getPersonalInformation, updatePersonalInformation, getCurrentEmployment} from "../../ApiCalls/PersonalInformationApi/PersonalInformationApi";


function StudentProfile({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [selected, setSelected] = useState("");
  const [action, setAction] = useState({})
  const id = useParams()
  const [alumniId, setAlumniId] = useState(getUser())
  const [academicHistoryData, setacademicHistoryData] = useState([])
  const [employmentHistoryData, setEmploymentHistoryData] = useState([])
  const [membershipHistoryData, setMembershipHistoryData] = useState([])
  const [readyExcel, setReadyExcel] = useState(false)
  const [personalInformationDetails, setPersonalInformationDetails] = useState({
    preferred_title:"",
    married_surname: "",
    last_name: "",
    first_name: "",
    middle_name: "",
    suffix: "",
    birthdate: "",
    nationality: "",
    college_id: "",
    college_name:"",
    degree_program_name:"",
    degree: "",
    student_no: "",
    latin_award: "",
    month_graduated:"",
    year_graduated:"",
    semester_graduated: "",
    academic_year: "",
    year_conferred: "",
    scholarship: "",
    contact_no: "",
    personal_email: "",
    pronouns: "",
    permanent_address: "",
    permanent_country: "",
    permanent_province: "",
    permanent_city: "",
    permanent_postal_code: "",
    present_address: "",
    present_country: "",
    present_province: "",
    present_city: "",
    present_postal_code: "",
    facebook: "",
    twitter: "",
    instagram: "",
    linkedin: "",
    github: "",
    youtube: "",
    personal_website: ""
});

//GET API
async function fetchPersonalInformation(){
  const response = await getPersonalInformation(alumniId);
  if(response.data) {
  setPersonalInformationDetails(response.data);
  } 
  else{
  setPersonalInformationDetails(response.data);
  }
  }

const [employmentInformation, setEmploymentInformation] = useState({
  alumni_employment_id: id,
  institution_type: "",
  sector: "",
  company_institution: "",
  is_international: "",
  position: "",
  specialization: "",
  monthly_salary: "",
  type: "",
  extent: "",
  description: "",
  is_current: "",
  date_started: "",
  date_ended: "",
});

const [currentEmploymentInformation, setCurrentEmploymentInformation] = useState([{
  alumni_employment_id: id,
  institution_type: "",
  sector: "",
  company_institution: "",
  is_international: "",
  position: "",
  specialization: "",
  monthly_salary: "",
  type: "",
  extent: "",
  description: "",
  is_current: "",
  date_started: "",
  date_ended: "",
}]);
  
async function fetchCurrentEmployment(){
    const response = await getCurrentEmployment(alumniId);
    if(response.data){
      setCurrentEmploymentInformation(response.data)
    }
}

  const [membershipDetails, setMembershipDetails] = useState({
    alumni_org_id: id,
    name: "",
    type: "",
    is_international: "",
    membership_period: "",
    position: "",
});

async function fetchMembership() {
  const response = await getMembership(id.id,alumniId);
  if (response.data) {
    const res = response.data
    setMembershipHistoryData(res);
  } 
}
  async function fetchEmploymentInformation() {
    const response = await getEmploymentInformation(alumniId);
    if(response.data) {
      setEmploymentHistoryData(response.data);
      setReadyExcel(true)
    } 
  } 

async function fetchAcademicInformation() {
    const response = await getAcademicInformation(alumniId);
    if (response.data) {
      setacademicHistoryData(response.data);
      setReadyExcel(true)
    } 
}


  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);
    
    fetchPersonalInformation();
    fetchCurrentEmployment();
    fetchAcademicInformation();
    fetchEmploymentInformation();
    fetchMembership();
  }, []);

  return (
  <div className="page no-vertical-scroll">
    <Navbar
      onCollapse={(e) => {
        setInactive(e);
      }}
      active={"DASHBOARD"}
      setIsChecked={setIsChecked}
      isChecked={isChecked}
    />

    <div className="pt-0">
      <div className="container pt-0">
        
        <div className="row mb-4 dash">
          <img src={dashhead} alt="dashboard-header" />
          <div className="px-2 ml--10 pt-0 dash-containers form-control top-br">
              <Row className="profile-picture-container">
                <Col xs={12} sm={6} lg={3} className="center">
                  <img
                    className="student-profile-pic"
                    src={userpic}
                    alt="user-pic"
                  />
                </Col>
                <Col xs={12} sm={6} lg={9} className="profile-name">
                  <h1 className="title">
                    {personalInformationDetails.first_name
                      ? personalInformationDetails.first_name
                      : ""}{" "}
                    {personalInformationDetails.last_name
                      ? personalInformationDetails.last_name
                      : ""}
                  </h1>
                </Col>
              </Row>
            <hr />

            <Row className="rad dash-info m-1 center">
                PERSONAL INFORMATION
            </Row>
            <Row className="dark-grey m-1 mt-3 p-3">
                <p className="input-subtitle-grey">CONTACT NUMBER</p>
                <p className="input-subtitle">
                  {personalInformationDetails.contact_no
                    ? personalInformationDetails.contact_no
                    : ""}
                </p>

                <p className="input-subtitle-grey pt-3">EMAIL ADDRESS</p>
                <p className="input-subtitle">
                  {personalInformationDetails.personal_email
                    ? personalInformationDetails.personal_email
                    : ""}
                </p>

                <p className="input-subtitle-grey pt-3">SOCIAL MEDIA ACCOUNTS</p>
                <p className="input-subtitle">
                  {personalInformationDetails.facebook
                    ? personalInformationDetails.facebook
                    : ""}
                </p>
                <p className="input-subtitle">
                  {personalInformationDetails.instagram
                    ? personalInformationDetails.instagram
                    : ""}
                </p>
                <p className="input-subtitle">
                  {personalInformationDetails.twitter
                    ? personalInformationDetails.twitter
                    : ""}
                </p>
                <p className="input-subtitle">
                  {personalInformationDetails.linkedin
                    ? personalInformationDetails.linkedin
                    : ""}
                </p>
                <p className="input-subtitle">
                  {personalInformationDetails.github
                    ? personalInformationDetails.github
                    : ""}
                </p>
                <p className="input-subtitle">
                  {personalInformationDetails.youtube
                    ? personalInformationDetails.youtube
                    : ""}
                </p>
                <p className="input-subtitle">
                  {personalInformationDetails.personal_website
                    ? personalInformationDetails.personal_website
                    : ""}
                </p>

                <p className="input-subtitle-grey pt-3">PRONOUNS</p>
                <p className="input-subtitle">
                  {personalInformationDetails.pronouns
                    ? personalInformationDetails.pronouns
                    : ""}
                </p>  
            </Row>

            <Row className="rad dash-info m-1 center">
                EDUCATIONAL BACKGROUND
            </Row>
              <Row className="dark-grey m-1 mt-3 p-3">
                <p className="input-subtitle-grey pt-3">COLLEGE/SCHOOL</p>
                <p className="input-subtitle">
                  {personalInformationDetails.college_name
                    ? personalInformationDetails.college_name.toUpperCase()
                    : ""}
                </p>

                <p className="input-subtitle-grey pt-3">DEGREE/CERTIFICATE</p>
                <p className="input-subtitle">
                  {personalInformationDetails.degree_program_name
                    ? personalInformationDetails.degree_program_name.toUpperCase()
                    : ""}
                </p>

                <p className="input-subtitle-grey pt-3">STUDENT NUMBER</p>
                <p className="input-subtitle">
                  {personalInformationDetails.student_no
                    ? personalInformationDetails.student_no
                    : ""}
                </p>

                <p className="input-subtitle-grey pt-3">LATIN AWARD</p>
                <p className="input-subtitle">
                  {personalInformationDetails.latin_award
                    ? personalInformationDetails.latin_award.toUpperCase()
                    : ""}
                </p>

                <p className="input-subtitle-grey pt-3">SEMESTER GRADUATED</p>
                <p className="input-subtitle">
                  {personalInformationDetails.month_graduated
                    ? personalInformationDetails.month_graduated +
                      " " +
                      personalInformationDetails.year_graduated
                    : ""}
                </p>

                <p className="input-subtitle-grey pt-3">ACADEMIC YEAR</p>
                <p className="input-subtitle">
                  {personalInformationDetails.academic_year
                    ? personalInformationDetails.academic_year
                    : ""}
                </p>

                <p className="input-subtitle-grey pt-3">YEAR CONFERRED</p>
                <p className="input-subtitle">
                  {personalInformationDetails.year_conferred
                    ? personalInformationDetails.year_conferred
                    : ""}
                </p>
              </Row>

            <Row className="rad dash-info m-1 center">
                RESIDENTIAL INFORMATION
            </Row>
              <Row className="dark-grey m-1 mt-3 p-3">
                <Col xs={12} sm={6}>
                    <p className="input-subtitle-grey pt-3">PRESENT ADDRESS</p>
                    <p className="input-subtitle">
                      {personalInformationDetails.present_address
                        ? personalInformationDetails.present_address
                        : ""}
                    </p>

                    <p className="input-subtitle-grey pt-3">COUNTRY</p>
                    <p className="input-subtitle">
                      {personalInformationDetails.present_country
                        ? personalInformationDetails.present_country
                        : ""}
                    </p>

                    <p className="input-subtitle-grey pt-3">STATE/PROVINCE</p>
                    <p className="input-subtitle">
                      {personalInformationDetails.present_province
                        ? personalInformationDetails.present_province
                        : ""}
                    </p>

                    <p className="input-subtitle-grey pt-3">CITY</p>
                    <p className="input-subtitle">
                      {personalInformationDetails.present_city
                        ? personalInformationDetails.present_city
                        : ""}
                    </p>

                    <p className="input-subtitle-grey pt-3">POSTAL CODE</p>
                    <p className="input-subtitle">
                      {personalInformationDetails.present_postal_code
                        ? personalInformationDetails.present_postal_code
                        : ""}
                    </p>
                </Col>
                <Col xs={12} sm={6}>
                    <p className="input-subtitle-grey pt-3">PERMANENT ADDRESS</p>
                    <p className="input-subtitle">
                      {personalInformationDetails.permanent_address
                        ? personalInformationDetails.permanent_address
                        : ""}
                    </p>

                    <p className="input-subtitle-grey pt-3">COUNTRY</p>
                    <p className="input-subtitle">
                      {personalInformationDetails.permanent_country
                        ? personalInformationDetails.permanent_country
                        : ""}
                    </p>

                    <p className="input-subtitle-grey pt-3">STATE/PROVINCE</p>
                    <p className="input-subtitle">
                      {personalInformationDetails.permanent_province
                        ? personalInformationDetails.permanent_province
                        : ""}
                    </p>

                    <p className="input-subtitle-grey pt-3">CITY</p>
                    <p className="input-subtitle">
                      {personalInformationDetails.permanent_city
                        ? personalInformationDetails.permanent_city
                        : ""}
                    </p>

                    <p className="input-subtitle-grey pt-3">POSTAL CODE</p>
                    <p className="input-subtitle">
                      {personalInformationDetails.permanent_postal_code
                        ? personalInformationDetails.permanent_postal_code
                        : ""}
                    </p>
                </Col>
              </Row>

            <Row className="dash-sub-info mt-4 m-2">
                CURRENT EMPLOYMENT INFORMATION
            </Row>
            <Row className="mt-3 m-1">
              {currentEmploymentInformation ? (
                currentEmploymentInformation.map((data, index) => (
                  <div className="col-12 rad light-grey p-4 mb-3" key={index}>
                    <div className="row">
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          TYPE OF INSTITUTION
                        </p>
                        <p className="input-subtitle">
                          {data.institution_type ? data.institution_type : " "}
                        </p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">SECTOR</p>
                        <p className="input-subtitle">
                          {data.sector ? data.sector : " "}
                        </p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          COMPANY/INSTITUTION
                        </p>
                        <p className="input-subtitle">
                          {data.company_institution ? data.company_institution : " "}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          POSITION/RANK
                        </p>
                        <p className="input-subtitle">
                          {data.position ? data.position : " "}
                        </p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          SPECIALIZATION
                        </p>
                        <p className="input-subtitle">
                          {data.specialization ? data.specialization : " "}
                        </p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          MONTHLY SALARY RATE
                        </p>
                        <p className="input-subtitle">
                          {data.monthly_salary ? data.monthly_salary : " "}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          TYPE OF EMPLOYMENT
                        </p>
                        <p className="input-subtitle">
                          {data.type ? data.type : " "}
                        </p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          EXTENT OF EMPLOYMENT
                        </p>
                        <p className="input-subtitle">
                          {data.extent ? data.extent : " "}
                        </p>
                      </div>
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          JOB DESCRIPTION
                        </p>
                        <p className="input-subtitle">
                          {data.description ? data.description : " "}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <p className="input-subtitle-grey pt-3">
                          PERIOD OF EMPLOYMENT
                        </p>
                        <p className="input-subtitle">
                          {`${data.date_started ? data.date_started : ""}${
                            data.date_ended ? " - " + data.date_ended : ""
                          }`}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>NO DATA</p>
              )}
            </Row>

            <Row className="dash-sub-info mt-4 m-2">
                EMPLOYMENT HISTORY
            </Row>
              <Row className="rad m-1">
                <Table
                  type={"student_profile_employment"}
                  tableData={employmentHistoryData}
                  clickable={false}
                  headingColumns={[
                    "COMPANY/INSTITUTION",
                    "POSITION/RANK",
                    "START DATE",
                    "END DATE",
                  ]}
                  setID={setSelected}
                  setAction={setAction}
                  useLoader={false}
                  isReady={true}
                />
              </Row>

            <Row className="dash-sub-info mt-4 m-2">
                ACADEMIC INFORMATION
            </Row>
              <Row className="rad m-1">
                <Table
                  type={"student_profile_academic"}
                  tableData={academicHistoryData}
                  clickable={false}
                  headingColumns={[
                    "COMPANY/INSTITUTION",
                    "POSITION/RANK",
                    "START DATE",
                    "END DATE",
                  ]}
                  setID={setSelected}
                  setAction={setAction}
                  useLoader={false}
                  isReady={isReady}
                />
              </Row>

            <Row className="dash-sub-info mt-4 m-2">
                MEMBERSHIP IN ORGANIZATIONS
            </Row>
              <Row className="rad m-1">
                <Table
                  type={"student_profile_membership"}
                  tableData={membershipHistoryData}
                  clickable={false}
                  headingColumns={[
                    "NAME OF ORGANIZATION",
                    "TYPE",
                    "PERIOD OF MEMBERSHIP",
                    "POSITION",
                  ]}
                  setID={setSelected}
                  setAction={setAction}
                  useLoader={false}
                  isReady={isReady}
                />
              </Row>
          </div>
        </div>
      </div>
    </div>
  </div>
);
}

StudentProfile.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
  setAction: PropTypes.any,
  action: PropTypes.bool,
};

export default StudentProfile;
