import { getUser, getDataId, getToken, getKey, removeSession, refreshPage } from "Helpers/Utils/Common";
import { postAPICall, postAPICallCustom } from "../axiosMethodCalls";

/***************************
 * USER
 ***************************/

const token = getToken();
const user = getUser();
// const id = getDataId();

//POST

export const getScholarship= async (id) => {

  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "scholarships/get", {
          requester: user,
          token: token,
          scholarship_id: id,
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

    export const createScholarshipMessage = async (id, data) => {
          try {
              const response = await postAPICall(process.env.REACT_APP_LINK + "scholarship_messages/send", {
                  requester: user,
                  token: token,  
                  scholarship_id: id,
                  message: data.message,
              });
              return ({data:response});   
          } catch (error) {
              if (error.response.status === 401) {
                removeSession();
                refreshPage();
              }
              return ({error: error.response});
          }
        }


//POST
export const editUser = async (info, branches, file) => {
  var branchesData = [];

  branches.map((data) => (
    branchesData.push(data.value)
  ));
  try {
    const formData = new FormData();
    formData.append("file_result", file[0]);
    const response = await postAPICallCustom(
      process.env.REACT_APP_LINK + "/login",
      {
        // requester: user,
        token: token,
        username: info.username,
        password: info.password,
        last_name: info.last_name,
        first_name: info.first_name,
        middle_name: info.middle_name,
        email: info.email_address,
        type: info.user_type,
        role_id: info.role,
        branch_ids: branchesData.join(","),
      },
      {
        "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
        // "user-key": userKey,
        "Content-Type": "multipart/form-data",
      },
    );

    return { data: response };
  } catch (error) {
    return { data: error.response.data };
  }
};