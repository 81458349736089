import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { getHasExitInterview, getSignup } from "Helpers/Utils/Common";
import { Row, Col } from "react-bootstrap";
import "./DataPrivacy.css";

//images
import upCebuLogo from "../../Assets/Images/Login/up-cebu-logo.png";
import csLogo from "../../Assets/Images/Login/cs-logo.png";
import Logo3 from "../../Assets/Images/Login/logo_3.png";
import Back from "../../Assets/Images/Login/exit.png";
import upaa from "../../Assets/Images/Navbar/UPAA.png";
import { toast, ToastContainer } from "react-toastify";

function DataPrivacy() {
  const navigateto = useNavigate();
  const hasExitInterview = getHasExitInterview();
  const [redirect, setRedirect] = useState("");
  const [checked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const checkboxHandler = () => {
    setIsChecked(!checked);
  };
  // const handleParagraphClick = () => {
  //   setIsChecked(!checked);
  // };
  console.log(checked);
  function btnHandler() {
    
    if (checked === false) {
      toast.error("PLEASE AGREE TERMS!");
    } else {
      setIsLoading(true)
      setTimeout(function () {
        navigateto("/registration-form");
      }, 800);
      
      
      // if (hasExitInterview === 1) {
       
      // } 
      // else if (getSignup() === "sign-up") {
        
      // } else {
      //   navigateto("/exit-interview");
      // }
    }
  }

  if (redirect === "back") {
    return <Navigate to="/login" />;
  }

  return (
    <div className="page">
      <ToastContainer />
      <div className="privacy">
        {/* <Row className="justify-content-end">
          <Col xs={10}></Col>
          <Col xs={2}>
            <img
              alt="exit-icon"
              src={Back}
              className="exit-icon"
              onClick={() => setRedirect("back")}
            ></img>
          </Col>
        </Row> */}
        <div className="login-header ms-5">
          <img alt="login-logo2" src={upCebuLogo} className="login-logo" />
          <img alt="login-logo1" src={upaa} className="login-logo1" />
          {/* <img alt="login-logo3" src={csLogo} className="login-logo" /> */}
        </div>
        <div className="mt-3 input-cont d-flex justify-content-center">
          <div className="row">
            <h1 className="login-header-label mb-0">DATA PRIVACY CONSENT</h1>
          </div>
        </div>
        <div className="row center mt-0">
          <div className="col-auto center">
            <div className="input-group mb-2 center">
              <div className="input-group-prepend center">
                <input
                  type="checkbox"
                  className="c-box btns mt-0 center ms-3 me-3"
                  id="checked"
                  checked={checked}
                  onChange={checkboxHandler}
                />
              </div>
              <div className="col-8">
                <p
                  className="center c-box mt-4"
                  onClick={checkboxHandler}
                  style={{ cursor: "pointer" }}
                >
                  By signing this consent form, I/we grant my/our free, voluntary and unconditional
                  consent to the collection and processing of all Personal Data (as defined below), and
                  account or transaction information or records (collectively, the Information) relating
                  to me/us disclosed/transmitted by me/us in person or by my/our authorized
                  agent/representative/s to the information database system of the UP Alumni Association
                  Cebu Chapter.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-0 d-flex justify-content-center mt-0">
            
          <button className="proceed-btn mt-4" onClick={btnHandler}>
            {isLoading ? 
              <div className="load">
              <ReactLoading
              type="spinningBubbles"
              color="#ff6700"
              height={28}
              width={30}
            />
            </div> :
              "PROCEED"
            }
            
          </button>

        </div>
      </div>
    </div>
  );
}

export default DataPrivacy;
