import {handleValidationChange} from "../Validations/CommonValidation";

export const validateAddAcademicInformation = (data, setIsError) => {

    var isValid = true;
    if(data.alumni_academic_id === "" || data.alumni_academic_id === "") {
      handleValidationChange("alumni_academic_id", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("alumni_academic_id", false, setIsError);
      
    }

    if(data.institution === "") {
        handleValidationChange("institution", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("institution", false, setIsError);
        
    }

    if(data.degree === "") {
        handleValidationChange("degree", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("degree", false, setIsError);
        
    }

    // if(data.specialization === "") {
    //     handleValidationChange("specialization", true, setIsError);
    //     isValid = false;
    // } else {
    //     handleValidationChange("specialization", false, setIsError);
        
    // }

    // if(data.scholarship_granted === "" || data.valid_to === "") {
    //     handleValidationChange("scholarship_granted", true, setIsError);
    //     isValid = false;
    // } else {
    //     handleValidationChange("scholarship_granted", false, setIsError);
        
    // }

    // if(data.is_current === "") {
    //     handleValidationChange("is_current", true, setIsError);
    //     isValid = false;
    // } else {
    //     handleValidationChange("is_current", false, setIsError);
        
    // }

    // if(data.date_started === "") {
    //     handleValidationChange("date_started", true, setIsError);
    //     isValid = false;
    // } else {
    //     handleValidationChange("date_started", false, setIsError);
        
    // }

    // if(data.date_ended === "") {
    //     handleValidationChange("date_ended", true, setIsError);
    //     isValid = false;
    // } else {
    //     handleValidationChange("date_ended", false, setIsError);
        
    // }

    return isValid
  
}