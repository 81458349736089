import { getUser, getDataId , getToken, getKey, removeSession, refreshPage } from "Helpers/Utils/Common";
import { useState } from "react";
import { postAPICall, postAPICallCustom } from "../axiosMethodCalls";

/***************************
 * USER
 ***************************/
 

const token = getToken();
const user = getUser();
const id = getDataId();
//POST
export const createExitInterview = async (responses, plans, data) => {

    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_interviews/add", {
            requester: user,
            // token: token,
            token: token,  
            alumni_id: user,
            date: data.date,
            plan1: data.plan1,
            plan2: data.plan2,
            plan3: data.plan3,
            experience: data.experience,
            challenge: data.challenge,
            remarks: data.remarks,
            ...responses,
            ...plans
            
        });

        return ({data:response});   
    } catch (error) {
        if (error.response.status === 401) {
          removeSession();
          refreshPage();
        }
        return ({error: error.response});
    }
  }

export const getExitInterview = async () => {

  try {
    
      const response = await postAPICall(process.env.REACT_APP_LINK + "interview_questions/get", {
          requester: user,
          token: token,
        //   alumni_id: user,
      });
      return ({data:response.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const deleteExitInterview = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_interviews/delete", {
          requester: user,
          token: token,
          alumni_id: user,
          
          // package_type_id: id,
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        // toast.error(
        //   "Unauthorized access: another device is using this account"
        // );
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

