import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { removeSession, removeUserSession } from "../../Helpers/Utils/Common";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getName, getType, getRole, getHasExitInterview } from "../../Helpers/Utils/Common";
import MenuItem from "./MenuItem";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
//css
import "../Navbar/Navbar.css";

//icons
import logo from "../../Assets/Images/Login/cs-logo.png";
import home from "../../Assets/Images/Navbar/home.png";
import user from "../../Assets/Images/Navbar/user.png";
import projects from "../../Assets/Images/Navbar/projects.png";
import scholarships from "../../Assets/Images/Navbar/scholarships.png";
import burger from "../../Assets/Images/Navbar/burger-bar.png"
import logout from "../../Assets/Images/Navbar/logout.png";
import { logoutUser } from "ApiCalls/authApi";

interface NavProp {
  onCollapse: any;
  active: string;
  setIsChecked: any;
  isChecked: boolean;
}

export default function Navbar(props: NavProp) {
  const[redirectionPage, setRedirectionPage] = useState("");
  const hasExitInterview = getHasExitInterview();
  
  React.useEffect (() => {
    if (hasExitInterview === 1) {
      setRedirectionPage("/employment-information");
    } else {
      setRedirectionPage("/exit-interview");
    }
  }, []);

  const adminItems = [
    {
      name: "HOME",
      exact: true,
      to: "/employment-information",
      iconClassName: home,
      subMenus: [],
      expand: true,
    },
    {
      name: "PROFILE",
      exact: true,
      to: "/student-profile",
      iconClassName: user,
      subMenus: [],
      expand: true,
    },
    // {
    //   name: "PROJECTS",
    //   exact: true,
    //   to: "/projects",
    //   iconClassName: projects,
    //   subMenus: [],
    //   expand: true,
    // },
    // {
    //   name: "SCHOLARSHIP",
    //   exact: true,
    //   //to: "/new-user-form",
    //   to: "/scholarships",

    //   iconClassName: scholarships,
    //   subMenus: [],
    //   expand: true,
    // },
  ];

  const [inactive, setInactive] = useState(true);
  const [menuItems, setMenuItems] = useState(adminItems);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [redirect, setRedirect] = useState("");


  const handleExpand = (index) => {
    var list = [...menuItems];

    if (!inactive) {
      for (var i = 0; i < list.length; i++) {
        if (i !== index) {
          list[i].expand = true;
        }
      }

      list[index].expand = !list[index].expand;

      setMenuItems(list);
    }
  };

  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".sub-menu").forEach((el) => {
      el.classList.remove("active");
    });
  };

  useEffect(() => {
    if (inactive) {
      removeActiveClassFromSubMenu();
    }

    props.onCollapse(inactive);
  }, [inactive]);

  useEffect(() => {
    /** INSERT CONDITIONS BELOW FOR THE USER RESTRICTED NAVBAR ITEM
     * 
     * 
     * 
     const role = getRole();
     const type = getType();

    if (role === "Admin" && type === "manager") {
      setMenuItems(adminManagerItems);
    } else if (role === "Admin" && type === "staff") {
      setMenuItems(adminStaffItems);
    } else if (role === "Finance" && type === "manager") {
      setMenuItems(financeManagerItems);
    } else if (role === "Finance" && type === "staff") {
      setMenuItems(financeStaffItems);
    } else if (role === "Operations" && type === "manager") {
      setMenuItems(operationsManagerItems);
    } else if (role === "Operations" && type === "staff") {
      setMenuItems(operationsStaffItems);
    } else if (role === "BU head") {
      setMenuItems(buHeadItems);
    }  
    
    **/

    let menuItemsList = document.querySelectorAll(".menu-item");
    menuItemsList.forEach((el, index) => {
      if (menuItems[index].name === props.active) {
        el.classList.add("active");
      }
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItemsList.forEach((item) => item.classList.remove("active"));
        el.classList.toggle("active");
        if (next !== null) {
          next.classList.toggle("active");
        }
      });
    });
  }, []);

  if (redirect === "logout") {
    logoutUser();
    removeSession()
    return <Navigate to="/login" />;
}
  return (
    <div>
      <div className={` side-logo ${inactive ? "inactive side-menu new-menu" : "side-menu"}`}>
        <div className="top-section">
          <div className="logo logo-cont">
            <Row>
              <Col sm={5} onClick={() => setInactive(!inactive)}>
                <img alt={"navbar-logo"} src={logo} className="navbar-logo" />
              </Col>
              <Col sm={6} className={"mt-3"}>
                <span className="side-menu-title">Alumni Tracker</span>
              </Col>
            </Row>
            <br />
          </div>
        </div>
        <div className={inactive ? "main main-menu" : "main-menu active-menu"}>
          {menuItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              name={menuItem.name}
              exact={menuItem.exact.toString()}
              to={menuItem.to}
              subMenus={menuItem.subMenus || []}
              iconClassName={menuItem.iconClassName}
              expandManage={menuItem.expand}
              setExpandManage={handleExpand}
              index={index}
              activeSub={menuItem.name === props.active}
              onClick={() => {
                if (inactive) {
                  setInactive(false);
                }
              }}
            />
          ))}
        </div>
        <div className={inactive ? "side side-menu-footer" : "side-menu-footer"}>
          <hr className="line" />
          <div className="logout-cont">
            <img alt={"logout-icon"} src={logout} className="logout-btn"  onClick={()=>setRedirect("logout")}/>
            <span className="logout-label">LOG OUT</span>
          </div>
        </div>
      </div>
    </div>
  );
}

//DEFINE DATA TYPES
Navbar.propTypes = {
  props: PropTypes.any,
};
