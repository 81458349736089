import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import InputError from "../../Components/InputError/InputError";

//Import Common
import { getUser, refreshPage } from "Helpers/Utils/Common";

//Import Validations
import { validateAddMembership } from "../../Helpers/Validations/MembershipValidation";

//Import APICalls
import {
  createMembership,
  deleteMembership,
  getMembership,
  updateMembership,
} from "../../ApiCalls/MembershipApi/MembershipApi";

//Import from Components
import Navbar from "../../Components/Navbar/Navbar";
import Table from "Components/Table/Table";
import ModalPopUp from "Components/Modal/Modal";

//Import .CSS
import "./MembershipAndOrg.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

function MembershipAndOrg({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [id, setId] = useState();
  const [alumniId, setAlumniId] = useState(getUser());
  const navigateTo = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [membershipHistoryData, setMembershipHistoryData] = useState([]);
  const [membershipData, setMembershipData] = useState({});
  const [membershipDatas, setMembershipDatas] = useState({});

  const [membershipDetails, setMembershipDetails] = useState({
    alumni_org_id: id,
    name: "",
    type: "",
    is_international: "",
    membership_period: "",
    position: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  //GET API
  async function fetchMembership() {
    setIsLoading(true);
    const response = await getMembership();
    if (response.data) {
      const res = response.data
        .filter((details) => details.alumni_id.includes(getUser()))
        .map((info) => {
          return info;
        });
      setMembershipHistoryData(res);
      if (res.length > 0) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    } else {
      setIsLoading(true);
    }
  }

  async function Membership(id) {
    const response = await getMembership(id);
    if (response.data) {
      setMembershipData(response.data);
      setShowModalUpdate(true);
    }
  }
  async function Memberships(id) {
    const response = await getMembership(id);
    if (response.data) {
      setMembershipDatas(response.data);
      // academicData = response.data;
      setShowModal(true);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMembershipDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [isError, setIsError] = useState({
    alumni_org_id: false,
    name: false,
    type: false,
    is_international: false,
    membership_period: false,
    position: false,
  });

  const handleUpdateChange = (e) => {
    setMembershipData({ ...membershipData, [e.target.name]: e.target.value });
    setMembershipDatas({ ...membershipData, [e.target.name]: e.target.value });
  };
  
  //ADD API
  async function add() {
    if (validateAddMembership(membershipDetails, setIsError) && !isClicked) {
      setIsClicked(true);
      const response = await createMembership(membershipDetails);
      if (response.data && response.data.status === 200) {
        toast.success(response.data.data.response.toUpperCase());
        toast.success("MEMBERSHIP IS SUCCESSFULLY ADDED!");
      } else {
        setIsClicked(false);
        toast.error(response.error.data.messages.error.toUpperCase());
        toast.success("FAILED TO ADD MEMBERSHIP!");
      }
      setTimeout(function () {
        refreshPage();
      }, 500);
    }
  }

  //UPDATE API
  async function update() {
    setIsClicked(true);
    const response = await updateMembership(id, membershipData);
    if (response.data && response.data.status === 200) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.messages.error.toUpperCase());
    }
    setTimeout(function () {
      refreshPage();
    }, 500);
    // }
  }

  async function del() {
    const response = await deleteMembership(id);
    if (response.data?.status === 200) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.response.toUpperCase());
    }
    setShowModal(false);
    setTimeout(function () {
      refreshPage();
    }, 500);
  }

  useEffect(() => {
    fetchMembership();
  }, []);

  const handleSelectChange = (e) => {
    const { id, name, value } = e.target;
    setId(id);
    if (value === "update") {
      Membership(id);
    }
    if (value === "delete") {
      Memberships(id);
    }
  };

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, []);

  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
      <div className="login-container">
        <div className="form-cont pt-4">
          <div className="container p-3">
            <h1 className="title">MEMBERSHIP IN ORGANIZATIONS</h1>
            <Row>
              <Col xs={12} sm={6} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    NAME OF ORGANIZATION<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="name"
                    className="form-control input-subtitle input-shadow"
                    id="company"
                    aria-describedby="company"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.name}
                    message={"This field is required"}
                  />

                  <input
                    type="checkbox"
                    className="custom-control-inpu mr-10 mt-3"
                    id="international_company"
                    name="is_international"
                    onChange={(e) => handleChange(e)}
                  />
                  <label
                    className="custom-control-label input-title ms-2"
                    htmlFor="purchase-limit"
                  >
                    INTERNATIONAL ORGANIZATION
                  </label>
                </div>
              </Col>
              <Col xs={12} sm={6} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    TYPE OF ORGANIZATION<span className="required-icon">*</span>
                  </p>
                  <select
                    className="form-control input-subtitle  input-shadow"
                    name="type"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select</option>
                    <option value="freelance">Freelance</option>
                    <option value="government">Government</option>
                    <option value="nga">NGA</option>
                    <option value="ngo">NGO</option>
                    <option value="private_company">Private Company</option>
                    <option value="personal_business">Personal Business</option>
                  </select>
                  <InputError
                    isValid={isError.type}
                    message={"This field is required"}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    PERIOD OF MEMBERSHIP<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="membership_period"
                    className="form-control input-subtitle input-shadow"
                    id="membership_period"
                    aria-describedby="membership_period"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.membership_period}
                    message={"This field is required"}
                  />
                  {/* <DatePicker className="form-control input-subtitle input-shadow input-green-bg" selected={startDate} onChange={(date) => {setStartDate(date); setMembershipDetails({...membershipDetails, membership_period:new Date(date).toLocaleDateString("en-CA")});}} /> */}
                </div>
              </Col>
              <Col xs={12} sm={6} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    POSITION<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="position"
                    className="form-control input-subtitle input-shadow"
                    id="specialization"
                    aria-describedby="specialization"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.position}
                    message={"This field is required"}
                  />
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center mt-5">
              <Col xs={3}>
                <button
                  className="btn-green btn-rad py-2"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={add}
                >
                  ADD
                </button>
              </Col>
            </Row>

            <hr></hr>
            <div className="row row-form mb-4">
              <h2 className="title">HISTORY</h2>
              <Table
                type={"membership_po"}
                tableData={membershipHistoryData}
                clickable={false}
                headingColumns={[
                  "NAME OF ORGANIZATION",
                  "TYPE",
                  "PERIOD OF MEMBERSHIP",
                  "POSITION",
                  "ACTION",
                ]}
                onSelectChange={handleSelectChange}
                useLoader={isLoading}
                isReady={isReady}
              />
            </div>
            <Row className="mb-5">
              <div className="col mb-4 right">
                <button
                  className="back-btn btn-rad right me-2 w-10"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => navigateTo("/certifications-information")}
                >
                  BACK
                </button>
                <button
                  className="btn-green btn-rad right w-10"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => navigateTo("/social-media")}
                >
                  NEXT
                </button>
              </div>
            </Row>
          </div>
        </div>
        <ModalPopUp
          type="delete-membership"
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleDelete={del}
          data={membershipDatas}
          setData={handleUpdateChange}
        />
        <ModalPopUp
          type="update-membership"
          show={showModalUpdate}
          handleClose={() => setShowModalUpdate(false)}
          handleUpdate={update}
          data={membershipData}
          setData={handleUpdateChange}
        />
      </div>
    </div>
  );
}

MembershipAndOrg.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
  action: PropTypes.any,
};

export default MembershipAndOrg;
