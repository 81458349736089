import {handleValidationChange} from "../Validations/CommonValidation";

export const validateAddExitInterviewInformation = (data, setIsError) => {
console.log(data)
    var isValid = true;
    if(data.remarks === "" ) {
      handleValidationChange("remarks", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("remarks", false, setIsError);
      
    }

    if(data.challenge === "") {
        handleValidationChange("challenge", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("challenge", false, setIsError);
        
    }

    if(data.experience === "") {
        handleValidationChange("experience", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("experience", false, setIsError);
        
    }

    if(data.responses === "") {
        handleValidationChange("responses", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("responses", false, setIsError);
        
    }
    if(data.length >= 1) {
        data.map((data) => {
            if(data.response === "") {
                handleValidationChange("optionalInfo", true, setIsError);
                isValid=false;
            } else {
                handleValidationChange("optionalInfo", false, setIsError);
            }
        })
    }

    return isValid
  
}