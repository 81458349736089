import {handleValidationChange} from "../Validations/CommonValidation";

export const validateAddPersonalInformation = (data, setIsError) => {

    var isValid = true;
    if(data.preferred_title === "" || data.preferred_title === "") {
      handleValidationChange("preferred_title", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("preferred_title", false, setIsError);
      
    }

    if(data.married_surname === "") {
        handleValidationChange("married_surname", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("married_surname", false, setIsError);
        
    }

    if(data.last_name === "") {
        handleValidationChange("last_name", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("last_name", false, setIsError);
        
    }

    if(data.first_name === "") {
        handleValidationChange("first_name", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("first_name", false, setIsError);
        
    }

    if(data.middle_name === "" || data.valid_to === "") {
        handleValidationChange("middle_name", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("middle_name", false, setIsError);
        
    }

    if(data.suffix === "") {
        handleValidationChange("suffix", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("suffix", false, setIsError);
        
    }

    if(data.birthdate === "") {
        handleValidationChange("birthdate", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("birthdate", false, setIsError);
        
    }

    if(data.nationatlity === "") {
        handleValidationChange("nationatlity", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("nationatlity", false, setIsError);
        
    }

    if(data.college_id === "") {
        handleValidationChange("college_id", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("college_id", false, setIsError);
        
    }

    if(data.degree === "") {
        handleValidationChange("degree", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("degree", false, setIsError);
        
    }
    if(data.student_no === "") {
        handleValidationChange("student_no", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("student_no", false, setIsError);
        
    }

    if(data.latin_award === "") {
        handleValidationChange("latin_award", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("latin_award", false, setIsError);
        
    }

    if(data.semester_graduated === "") {
        handleValidationChange("semester_graduated", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("semester_graduated", false, setIsError);
        
    }

    if(data.academic_year === "") {
        handleValidationChange("academic_year", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("academic_year", false, setIsError);
        
    }

    if(data.year_conferred === "") {
        handleValidationChange("year_conferred", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("year_conferred", false, setIsError);
        
    }

    if(data.scholarship === "") {
        handleValidationChange("scholarship", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("scholarship", false, setIsError);
        
    }

    if(data.contact_no === "") {
        handleValidationChange("contact_no", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("contact_no", false, setIsError);
        
    }

    if(data.personal_email === "") {
        handleValidationChange("personal_email", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("personal_email", false, setIsError);
        
    }

    if(data.pronouns === "") {
        handleValidationChange("pronouns", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("pronouns", false, setIsError);
        
    }

    if(data.permanent_address === "") {
        handleValidationChange("permanent_address", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("permanent_address", false, setIsError);
        
    }

    if(data.permanent_county === "") {
        handleValidationChange("permanent_county", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("permanent_county", false, setIsError);
        
    }

    if(data.permanent_province === "") {
        handleValidationChange("permanent_province", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("permanent_province", false, setIsError);
        
    }

    if(data.permanent_city === "") {
        handleValidationChange("permanent_city", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("permanent_city", false, setIsError);
        
    }

    if(data.permanent_postal_code === "") {
        handleValidationChange("permanent_postal_code", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("permanent_postal_code", false, setIsError);
        
    }

    if(data.present_address === "") {
        handleValidationChange("present_address", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("present_address", false, setIsError);
        
    }

    if(data.present_country === "") {
        handleValidationChange("present_country", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("present_country", false, setIsError);
        
    }

    if(data.present_province === "") {
        handleValidationChange("present_province", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("present_province", false, setIsError);
        
    }

    if(data.present_city === "") {
        handleValidationChange("present_city", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("present_city", false, setIsError);
        
    }
    if(data.present_postal_code === "") {
        handleValidationChange("present_postal_code", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("present_postal_code", false, setIsError);
        
    }

    return isValid
  
}