import React, { createContext, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import "./Components/FontAwesomeIcons";
import { Toaster } from "react-hot-toast";

/** PAGES **/
import Login from "Pages/Login";
import FirstTimeUserForm from "Pages/FirstTimeUserForm";
import EmploymentInformation from "Pages/EmploymentInformation/EmploymentInformation";
// import ProfessionalInformation from "Pages/ProfessionalInformation/ProfessionalInformation";
import AcademicInformation from "Pages/AcademicInformation/AcademicInformation";
import CertificationsLicences from "Pages/CertificationsLicences/CertificationsLicences";
import MembershipAndOrg from "Pages/MembershipAndOrg/MembershipAndOrg";
import SocialMedia from "Pages/SocialMediaInfo/SocialMedia";
import Dashboard from "Pages/Dashboard";
import StudentProfile from "Pages/StudentProfile/StudentProfile";
import ExitInterview from "Pages/ExitInterview/ExitInterview";
import ExitInterviewPage2 from "Pages/ExitInterview/ExitInterviewPage2";
import ExitInterviewPage3 from "Pages/ExitInterview/ExitInterviewPage3";
import Projects from "Pages/Projects/Projects";
import ProjectDetails from "Pages/Projects/ProjectDetails";
import Scholarships from "Pages/Scholarships/Scholarships";
import ScholarshipDetails from "Pages/Scholarships/ScholarshipDetails";
import DataPrivacy from "Pages/DataPrivacy/DataPrivacy";

import { getUser } from "Helpers/Utils/Common";
import RegistrationForm from "Pages/RegistrationForm/RegistrationForm";



interface IThemeContext {
  theme: any;
  method: any;
}

export const ThemeContext = React.createContext<IThemeContext>({
  theme: null,
  method: null,
});

function App() {
  document.title = "UP CEBU ALUMNI TRACKER";
  const user = getUser()

  //Authenticate
  const [isAuthenticated, setIsAuthenticated] = useState(getUser());

  //Set light mode or dark mode
  const [theme, setTheme] = useState("light");
  const [isChecked, setIsChecked] = useState(false);

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  React.useEffect(() => {
    toggleTheme();
  }, [isChecked]);

  return (
    <ThemeContext.Provider value={{ theme: theme, method: toggleTheme }}>
      <div className="App" id={theme}>
        <Toaster position="top-right" reverseOrder={false} />
        <Router>
          <Routes>
            <Route path="/" element={ user ? <Navigate to='/data-privacy' /> : <Navigate to='/data-privacy-registration'/>} />
            <Route path="/end" element={<Login />} />
            <Route path="/dashboard" element={ user ? <Dashboard /> : <Navigate to='/'/>} />
            <Route path="/new-user-form" element={<FirstTimeUserForm />} />
            <Route path="/registration-form" element={<RegistrationForm />} />
            <Route path="/employment-information" element={ user ? <EmploymentInformation /> : <Navigate to='/'/>} />
            {/* <Route path="/professional-information" element={<ProfessionalInformation />} /> */}
            <Route path="/academic-information" element={ user ? <AcademicInformation /> : <Navigate to='/'/>} />
            <Route path="/certifications-information" element={ user ? <CertificationsLicences /> : <Navigate to='/'/>} />
            <Route path="/membership" element={ user ? <MembershipAndOrg /> : <Navigate to='/'/>} />
            <Route path="/social-media" element={ user ? <SocialMedia /> : <Navigate to='/'/>} />
            <Route path="/student-profile" element={ user ? <StudentProfile /> : <Navigate to='/'/>} />
            <Route path="/exit-interview" element={ user ? <ExitInterview /> : <Navigate to='/'/>} />
            <Route path="/registration-exit-interview" element={ <ExitInterview /> } />
            <Route path="/exit-interviewpage2" element={ user ? <ExitInterviewPage2 /> : <Navigate to='/'/>} />
            <Route path="/registration-exit-interviewpage2" element={ <ExitInterviewPage2 />} />
            <Route path="/exit-interviewpage3" element={ user ? <ExitInterviewPage3 /> : <Navigate to='/'/>} />
            <Route path="/registration-exit-interviewpage3" element={ <ExitInterviewPage3 />} />
            <Route path="/projects" element={ user ? <Projects /> : <Navigate to='/'/>} />
            <Route path="/project-details/:id" element={ user ? <ProjectDetails /> : <Navigate to='/'/>} />
            <Route path="/scholarships" element={user ? <Scholarships /> : <Navigate to='/'/>} />
            <Route path="/scholarship-details/:id" element={ user ? <ScholarshipDetails /> : <Navigate to='/'/>} />
            <Route path="/data-privacy" element={user ? <DataPrivacy /> : <Navigate to='/'/>} />
            <Route path="/data-privacy-registration" element={ <DataPrivacy/>} />
          </Routes>
        </Router>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;