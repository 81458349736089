import { getUser, getToken, getKey, removeSession, refreshPage } from "Helpers/Utils/Common";
import { postAPICall, postAPICallCustom } from "../axiosMethodCalls";

/***************************
 * USER
 ***************************/
// const api_key = getKey();
const token = getToken();
const user = getUser();
// const userKey = getUserKey();

//POST
export const getPersonalInformation= async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "graduates/get", {
          requester: user,
          token: token,
          alumni_id: user,
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        // toast.error(
        //   "Unauthorized access: another device is using this account"
        // );
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const getCurrentEmployment = async (id) => {
  try {
    const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_employments/get_current_employment", {
      requester: user,
      token: token,
      alumni_id: user,
  });
  return ({data:response.data.data});
} catch (error){
  if (error.response.status === 401) {
    removeSession();
    refreshPage();
  }
  return ({error: error.response});
}

}

export const updatePersonalInformation = async (id, data) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "graduates/update", {
          // requester: user,
          requester: user,
          // token: token,
          token: token,
          alumni_id: user,
          preferred_title: data.preferred_title,
          married_surname: data.married_surname,
          last_name: data.last_name,
          first_name: data.first_name,
          middle_name: data.middle_name,
          suffix: data.suffix,
          birthdate: data.birthdate,
          nationality: data.nationality,
          college: data.college,
          degree: data.degree,
          student_no: data.student_no,
          latin_award: data.latin_award,
          semester_graduated: data.semester_graduated,
          academic_year: data.academic_year,
          conferred_year: data.conferred_year,
          scholarship: data.scholarship,
          contact_no: data.contact_no,
          email: data.email,
          pronouns: data.pronouns,
          permanent_address: data.permanent_address,
          permanent_country: data.permanent_country,
          permanent_province: data.permanent_province,
          permanent_city: data.permanent_city,
          permanent_postal_code: data.permanent_postal_code,
          present_address: data.present_address,
          present_country: data.present_country,
          present_province: data.present_province,
          present_city: data.present_city,
          present_postal_code: data.present_postal_code,
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        // toast.error(
        //   "Unauthorized access: another device is using this account"
        // );
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const createPersonalInformation = async (data) => {

  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "graduates/create", {
          requester: user,
          // token: token,
          token: token,
          preferred_title: data.preferred_title,
          married_surname: data.married_surname,
          last_name: data.last_name,
          first_name: data.first_name,
          middle_name: data.middle_name,
          suffix: data.suffix,
          birthdate: data.birthdate,
          nationality: data.nationality,
          pronouns: data.pronouns,
          college: data.college,
          degree: data.degree,
          student_no: data.student_no,
          latin_award: data.latin_award,
          semester_graduated: data.semester_graduated,
          academic_year: data.academic_year,
          conferred_year: data.conferred_year,
          scholarship: data.scholarship,
          contact_no: data.contact_no,
          email: data.email,
          permanent_address: data.permanent_address,
          permanent_country: data.permanent_country,
          permanent_province: data.permanent_province,
          permanent_city: data.permanent_city,
          permanent_postal_code: data.permanent_postal_code,
          present_address: data.present_address,
          present_country: data.present_country,
          present_province: data.present_province,
          present_city: data.present_city,
          present_postal_code: data.present_postal_code,
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        // toast.error(
        //   "Unauthorized access: another device is using this account"
        // );
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

//POST
export const editUser = async (info, branches, file) => {
  var branchesData = [];

  branches.map((data) => (
    branchesData.push(data.value)
  ));
  try {
    const formData = new FormData();
    //Change param name according to the asked param from API
    formData.append("file_result", file[0]);
    const response = await postAPICallCustom(
      process.env.REACT_APP_LINK + "/login",
      {
        // requester: user,
        token: token,
        username: info.username,
        password: info.password,
        last_name: info.last_name,
        first_name: info.first_name,
        middle_name: info.middle_name,
        email: info.email_address,
        type: info.user_type,
        role_id: info.role,
        branch_ids: branchesData.join(","),
      },
      {
        "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
        // "user-key": userKey,
        "Content-Type": "multipart/form-data",
      },
    );

    return { data: response };
  } catch (error) {
    return { data: error.response.data };
  }
};

//POST
export const createAlumni = async (data) => {
  try {
    console.log(data)
      const response = await postAPICall(process.env.REACT_APP_LINK + "graduates/create", {
          requester: 1,
          token: token,
          preferred_title: data.preferred_title,
          married_surname: data.married_surname,
          last_name: data.last_name,
          first_name: data.first_name,
          middle_name: data.middle_name,
          suffix: data.suffix,
          birthdate: data.birthdate,
          nationality: data.nationality,
          pronouns: data.pronouns,
          college: data.college,
          department: data.department,
          degree_program: data.degree_program,
          student_no: data.student_no,
          latin_award: data.latin_award,
          semester_graduated_month: data.semester_graduated_month,
          semester_graduated_year: data.semester_graduated_year,
          academic_year: data.academic_year,
          conferred_month: data.conferred_month,
          conferred_year: data.conferred_year,
          scholarship: data.scholarship,
          contact_no: data.contact_no,
          email: data.email,
          permanent_address: data.permanent_address,
          permanent_country: data.permanent_country,
          permanent_province: data.permanent_province,
          permanent_city: data.permanent_city,
          permanent_postal_code: data.permanent_postal_code,
          present_address: data.present_address,
          present_country: data.present_country,
          present_province: data.present_province,
          present_city: data.present_city,
          present_postal_code: data.present_postal_code,
        });
        return ({data:response});   
    } catch (error) {
        if (error.response.status === 401) {
          removeSession();
        }
        return ({error: error.response});
    }
  }
