import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTrashCan,
  faUpload,
  faFileExcel,
  faFileCsv,
  faFileExport,
  faMoon,
  faExclamationCircle,
  faExclamationTriangle,
  faPlus,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faGreaterThan,
  faLessThan,
  faArrowRight,
  faArrowLeft,
  faEnvelope,
  faCaretDown,
  faCaretUp,
  faWindowClose,
  faPrint,
  faEye,
  faMinusSquare,
  faEyeSlash,
  faSearch,
  faTimes,
  faBars,
  faUser,
  faLock,
  faCheckSquare,
  faCoffee,
  faChevronDown,
  faChevronUp,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faTrashCan,
  faUpload,
  faFileExcel,
  faFileCsv,
  faFileExport,
  faMoon,
  faExclamationCircle,
  faExclamationTriangle,
  faChevronDown,
  faChevronUp,
  faPlus,
  faAngleDown,
  faAngleUp,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faGreaterThan,
  faLessThan,
  faArrowRight,
  faArrowLeft,
  faEnvelope,
  faCaretDown,
  faCaretUp,
  faWindowClose,
  faPrint,
  faMinusSquare,
  faEye,
  faEyeSlash,
  faSearch,
  faTimes,
  faBars,
  faUser,
  faLock,
  faCheckSquare,
  faCoffee,
  faRedo,
);
