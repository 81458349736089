import React, { useState } from "react";
import PropTypes, { bool } from "prop-types";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import InputError from "../../Components/InputError/InputError";
import {
  getMonths,
  getSignup,
  getToken,
  getUpSy,
  getUpYears,
  getUser,
  refreshPage,
} from "Helpers/Utils/Common";
import { toast, ToastContainer } from "react-toastify";
import { createAlumni } from "ApiCalls/PersonalInformationApi/PersonalInformationApi";
import { validateAddSingleAlumni } from "Helpers/Validations/Alumni";
import { Col, Row } from "react-bootstrap";
import "./RegistrationForm.css";
import "react-datepicker/dist/react-datepicker.css";

async function capitalizeFirstLetter(str) {
  console.log(str)
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function addPeriodToEnd(str) {
  if (str.endsWith(".")) {
    return str;
  } else {
    return str + ".";
  }
}

function RegistrationForm({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [redirect, setRedirect] = useState("");
  const navigateTo = useNavigate();
  const Months = getMonths();
  const upYears = getUpYears();
  const upSy = getUpSy();
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  // const [isChecked, setIsChecked] = useState([])
  const [checked, setChecked] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [alumniDetails, setAlumniDetails] = useState({
    preferred_title: "",
    married_surname: "",
    last_name: "",
    first_name: "",
    middle_name: "",
    suffix: "",
    birthdate: "",
    nationality: "",
    college: "",
    department: "",
    degree_program: "",
    student_no: "",
    latin_award: "",
    semester_graduated_month: "",
    semester_graduated_year: "",
    academic_year: "",
    conferred_year: "",
    conferred_month: "",
    scholarship: "",
    contact_no: "",
    email: "",
    pronouns: "",
    permanent_address: "",
    permanent_country: "",
    permanent_province: "",
    permanent_city: "",
    permanent_postal_code: "",
    present_address: "",
    present_country: "",
    present_province: "",
    present_city: "",
    present_postal_code: "",
  });

  const [isErrorTemp, setIsErrorTemp] = useState({});
  const [isError, setIsError] = useState({
    preferred_title: false,
    married_surname: false,
    last_name: false,
    first_name: false,
    middle_name: false,
    suffix: false,
    birthdate: false,
    nationality: false,
    college: false,
    department: false,
    degree_program: false,
    student_no: false,
    latin_award: false,
    semester_graduated_month: false,
    semester_graduated_year: false,
    academic_year: false,
    conferred_year: false,
    conferred_month: false,
    scholarship: false,
    contact_no: false,
    email: false,
    pronoun: false,
    permanent_address: false,
    permanent_country: false,
    permanent_province: false,
    permanent_city: false,
    permanent_postal_code: false,
    present_address: false,
    present_country: false,
    present_province: false,
    present_city: false,
    present_postal_code: false,
  });

  const handleKeyPress = (e) => {
    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  const collegesList = [
    {
      id: 1,
      value: "College of Communication, Art, and Design",
    },
    {
      id: 2,
      value: "College of Science",
    },
    {
      id: 3,
      value: "College of Social Sciences",
    },
    {
      id: 4,
      value: "School of Management",
    },
  ];
// ---------------------------------------------------------------------------------------------------
  const departmentListCCAD = [
    {
      id: 1,
      value: "Communication",
    },
    {
      id: 2,
      value: "Fine Arts",
    },
  ];

  const departmentListCOS = [
    {
      id: 1,
      value: "Department of Biology and Environmental Science",
    },
    {
      id: 2,
      value: "Department of Computer Science",
    },
    {
      id: 3,
      value: "Mathematics and Statistics",
    },
  ];

  const departmentListCOSS = [
    {
      id: 1,
      value: "Poltical Science",
    },
    {
      id: 2,
      value: "Psychology",
    },
    {
      id: 3,
      value: "Master of Education",
    },
  ];

  const departmentListSOM = [
    {
      id: 1,
      value: "Management",
    },
    {
      id: 2,
      value: " Master of Business Administration",
    },
  ];

// ---------------------------------------------------------------------------------------------------------
  
  const degreeProgramCommList = [
    {
      id: 1,
      value: "Bachelor of Arts in Communication",
    },
  ];

  const degreeProgramFAList = [
    {
      id: 1,
      value: "B Fine Arts (Product Design)",
    },
    {
      id: 2,
      value: "B Fine Arts (Studio Arts)",
    },
    {
      id: 3,
      value: "C Fine Arts (Product Design)",
    },
    {
      id: 4,
      value: "B Fine Arts (Studio Arts)",
    },
  ];

  const degreeProgramDBESList = [
    {
      id: 1,
      value: "Bachelor of Science in Biology",
    },
    {
      id: 2,
      value: "Masters of Science in Environmental Science",
    },
  ];

  const degreeProgramDCSList = [
    {
      id: 1,
      value: "Bachelor of Science in Computer Science",
    },
    {
      id: 2,
      value: "Masters of Science in Computer Science",
    },
  ];

  const degreeProgramMathList = [
    {
      id: 1,
      value: "Bachelor of Science in Mathematics",
    },
    {
      id: 2,
      value: "Bachelor of Science in Statistics",
    },
  ];

  const degreeProgramPolSciList = [
    {
      id: 1,
      value: "Bachelor of Science in Political Science",
    },
  ];

  const degreeProgramPsychoList = [
    {
      id: 1,
      value: "Bachelor of Science in Psychology",
    },
  ];

  const degreeProgramMEdList = [
    {
      id: 1,
      value: "Master of Education (Biology)",
    },
    {
      id: 2,
      value: "Master of Education (Chemistry)",
    },
    {
      id: 3,
      value: "Master of Education (English as Second Language)",
    },
    {
      id: 4,
      value: "Master of Education (Filipino)",
    },
    {
      id: 5,
      value: "Master of Education (Mathematics)",
    },
    {
      id: 6,
      value: "Master of Education (Physics)",
    },
    {
      id: 7,
      value: "Master of Education (Social Studies)",
    },
  ];

  const degreeProgramManagementList = [
    {
      id: 1,
      value: "Bachelor of Science in Management (Finance)",
    },
    {
      id: 2,
      value: "Bachelor of Science in Management (Marketing)",
    },
  ];

  const degreeProgramMBAList = [
    {
      id: 1,
      value: "Master of Business Administration",
    },
  ];

  const pronounList = [
    {
      id: 1,
      value: "she/her",
    },
    {
      id: 2,
      value: "he/him",
    },
    {
      id: 3,
      value: "they/them",
    },
  ];

  const latinAward = [
    {
      id: 1,
      value: "cum laude",
    },
    {
      id: 2,
      value: "magna cum laude",
    },
    {
      id: 3,
      value: "summa cum laude",
    },
  ];


 

  const [colleges, setColleges] = useState(collegesList);
  const [departments, setDepartments] = useState("");
  // const [degreeProgramsCS, setDegreeProgramsCS] = useState(degreeProgramCSList);
  const [showDepartment, setShowDepartment] = useState("");
  
  console.log(showDepartment);
  const handleSelectChange = (e) => {
    const { value } = e.target;
    setShowDepartment(value);
  };
   
  const handleCollegeChange = (e) => {
    const { value } = e.target;
    setDepartments(value);
  };

  const checkboxHandler = (e) => {
    setChecked(e.target.checked);

    if (e.target.checked) {
      alumniDetails.present_address = alumniDetails.permanent_address;
      alumniDetails.present_country = alumniDetails.permanent_country;
      alumniDetails.present_province = alumniDetails.permanent_province;
      alumniDetails.present_city = alumniDetails.permanent_city;
      alumniDetails.present_postal_code = alumniDetails.permanent_postal_code;
    } else {
      alumniDetails.present_address = "";
      alumniDetails.present_country = "";
      alumniDetails.present_province = "";
      alumniDetails.present_city = "";
      alumniDetails.present_postal_code = "";
    }
    if(validateAddSingleAlumni(alumniDetails, setIsErrorTemp)){
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleChange = (e) => {   
    const { name, value } = e.target;
    if(name === "college" || name === "department" || name === "degree_program") {
      if (name === "college") {
        setDepartments(value);
      } 
      else if (name === "department") {
        setShowDepartment(value);
      } 

      setAlumniDetails((prevState) => ({
        ...prevState,
        [name]: e.target.options[e.target.selectedIndex].text,
      }));
    } else {
      setAlumniDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if(validateAddSingleAlumni(alumniDetails, setIsErrorTemp)){
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  async function add() {
    setIsLoading(true);
    // console.log(!isClicked);
    // setIsClicked(false);
    if ( validateAddSingleAlumni(alumniDetails, setIsError) === true && !isClicked) {

      setIsClicked(true);
      const response = await createAlumni(alumniDetails);
      if (response.data) {
        const hasExitInterview = response.data.data.exit_interview;
        console.log(response);

        response.data.data.message = await capitalizeFirstLetter(response.data.data.message);
        response.data.data.message = response.data.data.message.replace("_", " ");
        response.data.data.message = addPeriodToEnd(response.data.data.message);

        toast.success(response.data.data.message);
        localStorage.setItem("user", JSON.stringify(response.data.data.id));
        localStorage.setItem(
          "name",
          JSON.stringify(
            response.data.data.first_name + " " + response.data.data.last_name,
          ),
        );
        localStorage.setItem("token", JSON.stringify(response.data.data.token));
        localStorage.setItem("id", JSON.stringify(response.data.data.id));
        localStorage.setItem(
          "has_exit_interview",
          JSON.stringify(hasExitInterview),
        );
        setIsLoading(false)
        setTimeout(function () {
          navigateTo("/end");
          refreshPage();
        }, 1000);
      } else {
        console.log("FAIL")
        console.log(response.error.data.messages.error_messages);
        setIsLoading(false)
        setTimeout( async function () {
          for(let i = 0; i < response.error.data.messages.error_messages.length; i++) {
            response.error.data.messages.error_messages[i] = await capitalizeFirstLetter(response.error.data.messages.error_messages[i]);
            response.error.data.messages.error_messages[i] = response.error.data.messages.error_messages[i].replace("_", " ");
            response.error.data.messages.error_messages[i] = addPeriodToEnd(response.error.data.messages.error_messages[i]);
            toast.error(response.error.data.messages.error_messages[i]);
          }
  
          setErrorMessages(response.error.data.messages.error_messages);
  
          setIsClicked(false);
        }, 1000);

      }
    } else {
      setIsLoading(false)      
    }

    // if (getToken() && getUser()) {
      
    // }
  }

  return (
    <div className="page">
      <ToastContainer />
      <div className="login-container">
        <div
          className={
            getSignup() === "sign-up" ? "form-cont-1 pt-4" : "form-cont pt-4"
          }
        >
          <div className="container pt-0">
            <h1 className="title">REGISTRATION FORM</h1>

            {/* FIRST ROW */}
            <Row>
              <Col xs={12} md={6} lg={4} className="mt-4 pref">
                <div className="form-group">
                  <p className="input-subtitle">PREF. TITLE</p>
                  <input
                    type="text"
                    name="preferred_title"
                    className="form-control input-subtitle input-shadow"
                    id="pref_title"
                    aria-describedby="pref_title"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">MARRIED SURNAME</p>
                  <input
                    type="text"
                    name="married_surname"
                    className="form-control input-subtitle input-shadow"
                    id="married_surname"
                    aria-describedby="married_surname"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    LAST NAME<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="last_name"
                    className="form-control input-subtitle input-shadow"
                    id="last_name"
                    aria-describedby="last_name"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.last_name}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    FIRST NAME<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="first_name"
                    className="form-control input-subtitle input-shadow"
                    id="first_name"
                    aria-describedby="first_name"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.first_name}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">M.I.</p>
                  <input
                    type="text"
                    name="middle_name"
                    className="form-control input-subtitle input-shadow"
                    id="m_i"
                    aria-describedby="m_i"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">SUFFIX</p>
                  <input
                    type="text"
                    name="suffix"
                    className="form-control input-subtitle input-shadow"
                    id="suffix"
                    aria-describedby="suffix"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
            {/* </Row>
            <Row> */}
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    DATE OF BIRTH<span className="required-icon">*</span>
                  </p>
                  <input
                    type="date"
                    name="birthdate"
                    className="modal-input date-input form-control input-subtitle input-shadow"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.birthdate}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    NATIONALITY<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="nationality"
                    className="form-control input-subtitle input-shadow"
                    id="nationality"
                    placeholder="Filipino"
                    aria-describedby="nationality"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.nationality}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">PRONOUNS</p>
                  <select
                    // type="text"
                    name="pronouns"
                    className="form-control input-subtitle input-shadow"
                    id="pronouns"
                    aria-describedby="pronouns"
                    placeholder="THEY/THEM"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select</option>
                    <option value="she/her">She/Her</option>
                    <option value="he/him">He/Him</option>
                    <option value="they/them">They/Them</option>
                  </select>
                </div>
              </Col>
            {/* </Row>

            <Row> */}
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    COLLEGE<span className="required-icon">*</span>
                  </p>
                  <select
                    name="college"
                    className="form-control input-subtitle input-shadow"
                    id="college"
                    aria-describedby="college"
                    onChange={(e) => handleChange(e)}
                    // onClick={handleCollegeChange}
                  >
                    <option value="">Select</option>
                    {colleges.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                  </select>
                  <InputError
                    isValid={isError.college}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    DEPARTMENT<span className="required-icon">*</span>
                  </p>
                  <select
                    // type="text"
                    name="department"
                    className="form-control input-subtitle input-shadow"
                    id="degree"
                    aria-describedby="degree"
                    onChange={(e) => handleChange(e)}
                    // onClick={handleSelectChange}
                  >
                    <option value="" hidden selected disabled>
                      Select
                    </option>

                    {departments === "1" &&
                      departmentListCCAD.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                    {departments === "2" &&
                      departmentListCOS.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                    {departments === "3" &&
                      departmentListCOSS.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                    {departments === "4" &&
                      departmentListSOM.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                  </select>
                  <InputError
                    isValid={isError.department}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    DEGREE PROGRAM<span className="required-icon">*</span>
                  </p>
                  <select
                    //   type="text"
                    name="degree_program"
                    className="form-control input-subtitle input-shadow"
                    id="degree"
                    aria-describedby="degree"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="" hidden selected disabled>
                      Select
                    </option>

                    {departments === "1" && showDepartment === "1" &&
                      degreeProgramCommList.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                    {departments === "1" && showDepartment === "2" &&
                      degreeProgramFAList.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                    {departments === "2" && showDepartment === "1" &&
                      degreeProgramDBESList.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                    {departments === "2" && showDepartment === "2" &&
                      degreeProgramDCSList.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                    {departments === "2" && showDepartment === "3" &&
                      degreeProgramMathList.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                    {departments === "3" && showDepartment === "1" &&
                      degreeProgramPolSciList.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                    {departments === "3" && showDepartment === "2" &&
                      degreeProgramPsychoList.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                    {departments === "3" && showDepartment === "3" &&
                      degreeProgramMEdList.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                    {departments === "4" && showDepartment === "1" &&
                      degreeProgramManagementList.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                    {departments === "4" && showDepartment === "2" &&
                      degreeProgramMBAList.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.value}
                      </option>
                    ))}
                  </select>
                  <InputError
                    isValid={isError.degree_program}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    STUDENT NO.<span className="required-icon">*</span>
                  </p>
                  <input
                    type="number"
                    name="student_no"
                    className="form-control input-subtitle input-shadow"
                    id="student_no"
                    placeholder="XXXXXXXXX"
                    aria-describedby="student_no"
                    onChange={(e) => handleChange(e)}
                    onKeyPress={(e) => handleKeyPress(e)}
                  />
                  <InputError
                    isValid={isError.student_no}
                    message={"This field is required"}
                  />
                </div>
              </Col>
            {/* </Row>

            <Row> */}
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">LATIN AWARDS/DISTINCTION</p>
                  {/* <input
                    type="text"
                    name="latin_award"
                    className="form-control input-subtitle input-shadow"
                    id="latin_award"
                    aria-describedby="awards"
                    onChange={(e) => handleChange(e)}
                  /> */}
                   <select
                    // type="text"
                    name="latin_award"
                    className="form-control input-subtitle input-shadow"
                    id="latin_award"
                    aria-describedby="awards"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">None</option>
                    <option value="cum laude">Cum Laude</option>
                    <option value="magna cum laude">Magna Cum Laude</option>
                    <option value="summa cum laude">Summa Cum Laude</option>
                  </select>
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    SEMESTER GRADUATED<span className="required-icon">*</span>
                  </p>
                  <Row>
                    <Col xs={6}>
                      <select
                        name="semester_graduated_month"
                        className="form-control input-subtitle input-shadow"
                        id="semester_graduated_month"
                        aria-describedby="semester_graduated_month"
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="" hidden selected disabled>
                          Month
                        </option>
                        {Months.map((data) => (
                          <option key={data.value} value={data.value}>
                            {data.label}
                          </option>
                        ))}
                      </select>
                      <InputError
                        isValid={isError.semester_graduated_month}
                        message={"This field is required"}
                      />
                    </Col>
                    <Col xs={6}>
                      {/* <input
                        type="number"
                        placeholder="Year"
                        name="semester_graduated_year"
                        className="form-control input-subtitle input-shadow"
                        id="semester_graduated_year"
                        aria-describedby="semester_graduated_year"
                        onChange={(e) => handleChange(e)}
                      /> */}
                      <select
                        placeholder="Select Year"
                        name="semester_graduated_year"
                        className="form-control input-subtitle input-shadow"
                        id="semester_graduated_year"
                        aria-describedby="semester_graduated_year"
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="" hidden selected disabled>
                          Select
                        </option>
                        {upSy.map((data) => (
                          <option key={data.value} value={data.value}>
                            {data.label}
                          </option>
                        ))}
                      </select>
                      <InputError
                        isValid={isError.semester_graduated_year}
                        message={"This field is required"}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    ACADEMIC YEAR<span className="required-icon">*</span>
                  </p>
                  {/* <input
                    type="text"
                    name="academic_year"
                    className="form-control input-subtitle input-shadow"
                    id="academic_year"
                    aria-describedby="academic_year"
                    onChange={(e) => handleChange(e)}
                  /> */}
                    <select
                      name="academic_year"
                      className="form-control input-subtitle input-shadow"
                      id="academic_year"
                      aria-describedby="academic_year"
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="" hidden selected disabled>
                        Select
                      </option>
                      {upYears.map((data) => (
                        <option key={data.value} value={data.value}>
                          {data.label}
                        </option>
                      ))}
                    </select>
                  <InputError
                    isValid={isError.academic_year}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">YEAR CONFERRED<span className="required-icon">*</span></p>
                  <div className="row">
                    <div className="col-6">
                      <select
                        // type="text"
                        name="conferred_month"
                        className="form-control input-subtitle input-shadow"
                        id="conferred_month"
                        aria-describedby="conferred_month"
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="" hidden selected disabled>
                          Month
                        </option>
                        {Months.map((data) => (
                          <option key={data.value} value={data.value}>
                            {data.label}
                          </option>
                        ))}
                      </select>
                      <InputError
                        isValid={isError.conferred_month}
                        message={"This field is required"}
                      />
                    </div>
                    <div className="col-5">
                      {/* <input
                        type="number"
                        placeholder="Year"
                        name="conferred_year"
                        className="form-control input-subtitle input-shadow"
                        id="conferred_year"
                        aria-describedby="conferred_year"
                        onChange={(e) => handleChange(e)}
                      /> */}
                       <select
                        placeholder="Year"
                        name="conferred_year"
                        className="form-control input-subtitle input-shadow"
                        id="conferred_year"
                        aria-describedby="conferred_year"
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="" hidden selected disabled>
                          Select
                        </option>
                        {upSy.map((data) => (
                          <option key={data.value} value={data.value}>
                            {data.label}
                          </option>
                        ))}
                      </select>
                      <InputError
                        isValid={isError.conferred_year}
                        message={"This field is required"}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">SCHOLARSHIP</p>
                  <input
                    type="text"
                    name="scholarship"
                    className="form-control input-subtitle input-shadow"
                    id="scholarship"
                    aria-describedby="scholarship"
                    placeholder="DOST SCHOLARSHIP"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    CONTACT NUMBER<span className="required-icon">*</span>
                  </p>
                  {/* <input
                    type="number"
                    name="contact_no"
                    className="form-control input-subtitle input-shadow"
                    id="contact_no"
                    aria-describedby="contact_no"
                    placeholder="0999999999"
                    onChange={(e) => handleChange(e)}
                  /> */}
                  <input
                    type="number"
                    name="contact_no"
                    className="form-control input-subtitle input-shadow"
                    id="contact_no"
                    aria-describedby="contact_no"
                    placeholder="63XXXXXXXXXX"
                    onKeyPress={(event) => {
                      const inputElement = event.target as HTMLInputElement;
                      if (!/[\d-]/.test(event.key)|| inputElement.value.length >= 12) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.contact_no}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    ACTIVE PERSONAL EMAIL
                    <span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="email"
                    className="form-control input-subtitle input-shadow"
                    id="email"
                    aria-describedby="email"
                    placeholder="JUANDELACRUZ@GMAIL.COM"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.email}
                    message={"This field is required"}
                  />
                </div>
              </Col>
            {/* </Row>

            <Row> */}
              <Col xs={12} md={8} lg={8} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    PERMANENT ADDRESS<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="permanent_address"
                    className="form-control input-subtitle input-shadow"
                    id="permanent_address"
                    aria-describedby="permanent_address"
                    placeholder="UNIT NO./BLDG./ST. NO./VILL./BRGY./LANDMARK"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.permanent_address}
                    message={"This field is required"}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    COUNTRY<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="permanent_country"
                    className="form-control input-subtitle input-shadow"
                    id="permanent_country"
                    aria-describedby="permanent_country"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.permanent_country}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    STATE/PROVINCE<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="permanent_province"
                    className="form-control input-subtitle input-shadow"
                    id="permanent_state_province"
                    aria-describedby="permanent_state_province"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.permanent_province}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    CITY<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="permanent_city"
                    className="form-control input-subtitle input-shadow"
                    id="permanent_city"
                    aria-describedby="permanent_city"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.permanent_city}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    POSTAL CODE<span className="required-icon">*</span>
                  </p>
                  <input
                    type="number"
                    name="permanent_postal_code"
                    className="form-control input-subtitle input-shadow"
                    id="permanent_postal_code"
                    aria-describedby="permanent_postal_code"
                    onKeyPress={(event) => {
                      const inputElement = event.target as HTMLInputElement;
                      if (!/[\d]/.test(event.key)|| inputElement.value.length >= 5) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => handleChange(e)}
                  />
                  {/* <input
                    type="text"
                    name="permanent_postal_code"
                    className="form-control input-subtitle input-shadow"
                    value={alumniDetails.present_postal_code}
                    id="permanent_postal_code"
                    placeholder="6014"
                    aria-describedby="permanent_postal_code"
                    onKeyPress={(event) => {
                      const inputElement = event.target as HTMLInputElement;
                      if (!/[\d]/.test(event.key)|| inputElement.value.length >= 5) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => handleChange(e)}
                  /> */}
                  <InputError
                    isValid={isError.permanent_postal_code}
                    message={"This field is required"}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={8} lg={8} className="mt-4">
                <div className="form-group">
                  <Row>
                    <Col xs={6} lg={6} className="">
                      <p className="input-subtitle w-100">
                        PRESENT ADDRESS<span className="required-icon">*</span>
                      </p>
                    </Col>
                    <Col xs={6} lg={6} className="form-group">
                      <input
                        type="checkbox"
                        className="custom-control-inpu mr-10 "
                        id="same_address"
                        name="same_address"
                        onChange={checkboxHandler}
                      />
                      <InputError
                        isValid={isError.preferred_title}
                        message={""}
                      />
                      <label
                        className="custom-control-label input-title pad-left5"
                        htmlFor="purchase-limit"
                      >
                        SAME AS PERMANENT ADDRESS
                      </label>
                    </Col>
                  </Row>
                  <input
                    type="text"
                    name="present_address"
                    className="form-control input-subtitle input-shadow"
                    value={alumniDetails.present_address}
                    id="present_address"
                    aria-describedby="present_address"
                    placeholder="UNIT NO./BLDG./ST. NO./VILL./BRGY./LANDMARK"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.present_address}
                    message={"This field is required"}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    COUNTRY<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="present_country"
                    className="form-control input-subtitle input-shadow"
                    value={alumniDetails.present_country}
                    id="present_country"
                    aria-describedby="present_country"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.present_country}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    STATE/PROVINCE<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="present_province"
                    className="form-control input-subtitle input-shadow"
                    value={alumniDetails.present_province}
                    id="present_province"
                    aria-describedby="present_state_province"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.present_province}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    CITY<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="present_city"
                    className="form-control input-subtitle input-shadow"
                    value={alumniDetails.present_city}
                    id="present_city"
                    aria-describedby="present_city"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.present_city}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    POSTAL CODE<span className="required-icon">*</span>
                  </p>
                  {/* <input
                    type="number"
                    name="present_postal_code"
                    className="form-control input-subtitle input-shadow"
                    value={alumniDetails.present_postal_code}
                    id="present_postal_code"
                    aria-describedby="present_postal_code"
                    onChange={(e) => handleChange(e)}
                  /> */}
                  <input
                    type="text"
                    name="present_postal_code"
                    className="form-control input-subtitle input-shadow"
                    value={alumniDetails.present_postal_code}
                    id="present_postal_code"
                    placeholder="6014"
                    aria-describedby="present_postal_code"
                    onKeyPress={(event) => {
                      const inputElement = event.target as HTMLInputElement;
                      if (!/[\d]/.test(event.key)|| inputElement.value.length >= 5) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.present_postal_code}
                    message={"This field is required"}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-5 mb-5">
              <div className="col right">
                <button
                  className="back-btn right me-2"
                  onClick={() => navigateTo("/exit-interviewpage3")}
                >
                  BACK
                </button>

                {isValid ? 
                <button className="btn-green right" onClick={add}>
                {isLoading ? 
                  <div className="load">
                  <ReactLoading
                  type="spinningBubbles"
                  color="#ff6700"
                  height={28}
                  width={30}
                />
                </div> :
                  "REGISTER"
                }
              </button> :
                <button className="btn-grey right" disabled onClick={add}>
                {isLoading ? 
                  <div className="load">
                  <ReactLoading
                  type="spinningBubbles"
                  color="#ff6700"
                  height={28}
                  width={30}
                />
                </div> :
                  "REGISTER"
                }
              </button>
                  }
              </div>
            </Row>

            {/* <Row className="formRow mt-5 me-5">
              <Col xs={2} className="right">
                <button className="back-btn btn-rad right px-5" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => setRedirect("back")}>
                  BACK
                </button>
              </Col>
              <Col xs={1} className="right">
                <button ref={saveBtn} className="btn-green btn-rad px-5"  data-bs-toggle="modal" onClick={add}>
                  ADD
                </button>
              </Col>
            </Row> */}
          </div>
        </div>
      </div>
    </div>
  );
}

RegistrationForm.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
};

export default RegistrationForm;
