import {handleValidationChange} from "../Validations/CommonValidation";

export const validateAddMembership = (data, setIsError) => {

    var isValid = true;
    if(data.alumni_org_id === "" || data.alumni_id === "") {
      handleValidationChange("alumni_org_id", true, setIsError);
      isValid = false;
    } else {
      handleValidationChange("alumni_id", false, setIsError);
      
    }

    if(data.name === "") {
        handleValidationChange("name", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("name", false, setIsError);
        
    }

    if(data.type === "") {
        handleValidationChange("type", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("type", false, setIsError);
        
    }

    // if(data.is_international === "") {
    //     handleValidationChange("is_international", true, setIsError);
    //     isValid = false;
    // } else {
    //     handleValidationChange("is_international", false, setIsError);
        
    // }

    if(data.membership_period === "") {
        handleValidationChange("membership_period", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("membership_period", false, setIsError);
        
    }

    if(data.position === "") {
        handleValidationChange("position", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("position", false, setIsError);
    }

    return isValid
  
}