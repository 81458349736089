import Navbar from "../../Components/Navbar/Navbar";
import PropTypes from "prop-types";
import React, { useState } from "react";
import InputError from "../../Components/InputError/InputError";
import { createExitInterview } from "../../ApiCalls/ExitInterviewApi/ExitInterviewApi";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getSignup } from "../../Helpers/Utils/Common";
import { validateAddExitInterviewInformation } from "../../Helpers/Validations/ExitInterview";
import "./ExitInterview.css";

type LocationState = {
  state: {
    plans: Object;
    responses: Object;
  };
};

function ExitInterviewPage2({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const navigateto = useNavigate();
  const location = useLocation();
  const { state } = location as LocationState;
  const [comments, setComments] = useState({
    experience: "",
    challenge: "",
    remarks: "",
  });
  const [isClicked, setIsClicked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState("");
  const checkboxHandler = () => {
    setChecked(!checked);
  };

  const [isError, setIsError] = useState({
    remarks: false,
    experience: false,
    challenge: false,
  });

  //ADD API
  async function add() {
    if (validateAddExitInterviewInformation(comments, setIsError)) {
      setIsClicked(true);
      if (checked) {
        const response = await createExitInterview(
          state.responses,
          state.plans,
          comments,
        );
        if (response.data && response.data.status === 200) {
          toast.success(response.data.data.response.toUpperCase());
        } else {
          toast.error(response.error.data.messages.error.toUpperCase());
        }
        setTimeout(function () {
          setSelected("cancel");
        }, 1500);
        navigateto("/employment-information");
        localStorage.setItem("sign_up", JSON.stringify(""));
      } else {
        toast.error("PLEASE AGREE TERMS!");
      }
    }
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setComments({ ...comments, [name]: value });
  };


  return (
    <div className="page">
      <ToastContainer />
      {getSignup() === "sign-up" ? (
        ""
      ) : (
        <Navbar
          onCollapse={(e) => {
            setInactive(e);
          }}
          active={"DASHBOARD"}
          setIsChecked={setIsChecked}
          isChecked={isChecked}
        />
      )}
      <div className="login-container">
        <div
          className={
            getSignup() === "sign-up" ? "form-cont-1 pt-4" : "form-cont pt-4"
          }
        >
          <div className="container pt-0">
            {/* FIRST ROW */}
            <div className="row">
              <h1 className="col-4 title">EXIT INTERVIEW</h1>
              <div className="col-8 message">
                <h4 className="message">
                  CONGRATULATIONS! WE HOPE THAT YOUR STAY IN UP CEBU HAS BEEN A
                  HELPFUL LEARNING EXPERIENCE. BEFORE YOU LEAVE THE UNIVERSITY
                  AND PURSUE YOUR CHOSEN CAREER, PLEASE LET US KNOW ABOUT YOUR
                  GENERAL PREPAREDNESS IN YOUR CAREER LIFE, ABOUT YOUR COLLEGE
                  EXPERIENCE, AND FUTURE PLANS.
                </h4>
              </div>
              <div className="row mt-4">
                <label className="">
                  WHAT ARE YOUR BEST EXPERIENCES IN THE UNIVERSITY?
                </label>
                <input
                  type="text"
                  className="row mt-2 ms-2 answer-txt"
                  name="experience"
                  onChange={handleChange}
                />
                <InputError
                  isValid={isError.experience}
                  message={"Required*"}
                />
              </div>
              <div className="row mt-4">
                <label className="">
                  WHAT CHALLENGES DID YOU ENCOUNTER IN THE UNIVERSITY? HOW DID
                  YOU MANAGE IT?
                </label>
                <input
                  type="text"
                  className="row mt-2 ms-2 answer-txt"
                  name="challenge"
                  onChange={handleChange}
                />
                <InputError isValid={isError.challenge} message={"Required*"} />
              </div>
              <div className="row mt-4">
                <label className="">
                  WHAT ARE YOUR COMMENTS/SUGGESTIONS FOR IMPROVEMENT FOR UP
                  CEBU?
                </label>
                <input
                  type="text"
                  className="row mt-2 ms-2 answer-txt"
                  name="remarks"
                  onChange={handleChange}
                />
                <InputError isValid={isError.remarks} message={"Required*"} />
              </div>

              <div className="row mt-2">
                <div className="col ">
                  <div className="input-group mb-2 ">
                    <div className="input-group-prepend ">
                      <input
                        type="checkbox"
                        className=" c-box btns me-1  ms-0 "
                        id="checked"
                        onChange={checkboxHandler}
                      />
                    </div>
                    <div className="col-11 mt-1">
                      <label className=" message mt-4 ">
                        {" "}
                        I hereby certify that the information stated above is
                        true and correct. I also hereby give my consent to the
                        Office of Student Affairs (OSA), UP Cebu to use the data
                        solely for research, report generation and job placement
                        purpose only.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <button
                  className="btn-green btn-rad right w-13"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={add}
                >
                  {" "}
                  NEXT{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ExitInterviewPage2.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
};

export default ExitInterviewPage2;
