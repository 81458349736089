import Navbar from "../../Components/Navbar/Navbar";
import PropTypes from "prop-types";
import React, { useState} from "react";
import dashhead from "../../Assets/Images/Dashboard/dashboard_head.png";
import { Row, Col} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getProjects } from "../../ApiCalls/Projects/ProjectsApi";
import { getDataId } from "Helpers/Utils/Common";
import "./Projects.css";

function StudentCard ({setIsChecked, isChecked }) {
  const navigateto = useNavigate();
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [projectsByDepartment, setProjectsByDepartment] = useState([{
    department: "",
    projects: [{
      id: "",
      project_name: "",
      budget: "",
      description: "",
      tag_1: "",
      tag_2: "",
      tag_3: "",
      tag_4: "",
      tag_5: "",
    }]
  }]);

  function formatAmount (value) {
    const amount: number = value;
    const formattedAmount: string = amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedAmount;
   }

  async function fetchProjects() {
    setProjectsByDepartment([]);
    const response = await getProjects();
    if (response.data) {
      setProjectsByDepartment(response.data);
    }
  }

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);

    fetchProjects();
  }, []);


  return (
    <div className="page">
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
      <div className="ml-100 pt-0">
        <div className="container pt-0 ml-0 pl-0 pr-0 ml--50">
          <div className="row mb-4 dash">
            <img src={dashhead} alt="dashboard-header" />
            <div className=" pt-0 dash-containers form-control top-br mx-2 me-5">
              <div className="row">
                <div className="row p-40">
                  <h1 className="title ms-5">PROJECTS</h1>
                </div>
                <div className="cont"> </div>
              </div>

              {
                projectsByDepartment ? (
                  projectsByDepartment.map((data) => {
                    return (
                      <>
                      <Row key="" className="ms-2 mt-5 mb-0">
                        <Col xs={5}>
                          <h4 className="rad dash-info title ms-4">
                            {data.department?data.department:""}
                          </h4>
                        </Col>
                      </Row>
                      <div className="row p-20 projects">
                <div className="row proj-container mt-0">
                  {data.projects.map((project, key) => {
                    return (
                      <div
                        className="col-4 dark-grey mx-3 mb-4 rounded"
                        key={key}
                        onClick={() =>
                          navigateto("/project-details/" + project.id)
                        }
                      >
                        <Row className="mt-2">
                          {
                            (project.tag_1) ? (
                                <Col className="label-gold ms-1 me-1 mt-2">
                                  {project.tag_1}
                                </Col>
                            ) : (
                              <p></p>
                            )
                          }

                          {
                            (project.tag_2) ? (
                                <Col className="maroon-tag ms-1 me-1 mt-2">
                                  {project.tag_2}
                                </Col>
                            ) : (
                              <p></p>
                            )
                          }

                          {
                            (project.tag_3) ? (
                                <Col className="green-tag ms-1 me-1 mt-2">
                                  {project.tag_3}
                                </Col>
                            ) : (
                              <p></p>
                            )
                          }

                          {
                            (project.tag_4) ? (
                                <Col className="label-gold ms-1 me-1 mt-2">
                                  {project.tag_4}
                                </Col>
                            ) : (
                              <p></p>
                            )
                          }

                          {
                            (project.tag_5) ? (
                                <Col className="maroon-tag ms-1 me-1 mt-2">
                                  {project.tag_5}
                                </Col>
                            ) : (
                              <p></p>
                            )
                          }
                          
                        </Row>

                        <Row className="ms-1 mt-3 scholarship-name">
                          <h2>{project.project_name?project.project_name.toLocaleUpperCase():""}</h2>
                        </Row>

                        <Row className="ms-3">
                          PHP {project.budget?formatAmount(project.budget):""}
                        </Row>

                        <Row className="px-4 py-4">
                          {project.description}
                        </Row>
                        
                      </div>
                    );
                  })}
                </div>
              </div>
                      </>

                    )
                  })
                ) : (
                  <p></p>
                )
              }
              {/* <div className="row-2 mt-3">
                <div className="col-8 pl-0">
                  <h4 className="rad dash-info title">
                    DEPARTMENT OF BIOLOGY AND ECOLOGY SOCIETY
                  </h4>
                </div>
              </div>
              <div className="row p-20 projects mb-5">
                <div className="row proj-container">
                  {projectData.map((data, key) => {
                    return (
                      <div
                        className="col-4 dark-grey m-1 rounded"
                        key={key}
                        onClick={() =>
                          navigateto("/project-details/" + data.id)
                        }
                      >
                        <div className="row">
                          <div className="col">
                            <p className="label-maroon center rad mt-3  ms-2 me-2">
                              FUNDING
                            </p>
                          </div>
                          <div className="col">
                            <p className="label-green center rad mt-3">
                              COMMUNITY
                            </p>
                          </div>
                          <div className="col left">
                            <p className="center rad left label-gold mt-3">
                              TEAMWORK
                            </p>
                          </div>
                        </div>
                        <h2 className="ms-2 input-subtitle-black-bold pt-2">
                          {data.name}
                        </h2>
                        <span className="ms-2 input-subtitle pt-3">
                          PHP {data.budget}{" "}
                          <label className="text-black pt-3">RAISED</label>
                        </span>
                        <div className="row ms-1 me-1">
                          <p className="input-subtitle-black pt-5">
                            {data.description}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

StudentCard.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
};

export default StudentCard;
