import { getUser, getDataId , getToken, getKey, removeSession, refreshPage } from "Helpers/Utils/Common";
import { postAPICall, postAPICallCustom } from "../axiosMethodCalls";

/***************************
 * USER
 ***************************/

const token = getToken();
const user = getUser();
const id = getDataId();

//POST
export const createAcademicInformation = async (data) => {

    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_academics/add", {
            requester: user,
            // token: token,
            token: token,  
            alumni_id: user,
            institution: data.institution,
            degree: data.degree,
            specialization: data.specialization,
            scholarship_grant: data.scholarship_grant,
            is_current: data.is_current === "on"? 1:0,
            date_started: data.date_started,
            date_ended: data.date_ended,
        });
        return ({data:response});   
    } catch (error) {
        if (error.response.status === 401) {
          removeSession();
          refreshPage();
        }
        return ({error: error.response});
    }
  }

export const getAcademicInformation = async (id) => {

  try {
    
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_academics/get", {
          requester: user,
          token: token,
          alumni_id: id,
      });
      return ({data:response.data.data});   
  } catch (error) {
      if (error.response.status === 401) {
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const updateAcademicInformation = async (id, data) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_academics/update", {
          requester: user,
          token: token,
          alumni_academic_id: id,
          institution: data.institution,
          degree: data.degree,
          specialization: data.specialization,
          scolarship_grant: data.scolarship_grant,
          is_current: data.is_current,
          date_started: data.date_started,
          
    });
      return ({data:response});   
    } catch (error) {
      return ({error: error.response});
  }
}

export const deleteAcademicInformation = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_academics/delete", {
          requester: user,
          token: token,
          alumni_academic_id: id,
          
          // package_type_id: id,
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        // toast.error(
        //   "Unauthorized access: another device is using this account"
        // );
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

//POST
export const editUser = async (info, branches, file) => {
  var branchesData = [];

  branches.map((data) => (
    branchesData.push(data.value)
  ));
  try {
    const formData = new FormData();
    //Change param name according to the asked param from API
    formData.append("file_result", file[0]);
    const response = await postAPICallCustom(
      process.env.REACT_APP_LINK + "/login",
      {
        // requester: user,
        token: token,
        username: info.username,
        password: info.password,
        last_name: info.last_name,
        first_name: info.first_name,
        middle_name: info.middle_name,
        email: info.email_address,
        type: info.user_type,
        role_id: info.role,
        branch_ids: branchesData.join(","),
      },
      {
        "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
        // "user-key": userKey,
        "Content-Type": "multipart/form-data",
      },
    );

    return { data: response };
  } catch (error) {
    return { data: error.response.data };
  }
};