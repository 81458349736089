import Header from "Components/Header/Header";
import Navbar from "Components/Navbar/Navbar";
import PropTypes from "prop-types";
import React, { useState } from "react";


function Dashboard({setIsChecked, isChecked}) {
  const [inactive, setInactive] = useState(false);

  return (
    <div className="page">
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
      <div className={`container ${inactive ? "inactive" : "active"}`}>
        <Header
          type={"users"}
          title="Dashboard"
        />
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
};


export default Dashboard;
