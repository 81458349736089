import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import {Row, Col} from "react-bootstrap";
import InputError from "../../Components/InputError/InputError";

//Import Validations
import { validateAddAcademicInformation } from "../../Helpers/Validations/AcademicInformationValidation";

//Import Common
import { getUser, refreshPage } from "Helpers/Utils/Common";

//Import from Components
import Header from "../../Components/Header/Header";
import Navbar from "../../Components/Navbar/Navbar";
import Table from "Components/Table/Table";
import ModalPopUp from "Components/Modal/Modal";

//Import ApiCalls
import {
  createAcademicInformation,
  getAcademicInformation,
  updateAcademicInformation,
  deleteAcademicInformation,
} from "../../ApiCalls/AcademicInformationApi/AcademicInformationApi";

//Import .CSS
import "./AcademicInformation.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

function AcademicInformation({ setIsChecked, isChecked }) {
  const navigateTo = useNavigate();
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [selected, setSelected] = useState("");
  const [action, setAction] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [alumniId, setAlumniId] = useState(getUser());
  const [id, setId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [academicHistoryData, setacademicHistoryData] = useState([]);
  const [academicData, setAcademicData] = useState({});
  const [academicDatas, setAcademicDatas] = useState({});
  const [addPresent, setAddPresent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [academicInformationDetails, setAcademicInformationDetails] = useState({
    alumni_academic_id: id,
    institution: "",
    degree: "",
    specialization: "",
    scholarship_grant: "",
    is_current: "",
    date_started: "",
    date_ended: "",
  });

  //GET API
  async function fetchAcademicInformation() {
    setIsLoading(true);
    setacademicHistoryData([]);
    const response = await getAcademicInformation();
    if (response.data) {
      const res = response.data
        .filter((details) => details.alumni_id.includes(getUser()))
        .map((info) => {
          return info;
        });
      setacademicHistoryData(res);
      if (res.length > 0) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    } else {
      setIsLoading(true);
    }
  }

  async function Academic(id) {
    const response = await getAcademicInformation(id);
    if (response.data) {
      setAcademicData(response.data);
      setShowModalUpdate(true);
    }
  }

  async function Academics(id) {
    const response = await getAcademicInformation(id);
    if (response.data) {
      setAcademicDatas(response.data);
      // academicData = response.data;
      setShowModal(true);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAcademicInformationDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [isError, setIsError] = useState({
    alumni_academic_id: false,
    institution: false,
    degree: false,
    specialization: false,
    scholarship_grant: false,
    is_current: false,
    date_started: false,
    date_ended: false,
  });

  const handleUpdateChange = (e) => {
    setAcademicData({ ...academicData, [e.target.name]: e.target.value });
    setAcademicDatas({ ...academicData, [e.target.name]: e.target.value });
  };

  //ADD API
  async function add() {
    if (
      validateAddAcademicInformation(academicInformationDetails, setIsError) && !isClicked
    ) {
      setIsClicked(true);
      const response = await createAcademicInformation(
        academicInformationDetails,
      );
      if (response.data && response.data.status === 200) {
        toast.success(response.data.data.response.toUpperCase());
        toast.success("ACADEMIC INFORMATION IS SUCCESSFULLY ADDED!");
      } else {
        toast.error(response.error.data.messages.error.toUpperCase());
        toast.error("ACADEMIC INFORMATION IS ADDED UNSUCCESSFULLY!");
      }
      setTimeout(function () {
        refreshPage();
      }, 500);
    }
  }

  // //UPDATE API
  async function update() {
    const response = await updateAcademicInformation(id, academicData);
    if (response.data && response.data.status === 200) {
      setIsClicked(true);
      toast.success(response.data.data.response.toUpperCase());
      setTimeout(function () {
        refreshPage();
      }, 500);
    } else {
      toast.error(response.error.data.messages.error.toUpperCase());
    }
  }

  //DELETE API
  async function handleRemove() {
    const response = await deleteAcademicInformation(id);
    if (response.data?.status === 200) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.response.toUpperCase());
    }
    setShowModal(false);
    setTimeout(function () {
      refreshPage();
    }, 500);
  }

  useEffect(() => {
    fetchAcademicInformation();
  }, []);

  const handleSelectChange = (e) => {
    const { id, name, value } = e.target;
    setId(id);
    if (value === "update") {
      Academic(id);
    }
    if (value === "delete") {
      Academics(id);
    }
  };

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, []);

  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
      <div className="login-container">
        <div className="form-cont pt-4">
          <div className="container p-3">
            <h1 className="title">ACADEMIC INFORMATION</h1>
            
            <Row className="">
              <Col xs={12} sm={6} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    INSTITUTION<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="institution"
                    className="form-control input-subtitle input-shadow"
                    value={academicInformationDetails.institution}
                    id="institution"
                    aria-describedby="institution"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.institution}
                    message={"This field is required"}
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    DEGREE<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="degree"
                    className="form-control input-subtitle input-shadow"
                    value={academicInformationDetails.degree}
                    id="degree"
                    aria-describedby="degree"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.degree}
                    message={"This field is required"}
                  />
                </div>
              </Col>
            </Row>
           
            <Row className="">
              <Col xs={12} sm={6} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">SPECIALIZATION</p>
                  <input
                    type="text"
                    name="specialization"
                    className="form-control input-subtitle input-shadow"
                    value={academicInformationDetails.specialization}
                    id="specialization"
                    aria-describedby="specialization"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">SCHOLARSHIP GRANTS</p>
                  <input
                    type="text"
                    name="scholarship_grant"
                    className="form-control input-subtitle input-shadow"
                    value={academicInformationDetails.scholarship_grant}
                    id="scholarship_grant"
                    aria-describedby="scholarship"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={12} lg={4} md={12} className="mt-4">
                <input
                  type="checkbox"
                  className="custom-control-input left"
                  id="is_current"
                  checked={addPresent}
                  onChange={(e) => setAddPresent(e.target.checked)}
                  name="is_current"
                />
                <label
                  className="custom-control-label input-subtitle grey ms-1"
                  id="current"
                  htmlFor="addPresent"
                >
                  I AM CURRENTLY ENROLLED/ IN PROGRESS
                </label>
              </Col>
              <Col xs={10} lg={4} md={6} className="mt-4">
                <Row>
                  <Col xs={2}>
                    <label
                      className="custom-control-label input-subtitle pad-left5"
                      htmlFor="purchase-limit"
                    >
                      START
                    </label>
                  </Col>
                  <Col className="">
                    <DatePicker
                      name="date_started"
                      className="form-control date-picker"
                      placeholderText={"mm/dd/yyyy"}
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setAcademicInformationDetails({
                          ...academicInformationDetails,
                          date_started: new Date(date).toLocaleDateString(
                            "en-CA",
                          ),
                        });
                      }}
                    />
                    {/* <InputError
                      isValid={isError.date_started}
                      message={"This field is required*"}
                    /> */}
                  </Col>
                </Row>
              </Col>
              <Col xs={10} lg={4} md={6} className="mt-4">
                <Row className="">
                  <div className="col">
                    {addPresent ? (
                      <ul>
                        <label
                          className="custom-control-label input-subtitle left"
                          htmlFor="addPresent"
                        >
                          PRESENT
                        </label>
                      </ul>
                    ) : (
                      <Row className="">
                        <Col xs={2} className="">
                          <label
                            className="custom-control-label input-subtitle pad-left5 "
                            htmlFor="addPresent"
                          >
                            TO
                          </label>
                        </Col>
                        <Col className="">
                          <DatePicker
                            name="date_ended"
                            className="form-control date-picker"
                            placeholderText={"mm/dd/yyyy"}
                            selected={endDate}
                            onChange={(date) => {
                              setEndDate(date);
                              setAcademicInformationDetails({
                                ...academicInformationDetails,
                                date_ended: new Date(date).toLocaleDateString(
                                  "en-CA",
                                ),
                              });
                            }}
                          />
                          {/* <InputError
                            isValid={isError.date_ended}
                            message={"This field is required"}
                          /> */}
                        </Col>
                      </Row>
                    )}
                  </div>
                </Row>
              </Col>
            </Row>

            <Row className="justify-content-center mt-4">
                <Col xs={3}>
                    <button
                    className="btn-green py-2"
                    onClick={add}
                    >
                    ADD
                    </button>
                </Col>
            </Row>
            <hr></hr>
            <div className="row mb-4">
              <h2 className="title">HISTORY</h2>
              <Table
                type={"academic_info"}
                tableData={academicHistoryData}
                clickable={false}
                headingColumns={[
                  "INSTITUTION",
                  "DEGREE",
                  "START DATE",
                  "END DATE",
                  "ACTION",
                ]}
                onSelectChange={handleSelectChange}
                setID={setSelected}
                setAction={setAction}
                useLoader={isLoading}
                isReady={isReady}
              />
            </div>
            
            <Row className="mb-5">
                <div className='col mb-4 right'>
                    <button className="back-btn btn-rad right me-2 w-10" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => navigateTo("/employment-information")}> BACK </button>
                    <button className="btn-green btn-rad right w-10" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>navigateTo("/certifications-information")}> NEXT </button>
                </div>
            </Row>
          </div>
        </div>
      </div>
      <ModalPopUp
        type="delete-academic"
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleDelete={handleRemove}
        data={academicDatas}
        setData={handleUpdateChange}
      />
      <ModalPopUp
        type="update-academic"
        show={showModalUpdate}
        handleClose={() => setShowModalUpdate(false)}
        handleUpdate={update}
        data={academicData}
        setData={handleUpdateChange}
      />
    </div>
  );
}

AcademicInformation.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
};

export default AcademicInformation;
