import React, { useState, useEffect, createRef } from "react";
import { useNavigate, Navigate, useParams} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import PropTypes from "prop-types";

//Import from Component
import Header from "Components/Header/Header";
import Navbar from "Components/Navbar/Navbar";
import InputError from "../Components/InputError/InputError";

//Import Validation
import { validateAddPersonalInformation } from "../Helpers/Validations/PersonalInformationValidation";

//IMport Common
import { removeUserSession, refreshPage } from "../Helpers/Utils/Common";

//API's
import {createPersonalInformation, getPersonalInformation, updatePersonalInformation} from "../ApiCalls/PersonalInformationApi/PersonalInformationApi";

//Import .CSS
import "./FirstTimeUserForm.css";
import "react-toastify/dist/ReactToastify.css";

function FirstTimeUserForm({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [personalInformation, setPersonalInformation] = useState([]);
  const navigateto = useNavigate()
  const [redirect, setRedirect] = useState ("")
  const [isClicked, setIsClicked] = useState(false);
  const [selected, setSelected] = useState("");
  const [id, setId] = useState();
  const [checked, setChecked] = useState(false);
  const [addPresent, setAddPresent] = useState(false);
  const [personalInformationDetails, setPersonalInformationDetails] = useState({
    preferred_title:"",
    married_surname: "",
    last_name: "",
    first_name: "",
    middle_name: "",
    suffix: "",
    birthdate: "",
    nationality: "",
    college_id: "",
    degree: "",
    student_no: "",
    latin_award: "",
    semester_graduated: "",
    academic_year: "",
    year_conferred: "",
    scholarship: "",
    contact_no: "",
    personal_email: "",
    pronouns: "",
    permanent_address: "",
    permanent_country: "",
    permanent_province: "",
    permanent_city: "",
    permanent_postal_code: "",
    present_address: "",
    present_country: "",
    present_province: "",
    present_city: "",
    present_postal_code: "",
});

  const checkboxHandler = (e) => {
    setChecked(e.target.checked)

    if(e.target.checked){
      
      personalInformationDetails.present_address = personalInformationDetails.permanent_address
      personalInformationDetails.present_country = personalInformationDetails.permanent_country
      personalInformationDetails.present_province = personalInformationDetails.permanent_province
      personalInformationDetails.present_city = personalInformationDetails.permanent_city
      personalInformationDetails.present_postal_code = personalInformationDetails.permanent_postal_code
    }
    else{
      personalInformationDetails.present_address=""
      personalInformationDetails.present_country=""
      personalInformationDetails.present_province=""
      personalInformationDetails.present_city=""
      personalInformationDetails.present_postal_code=""
    }
  }
  
//GET API
async function fetchPersonalInformation(){
    // setPersonalInformationDetails([]);
  const response = await getPersonalInformation(id);
  if(response.data) {
    setPersonalInformationDetails(response.data);
  } 
  else{
    setPersonalInformationDetails(response.data);
  }
}

const handleChange = (e) => {
  const { name, value } = e.target;
  setPersonalInformationDetails(prevState => ({
      ...prevState,
      [name]: value
  }));
}


//REQUIRED ERROR HANDLING
const [isError, setIsError] = useState({
          preferred_title: false,
          married_surname: false,
          last_name:false,
          first_name:false,
          middle_name: false,
          suffix: false,
          birthdate: false,
          nationality: false,
          college_id: false,
          degree: false,
          student_no: false,
          latin_award: false,
          semester_graduated: false,
          academic_year: false,
          year_conferred: false,
          scholarship: false,
          contact_no: false,
          personal_email: false,
          pronouns: false,
          permanent_address: false,
          permanent_country: false,
          permanent_province: false,
          permanent_city: false,
          permanent_postal_code: false,
          present_address: false,
          present_country: false,
          present_province: false,
          present_city: false,
          present_postal_code: false,
  });

//ADD API
async function add() {
  //REQUEST
if(validateAddPersonalInformation(personalInformationDetails, setIsError) === true && isClicked === false) {
    setIsClicked(true);
    const response = await createPersonalInformation(personalInformationDetails);
      if(response.data && response.data.status === 200) {
        navigateto("/student-profile")
        toast.success(response.data.data.response.toUpperCase());
        toast.success("ALUMNI INFORMATION IS SUCCESSFULLY ADDED!")
        navigateto("/new-user-form")
      } else {
        toast.error(response.error.data.messages.error.toUpperCase());
        toast.success("ALUMNI INFORMATION IS ADDED UNSUCCESSFULLY!")
      }
    setTimeout(function () {
    setSelected("cancel")
    }, 1500);
}
}

//UPDATE API
async function update() {
  // if(validateAddPersonalInformation(personalInformationDetails, setIsError) === true && isClicked === false) {
    setIsClicked(true);
    const response = await updatePersonalInformation(id, personalInformationDetails);
      if(response.data && response.data.status === 200) {
        toast.success(response.data.data.response.toUpperCase());
      toast.success("ALUMNI INFORMATION IS SUCCESSFULLY UPDATED!");
      } else {
      toast.error(response.error.data.messages.error.toUpperCase());
      toast.error("ALUMNI INFORMATION IS UPDATED UNSUCCESSFULLY!");
      }
    setTimeout(function () {
    refreshPage()
      }, 1500);
  // }
// }
}

useEffect(() => {
  fetchPersonalInformation();
},[]);

if (redirect === "next") {
  return <Navigate to="/projects" />;
}

  return (
    <div className="page">
    <ToastContainer/> 
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />

      <div className="login-container">
        <div className="form-cont pt-4">
          <div className="container pt-0">
            <h1 className="title">PERSONAL INFORMATION</h1>
            {/* FIRST ROW */}
            <div className="row row-form mb-4">
              <div className="col-2">
                <div className="form-group">
                  <p className="input-subtitle">PREF. TITLE</p>
                  <input type="text" name="preferred_title" className="form-control input-subtitle input-shadow" value={personalInformationDetails.preferred_title} id="pref_titles" aria-describedby="pref_titles" onChange={(e) => handleChange(e)}/>
                  <InputError isValid={isError.preferred_title} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <p className="input-subtitle">MARRIED SURNAME</p>
                  <input type="text" name="married_surname" className="form-control input-subtitle input-shadow" value={personalInformationDetails.married_surname} id="married_surname" aria-describedby="married_surname" onChange={(e) => handleChange(e)}/>
                  <InputError isValid={isError.married_surname} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <p className="input-subtitle">LAST NAME</p>
                  <input type="text" disabled name="last_name" className="form-control input-subtitle input-shadow" value={personalInformationDetails.last_name} id="last_name" aria-describedby="last_name" />
                  <InputError isValid={isError.last_name} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <p className="input-subtitle">FIRST NAME</p>
                  <input type="text" disabled name="first_name" className="form-control input-subtitle input-shadow" value={personalInformationDetails.first_name} id="first_name" aria-describedby="first_name" />
                  <InputError isValid={isError.first_name} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <p className="input-subtitle">M.I.</p>
                  <input type="text" disabled name="m_i" className="form-control input-subtitle input-shadow" value={personalInformationDetails.middle_name} id="m_i" aria-describedby="m_i" />
                  <InputError isValid={isError.middle_name} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <p className="input-subtitle">SUFFIX</p>
                  <input type="text" disabled name="suffix" className="form-control input-subtitle input-shadow" value={personalInformationDetails.suffix} id="suffix" aria-describedby="suffix" />
                  <InputError isValid={isError.suffix} message={"Max Weight is required*"}/>
                </div>
              </div>
            </div>
            <div className="row row-form mb-4">
              <div className="col-4">
                <div className="form-group">
                  <p className="input-subtitle">DATE OF BIRTH</p>
                  <input
                      disabled
                      type="date"
                      name="date_of_birth"
                      className="modal-input date-input form-control input-subtitle input-shadow"
                      value={personalInformationDetails.birthdate}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <p className="input-subtitle">NATIONALITY</p>
                  <input type="text" disabled name="nationality" className="form-control input-subtitle input-shadow" value={personalInformationDetails.nationality} id="nationality" aria-describedby="nationality" />
                  <InputError isValid={isError.nationality} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <p className="input-subtitle">PRONOUNS</p>
                  <input type="text" name="pronouns" className="form-control input-subtitle input-shadow" value={personalInformationDetails.pronouns} id="pronouns" aria-describedby="pronouns" placeholder="THEY/THEM" onChange={(e) => handleChange(e)}/>
                  <InputError isValid={isError.pronouns} message={"Max Weight is required*"}/>
                </div>
              </div>
            </div>
            <div className="row row-form mb-4">
              <div className="col-4">
                <div className="form-group">
                  <p className="input-subtitle">COLLEGE</p>
                  <input type="text" disabled name="college" className="form-control input-subtitle input-shadow" value={personalInformationDetails.college_id} id="college" aria-describedby="college" />
                  <InputError isValid={isError.college_id} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <p className="input-subtitle">DEGREE</p>
                  <input type="text" disabled name="degree" className="form-control input-subtitle input-shadow" value={personalInformationDetails.degree} id="degree" aria-describedby="degree" />
                <InputError isValid={isError.degree} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <p className="input-subtitle">STUDENT NO.</p>
                  <input type="text" disabled name="student_no" className="form-control input-subtitle input-shadow" value={personalInformationDetails.student_no} id="student_no" aria-describedby="student_no" />
                  <InputError isValid={isError.student_no} message={"Max Weight is required*"}/>
                </div>
              </div>
            </div>
            <div className="row row-form mb-4">
              <div className="col-3">
                <div className="form-group">
                  <p className="input-subtitle">LATIN AWARDS/DISTINCTION</p>
                  <input type="text" disabled name="awards" className="form-control input-subtitle input-shadow" value={personalInformationDetails.latin_award} id="awards" aria-describedby="awards" />
                  <InputError isValid={isError.latin_award} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <p className="input-subtitle">SEMESTER GRADUATED</p>
                  <input type="text" disabled name="semester_graduated" className="form-control input-subtitle input-shadow" value={personalInformationDetails.semester_graduated} id="semester_graduated" aria-describedby="semester_graduated" />
                  <InputError isValid={isError.semester_graduated} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <p className="input-subtitle">ACADEMIC YEAR</p>
                  <input type="text" disabled name="academic_year" className="form-control input-subtitle input-shadow" value={personalInformationDetails.academic_year} id="academic_year" aria-describedby="academic_year" />
                  <InputError isValid={isError.academic_year} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <p className="input-subtitle">YEAR CONFERRED</p>
                  <input type="text" disabled name="year_conferred" className="form-control input-subtitle input-shadow" value={personalInformationDetails.year_conferred} id="year_conferred" aria-describedby="year_conferred" />
                  <InputError isValid={isError.year_conferred} message={"Max Weight is required*"}/>
                </div>
              </div>
            </div>
            <div className="row row-form mb-4">
              <div className="col-4">
                <div className="form-group">
                  <p className="input-subtitle">SCHOLARSHIP<span className='required-icon'>*</span></p> 
                  <input type="text" name="scholarship" className="form-control input-subtitle input-shadow" id="scholarship" value={personalInformationDetails.scholarship} aria-describedby="scholarship" placeholder="DOST SCHOLARSHIP" onChange={(e) => handleChange(e)}/>
                  <InputError isValid={isError.scholarship} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <p className="input-subtitle">CONTACT NUMBER<span className='required-icon'>*</span></p>
                  <input type="text" name="contact_no" className="form-control input-subtitle input-shadow" id="contact_no"value={personalInformationDetails.contact_no}  aria-describedby="contact_no" placeholder="0999999999" onChange={(e) => handleChange(e)}/>
                  <InputError isValid={isError.contact_no} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <p className="input-subtitle">ACTIVE PERSONAL EMAIL<span className='required-icon'>*</span></p>
                  <input type="text" name="personal_email" className="form-control input-subtitle input-shadow" value={personalInformationDetails.personal_email} id="personal_email" aria-describedby="personal_email" placeholder="JUANDELACRUZ@GMAIL.COM" onChange={(e) => handleChange(e)}/>
                  <InputError isValid={isError.personal_email} message={"Max Weight is required*"}/>
                </div>
              </div>
            </div>
            <div className="row row-form row-form mb-4">
              <div className="col">
                <div className="form-group">
                  <p className="input-subtitle">PERMANENT ADDRESS<span className='required-icon'>*</span></p>
                  <input type="text" name="permanent_address" className="form-control input-subtitle input-shadow" value={personalInformationDetails.permanent_address} id="permanent_address" aria-describedby="permanent_address" placeholder="UNIT NUMBER/BUILDING/STREET NUMBER/VILLAGE/BARANGAY/LANDMARK" onChange={(e) => handleChange(e)}/>
                  <InputError isValid={isError.permanent_address} message={"Max Weight is required*"}/>
                </div>
              </div>
            </div>
            <div className="row row-form mb-4">
              <div className="col-3">
                <div className="form-group">
                  <p className="input-subtitle">COUNTRY<span className='required-icon'>*</span></p>
                  <input type="text" name="awards" className="form-control input-subtitle input-shadow" value={personalInformationDetails.permanent_country} id="awards" aria-describedby="awards" onChange={(e) => handleChange(e)}/>
                  <InputError isValid={isError.permanent_country} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <p className="input-subtitle">STATE/PROVINCE<span className='required-icon'>*</span></p>
                  <input type="text" name="province" className="form-control input-subtitle input-shadow" value={personalInformationDetails.permanent_province} id="province" aria-describedby="province" onChange={(e) => handleChange(e)}/>
                  <InputError isValid={isError.permanent_province} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <p className="input-subtitle">CITY<span className='required-icon'>*</span></p>
                  <input type="text" name="permanent_city" className="form-control input-subtitle input-shadow" value={personalInformationDetails.permanent_city} id="permanent_city" aria-describedby="permanent_city" onChange={(e) => handleChange(e)}/>
                  <InputError isValid={isError.permanent_city} message={"Max Weight is required*"}/>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <p className="input-subtitle">POSTAL CODE<span className='required-icon'>*</span></p>
                  <input type="text" name="permanent_postal_code" className="form-control input-subtitle input-shadow" value={personalInformationDetails.permanent_postal_code} id="permanent_postal_code" aria-describedby="permanent_postal_code" onChange={(e) => handleChange(e)}/>
                  <InputError isValid={isError.permanent_postal_code} message={"Max Weight is required*"}/>
                </div>
              </div>
            </div>
            <div className="row row-form mb-4">
              <div className="col">
                <div className="form-group">
                  <div className="row row-form">
                      <div className="col-3">
                        <p className="input-subtitle w-100">PRESENT ADDRESS<span className='required-icon'>*</span></p> 
                      </div>
                      <div className="col-3 form-group">
                        <input type="checkbox" className="custom-control-inpu mr-10 " id="same_address" name="same_address" onChange={checkboxHandler}/>
                        <InputError isValid={isError.present_address} message={"Max Weight is required*"}/>
                        <label className="custom-control-label input-title pad-left5" htmlFor="purchase-limit">SAME AS PERMANENT ADDRESS </label>
                      </div>
                  </div>
                  <input type="text" name="present_address" className="form-control input-subtitle input-shadow" value={personalInformationDetails.present_address} id="present_address" aria-describedby="present_address" placeholder="UNIT NUMBER/BUILDING/STREET NUMBER/VILLAGE/BARANGAY/LANDMARK" onChange={(e) => handleChange(e)}/>
                </div>
              </div>
            </div>
            <div className="row row-form mb-4">
              <div className="col-3">
                <div className="form-group">
                  <p className="input-subtitle">COUNTRY<span className='required-icon'>*</span></p>
                  <input type="text" name="present_country" className="form-control input-subtitle input-shadow" value={personalInformationDetails.present_country} id="present_country" aria-describedby="present_country" onChange={(e) => handleChange(e)}/>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <p className="input-subtitle">STATE/PROVINCE<span className='required-icon'>*</span></p>
                  <input type="text" name="present_province" className="form-control input-subtitle input-shadow"  value={personalInformationDetails.present_province} id="present_state_province" aria-describedby="present_state_province" onChange={(e) => handleChange(e)}/>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <p className="input-subtitle">CITY<span className='required-icon'>*</span></p>
                  <input type="text" name="present_city" className="form-control input-subtitle input-shadow" value={personalInformationDetails.present_city} id="present_city" aria-describedby="present_city" onChange={(e) => handleChange(e)}/>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <p className="input-subtitle">POSTAL CODE<span className='required-icon'>*</span></p>
                  <input type="text" name="present_postal_code" className="form-control input-subtitle input-shadow" value={personalInformationDetails.present_postal_code} id="present_postal_code" aria-describedby="present_postal_code" onChange={(e) => handleChange(e)}/>
                </div>
              </div>
            </div>
            <div className="row right pb-3">
              <div className='col right'>
                  <button className="btn-green btn-rad right" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>setRedirect("next")}> NEXT </button>
                  <button className="btn-green btn-rad me-5 right" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={add}> SAVE </button>
              </div>  
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
}

FirstTimeUserForm.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
  current: PropTypes.any,
  
};

export default FirstTimeUserForm;
