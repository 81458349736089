import Header from "../../Components/Header/Header";
import Navbar from "../../Components/Navbar/Navbar";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import "./ExitInterview.css"
import { removeUserSession, getUser, refreshPage, getSignup } from "../../Helpers/Utils/Common";
import Table from "Components/Table/Table";
import { Navigate, useNavigate } from "react-router-dom";
import {createExitInterview, getExitInterview} from "../../ApiCalls/ExitInterviewApi/ExitInterviewApi";
import {toast, ToastContainer} from "react-toastify";
import InputError from "Components/InputError/InputError";
import { validateAddExitInterviewInformation } from "Helpers/Validations/ExitInterview";

function ExitInterview({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [redirect, setRedirect] = useState("");
  const navigateto = useNavigate()
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState("");
  const [id, setId] = useState();
  const [questions, setQuestions] = useState ([{id: "", question: "", }])

  const[responses, setResponses] = useState([{
    id:"", question:"", response:"",
  }
  ])

  const [isError, setIsError] = useState({
    optionalInfo: false,
  })

  const plansList:string[] = []

const [plans, setPlans] = useState (
    [
        {name: "PROCEED TO GRADUATE STUDIES", state: false, isDisabled: false },
        {name: "PROCEED TO MEDICINE", state: false, isDisabled: false },
        {name: "PROCEED TO LAW", state: false, isDisabled: false },
        {name: "STUDY ANOTHER PROGRAM", state: false, isDisabled: false },
        {name: "TAKE A SHORT COURSE", state: false, isDisabled: false },
        {name: "START OWN BUSINESS", state: false, isDisabled: false },
        {name: "REST FOR A WHILE", state: false, isDisabled: false },
        {name: "REVIEW AND TAKE THE BOARD EXAM", state: false, isDisabled: false },
        {name: "LOOK FOR A JOB", state: false, isDisabled: false },
        {name: "WORK IN A FAMILY BUSINESS", state: false, isDisabled: false },
        {name: "OTHERS", state: false, isDisabled: false },
    ]
)

async function fetchQuestions(){
    setQuestions([]);
   const response = await getExitInterview();
    if(response) {
        response.data.data.map((info)=>{
            info.response = "";
        });
        let result = response.data.data.filter((a,key) => key < 11)
        
        setResponses(result)
        setQuestions(result);
    }
}

const handleChange = (e) => {
    const { id, name, value } = e.target;
    responses.forEach(data=>{
        if(data.id===id){
            data.response = value
        }
    })
  }

//ADD API
async function add() {
    if(validateAddExitInterviewInformation(responses, setIsError)) {
        setIsClicked(true);
        var answer = {},plansList = {}, filterplans = [...plans]
        responses.map(data =>(
            answer[`response_${data.id}`] = data.response
        ))
        filterplans = plans.filter(data=>data.isDisabled===false)
        filterplans.map((data,key)=>(
            plansList[`plan${key+1}`] = data.name
        ))
        if(getSignup() === "sign-up"){
            navigateto("/registration-exit-interviewpage2", {state: {plans: plansList, responses:answer}})
        }else{
            navigateto("/exit-interviewpage2", {state: {plans: plansList, responses:answer}})
        }
    }
}
   
  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, []);

  useEffect(() => {
    fetchQuestions();
  },[]);

  if (redirect === "next") {
    return <Navigate to="/exit-interviewpage2" />;
}

const handleButtonChange = (id) => {
    var arr = [...plans]
    arr[id].state = !plans[id].state;
    setPlans(arr)
    
    var isThree = plans.filter((data) => data.state === true).length >= 3? true:false
    var newArr = [...plans]
    if(isThree){
        plans.forEach((data) => 
        {
            if(data.state === true){
                plansList.push(data.name)
            }
        })
        newArr.forEach(data => 
            {  
            if(data.state){
                data.isDisabled = false
        }
        else{
            data.isDisabled = true
        }}  )
        setPlans(newArr)
    }
    else{

        newArr.map(data => 
        (
            data.isDisabled = false  
        ))
        setPlans(newArr)
    }

    var othersBox = document.getElementById("othersBox") as HTMLElement
    if(plans[10].state === true){
        othersBox.removeAttribute("hidden")
    } 
    else {
        othersBox.setAttribute("hidden","hidden")
    }
}

  return (
    <div className="page">
    {getSignup() === "sign-up" ? "" :
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
    }
      <div className="login-container">
      <div className={getSignup() === "sign-up" ? "form-cont-1 pt-4" : "form-cont pt-4"}>
          <div className="container pt-0">
            <div className="row">
            <h1 className="col-4 title">EXIT INTERVIEW</h1>
            <div className="col-8 message">
            <h4 className="message">CONGRATULATIONS! WE HOPE THAT YOUR STAY IN UP CEBU HAS BEEN A HELPFUL LEARNING EXPERIENCE. BEFORE YOU LEAVE THE UNIVERSITY AND PURSUE YOUR CHOSEN CAREER, PLEASE LET US KNOW ABOUT YOUR GENERAL PREPAREDNESS IN YOUR CAREER LIFE, ABOUT YOUR COLLEGE EXPERIENCE, AND FUTURE PLANS.</h4>
            </div>
            <div className="row mt-4">
            <h3 className="header-2">WHAT ARE YOUR PLANS AFTER YOUR GRADUATION? (PLEASE CHOOSE 3 ANSWERS AND RANK THEM 1 AS YOUR 1ST CHOICE; 2 AS YOUR 2ND CHOICE AND 3 AS YOUR 3RD CHOICE)</h3>
            </div>
            {/* FIRST ROW */}
            <div className="form-group row">
          
            <div className="row mb-4">
            {plans.map((data, key) => {
                return (<div key={key} className="col-2 mb-3" role="group">
                <button key={key} className={data.state ? "click-btn " : "plans-btn input-shadow"} 
                onClick={()=>{handleButtonChange(key); 
                    }} disabled={data.isDisabled}>
                        {data.name}
                    </button>   
                </div>)
                      })
                    }
                    {/* <div className="col-2" id="othersBox" hidden>
                    <label className="row ms-2">Please specify: </label>
                    <input type="text" className="plans-btn input-shadow" name="other_plan" onChange={handleChange}/> */}
                    {/* <InputError isValid={isError.} message={"Required*"}/>  */}
                    <div className="row" id="othersBox" hidden>
                    <label className="row ms-1 mt-3">If you selected Others, please specify: </label>
                    <input type="text" className="plans-btn input-shadow ms-2 mt-1 " name="other_plan" onChange={handleChange}/>
                    
                </div>
                    </div>
                </div>

                <td></td>
                
               
                {/* <div className="row mb-6"> */}
                    <div className="form-group">
                        <p className="row mt-5 header-2">PLEASE RATE THE LEVEL OF QUALITY IN RELATION TO YOUR DEGREE PROGRAM BY CHOOSING THE APPROPRIATE BOX:</p>
                {questions.map((data,key)=>{
                    return (
                      <>
                      
                        <div className="row mt-4">
                            <div className="col">
                                <label>{data.question}</label>
                            </div>
                            <div className="col-5">
                                <select
                                    required
                                    className="form-control input-subtitle  input-shadow"
                                    id={data.id}
                                    onChange={handleChange}
                                    >
                                    <option value="">Select</option>
                                    <option value="not_developed">NOT DEVELOPED</option>
                                    <option value="developed">DEVELOPED</option>
                                    <option value="highly_developed">HIGHLY DEVELOPED</option>
                                </select> 
                                <InputError isValid={isError.optionalInfo} message={"The Field is required"}/>
                            </div>
                        </div>
                      </>
                    )})}
                        {/* <div className="row mt-4">
                            <div className="col">
                                <label>{ata.question}</label>
                            </div>
                            <div className="col-5">
                                <select
                                    className="form-control input-subtitle  input-shadow"
                                    name="institution"
                                    >
                                    <option value="">Select</option>
                                    <option value="1">NOT DEVELOPED</option>
                                    <option value="2">DEVELOPED</option>
                                    <option value="2">HIGHLY DEVELOPED</option>
                                </select> 
                            </div>
                        </div> */}
                        {/* <div className="row mt-4">
                            <div className="col">
                                <label>OPPORTUNITIES OF USEFUL NON-CLASSROOM EXPERIENCES</label>
                            </div>
                            <div className="col-5">
                                <select
                                    className="form-control input-subtitle  input-shadow"
                                    name="institution"
                                    >
                                    <option value="">Select</option>
                                    <option value="1">NOT DEVELOPED</option>
                                    <option value="2">DEVELOPED</option>
                                    <option value="2">HIGHLY DEVELOPED</option>
                                </select> 
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <label>PROVIDE OPPORTUINTIES TO PARTICIPATE IN COMMUNITY SERVICE</label>
                            </div>
                            <div className="col-5">
                                <select
                                    className="form-control input-subtitle  input-shadow"
                                    name="institution"
                                    >
                                    <option value="">Select</option>
                                    <option value="1">NOT DEVELOPED</option>
                                    <option value="2">DEVELOPED</option>
                                    <option value="2">HIGHLY DEVELOPED</option>
                                </select> 
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <label>COURSE CONTENT REFLECTED CURRENT TRENDS IN YOUR FIELDS</label>
                            </div>
                            <div className="col-5">
                                <select
                                    className="form-control input-subtitle  input-shadow"
                                    name="institution"
                                    >
                                    <option value="">Select</option>
                                    <option value="1">NOT DEVELOPED</option>
                                    <option value="2">DEVELOPED</option>
                                    <option value="2">HIGHLY DEVELOPED</option>
                                </select> 
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <label>DEGREE REQUIREMENTS WERE RELEVANT TO YOUR PROFESSIONAL GOALS</label>
                            </div>
                            <div className="col-5">
                                <select
                                    className="form-control input-subtitle  input-shadow"
                                    name="institution"
                                    >
                                    <option value="">Select</option>
                                    <option value="1">NOT DEVELOPED</option>
                                    <option value="2">DEVELOPED</option>
                                    <option value="2">HIGHLY DEVELOPED</option>
                                </select> 
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <label>GIVING YOU A SENSE OF COMPETENCE IN YOUR MAJOR FIELD OF STUDY</label>
                            </div>
                            <div className="col-5">
                                <select
                                    className="form-control input-subtitle  input-shadow"
                                    name="institution"
                                    >
                                    <option value="">Select</option>
                                    <option value="1">NOT DEVELOPED</option>
                                    <option value="2">DEVELOPED</option>
                                    <option value="2">HIGHLY DEVELOPED</option>
                                </select> 
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <label>INVOLVED THE APPLICATION OF THEORITICAL KNOWLEDGE TO PRACTICAL SITUATIONS. </label>
                            </div>
                            <div className="col-5">
                                <select
                                    className="form-control input-subtitle  input-shadow"
                                    name="institution"
                                    >
                                   <option value="">Select</option>
                                    <option value="1">NOT DEVELOPED</option>
                                    <option value="2">DEVELOPED</option>
                                    <option value="2">HIGHLY DEVELOPED</option>
                                </select> 
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <label>FACULTY MEMBERS WERE GEUINELY INTERESTED IN YOUR WELFARE </label>
                            </div>
                            <div className="col-5">
                                <select
                                    className="form-control input-subtitle  input-shadow"
                                    name="institution"
                                    >
                                    <option value="">Select</option>
                                    <option value="1">NOT DEVELOPED</option>
                                    <option value="2">DEVELOPED</option>
                                    <option value="2">HIGHLY DEVELOPED</option>
                                </select> 
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <label>OVERALL QUALITY OF ASSISTANCE PROVIDED BY THE DEPARTMENT</label>
                            </div>
                            <div className="col-5">
                                <select
                                    className="form-control input-subtitle  input-shadow"
                                    name="institution"
                                    >
                                    <option value="">Select</option>
                                    <option value="1">NOT DEVELOPED</option>
                                    <option value="2">DEVELOPED</option>
                                    <option value="2">HIGHLY DEVELOPED</option>
                                </select> 
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <label>OVERALLQUALITY OF INSTRUCTION</label>
                            </div>
                            <div className="col-5">
                                <select
                                    className="form-control input-subtitle  input-shadow"
                                    name="institution"
                                    >
                                    <option value="">Select</option>
                                    <option value="1">NOT DEVELOPED</option>
                                    <option value="2">DEVELOPED</option>
                                    <option value="2">HIGHLY DEVELOPED</option>
                                </select> 
                            </div>
                        </div> */}
                    </div>
                    
            </div>
            <div className="row mt-5">
                <div className='col-12'>
                    <button className="btn-green btn-rad right w-13" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={add}> NEXT </button>
                </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
}

ExitInterview.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
  key: PropTypes.any,
};

export default ExitInterview;
