import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {Row, Col} from "react-bootstrap";
import { getUser, refreshPage } from "Helpers/Utils/Common";
import { getPersonalInformation } from "../../ApiCalls/PersonalInformationApi/PersonalInformationApi";
import { validateAddSocialMedia } from "../../Helpers/Validations/SocialMedia";
import { updateSocialMedia } from "../../ApiCalls/SocialMedia/SocialMediaApi";
import Navbar from "../../Components/Navbar/Navbar";

//Import .CSS
import "./SocialMedia.css";
import "react-toastify/dist/ReactToastify.css";

function SocialMedia({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [id, setId] = useState(getUser());
  const [isClicked, setIsClicked] = useState(false);
  const navigateTo = useNavigate();

  const [personalInformationDetails, setPersonalInformationDetails] = useState({
    alumni_id: id,
    facebook: "",
    linkedin: "",
    twitter: "",
    instagram: "",
    github: "",
    youtube: "",
    personal_website: "",
  });

  const [isError, setIsError] = useState({
    alumni_id: false,
    facebook: false,
    linkedin: false,
    twitter: false,
    instagram: false,
    github: false,
    youtube: false,
    personal_website: false,
  });

  //GET API
  async function fetchPersonalInformation() {
    const response = await getPersonalInformation(id);
    if (response.data) {
      setPersonalInformationDetails(response.data);
    }
  }

  //UPDATE API
  async function update() {
    if (
      validateAddSocialMedia(personalInformationDetails, setIsError) === true &&
      isClicked === false
    ) {
      setIsClicked(true);
      const response = await updateSocialMedia(id, personalInformationDetails);
      if (response.data && response.data.status === 200) {
        toast.success(response.data.data.response.toUpperCase());
        toast.success("SOCIAL MEDIA INFORMATION IS SUCCESSFULLY UPDATED!");
        navigateTo("/student-profile");
      } else {
        setIsClicked(false)
        toast.error(response.error.data.messages.error);
        toast.success("SOCIAL MEDIA INFORMATION IS UPDATED UNSUCCESSFULLY!");
      }
      refreshPage();
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonalInformationDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchPersonalInformation();
  }, []);

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, []);

  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
      <div className="login-container">
        <div className="form-cont pt-2 p-4">
          <div className="container p-3">
            <h1 className="title">SOCIAL MEDIA INFORMATION</h1>
            {/* FIRST ROW */}
            <Row className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">FACEBOOK/MESSENGER</p>
                  <input
                    type="text"
                    name="facebook"
                    className="form-control input-subtitle input-shadow"
                    value={personalInformationDetails.facebook}
                    id="facebook"
                    aria-describedby="facebook"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
            </Row>
            <Row className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">LINKEDIN </p>
                  <input
                    type="text"
                    name="linkedin"
                    className="form-control input-subtitle input-shadow"
                    value={personalInformationDetails.linkedin}
                    id="linkedin"
                    aria-describedby="linkedin"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
            </Row>
            <Row className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">TWITTER </p>
                  <input
                    type="text"
                    name="twitter"
                    className="form-control input-subtitle input-shadow"
                    value={personalInformationDetails.twitter}
                    id="twitter"
                    aria-describedby="twitter"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
            </Row>
            <Row className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">INSTAGRAM</p>
                  <input
                    type="text"
                    name="instagram"
                    className="form-control input-subtitle input-shadow"
                    value={personalInformationDetails.instagram}
                    id="instagram"
                    aria-describedby="instagram"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
            </Row>
            <Row className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">GITHUB</p>
                  <input
                    type="text"
                    name="github"
                    className="form-control input-subtitle input-shadow"
                    value={personalInformationDetails.github}
                    id="github"
                    aria-describedby="github"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
            </Row>
            <Row className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">YOUTUBE</p>
                  <input
                    type="text"
                    name="youtube"
                    className="form-control input-subtitle input-shadow"
                    value={personalInformationDetails.youtube}
                    id="youtube"
                    aria-describedby="youtube"
                    onChange={(e) => handleChange(e)}
                  />
              </div>
            </Row>
            <Row className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">PERSONAL WEBSITE</p>
                  <input
                    type="text"
                    name="personal_website"
                    className="form-control input-subtitle input-shadow"
                    value={personalInformationDetails.personal_website}
                    id="website"
                    aria-describedby="website"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
            </Row>

            <Row className="mt-5">
              <div className="col mb-4 right">
                <button
                  className="back-btn btn-rad right me-2 w-10"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => navigateTo("/membership")}
                >
                  BACK
                </button>
                <button
                  className="btn-green btn-rad right w-10"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => navigateTo("/student-profile")}
                >
                  NEXT
                </button>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

SocialMedia.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
};

export default SocialMedia;
