import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { toast, ToastContainer } from "react-toastify";
import { Row, Col } from "react-bootstrap";
//Import from Components
import Navbar from "../../Components/Navbar/Navbar";
import Table from "Components/Table/Table";
import ModalPopUp from "Components/Modal/Modal";

//Import Validations
import { validateAddCertificates } from "../../Helpers/Validations/CertificatesValidation";

//Import Common
import { refreshPage, getUser, getDataId } from "Helpers/Utils/Common";

//Import APICalls
import {
  createCertificates,
  getCertificates,
  deleteCertificates,
  updateCertificates,
} from "../../ApiCalls/CertificatesApi/CertificatesApi";

//Import .CSS
import "./CertificationsLicences.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

function Certificates({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [selected, setSelected] = useState("");
  const [action, setAction] = useState({});
  const navigateTo = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [id, setId] = useState();
  const [alumniId, setAlumniId] = useState(getUser());
  const [showModal, setShowModal] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [certificatesHistoryData, setCertificatesHistoryData] = useState([]);
  const [certificatesData, setCertificatesData] = useState({});
  const [certificatessData, setCertificatessData] = useState({});

  const [certificatesDetails, setCertificatesDetails] = useState({
    alumni_cert_id: id,
    name: "",
    institution: "",
    completion_date: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  //GET API
  async function fetchCertificates() {
    setIsLoading(true);
    const response = await getCertificates();
    if (response.data) {
      const res = response.data
        .filter((details) => details.alumni_id.includes(getUser()))
        .map((info) => {
          return info;
        });
      setCertificatesHistoryData(res);
      if (res.length > 0) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    } else {
      setIsLoading(false);
    }
  }

  async function certificate(id) {
    const response = await getCertificates(id);
    if (response.data) {
      setCertificatesData(response.data);
      setShowModalUpdate(true);
    }
  }

  async function certificates(id) {
    const response = await getCertificates(id);
    if (response.data) {
      setCertificatessData(response.data);
      setShowModal(true);
    }
  }

  const handleUpdateChange = (e) => {
    setCertificatesData({
      ...certificatesData,
      [e.target.name]: e.target.value,
    });
    setCertificatessData({
      ...certificatesData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCertificatesDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //ADD API
  async function add() {
    // if(validateAddCertificates(certificatesDetails, setIsError) === true && isClicked === false) {
    setIsClicked(true);
    const response = await createCertificates(certificatesDetails);
    if (response.data && response.data.status === 200) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.messages.error.toUpperCase());
    }
    setTimeout(function () {
      setSelected("cancel");
    }, 1500);
    // }
    refreshPage();
  }

  //UPDATE API
  async function update() {
    setIsClicked(true);
    const response = await updateCertificates(id, certificatesData);
    if (response.data && response.data.status === 200) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.messages.error.toUpperCase());
    }
    setTimeout(function () {
      refreshPage();
    }, 1500);
    // }
  }

  // Delete API
  async function del() {
    const response = await deleteCertificates(id);
    if (response.data?.status === 200) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.response.toUpperCase());
    }
    setShowModal(false);
    setTimeout(function () {
      refreshPage();
    }, 1500);
  }

  useEffect(() => {
    fetchCertificates();
  }, []);

  const handleSelectChange = (e) => {
    const { id, name, value } = e.target;
    setId(id);
    if (value === "update") {
      certificate(id);
    }
    if (value === "delete") {
      certificates(id);
    }
  };

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, []);

  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
      <div className="login-container">
        <div className="form-cont pt-4">
          <div className="container p-3">
            <h1 className="title">
              CERTIFICATIONS, LICENCES OR RELEVANT TRAININGS EARNED
            </h1>

            {/* FIRST ROW */}
            <Row className="">
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    NAME OF TRAINING / EXAMINATION{" "}
                    <span className="red"> *</span>
                  </p>
                  <input
                    type="text"
                    name="name"
                    className="form-control input-subtitle input-shadow"
                    id="institution"
                    aria-describedby="institution"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    INSTITUTION <span className="red"> *</span>
                  </p>
                  <input
                    type="text"
                    name="institution"
                    className="form-control input-subtitle input-shadow"
                    id="degree"
                    aria-describedby="degree"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    COMPLETION DATE <span className="red"> *</span>
                  </p>
                  <DatePicker
                    className="form-control date-picker"
                    placeholderText={"mm/dd/yyyy"}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setCertificatesDetails({
                        ...certificatesDetails,
                        completion_date: new Date(date).toLocaleDateString(
                          "en-CA",
                        ),
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center mt-4">
              <Col xs={3}>
                <button className="btn-green btn-rad py-2" onClick={add}>
                  ADD
                </button>
              </Col>
            </Row>

            <hr></hr>

            <div className="row mb-4">
              <h2 className="title">HISTORY</h2>
              <Table
                type={"certificationslicences_info"}
                tableData={certificatesHistoryData}
                clickable={false}
                headingColumns={[
                  "NAME OF TRAINING / EXAMINATION",
                  "INSTITUTION",
                  "COMPLETION DATE",
                  "ACTION",
                ]}
                onSelectChange={handleSelectChange}
                setID={setSelected}
                setAction={setAction}
                useLoader={isLoading}
                isReady={isReady}
              />
            </div>

            <Row className="mb-5">
              <div className="col mb-4 right">
                <button
                  className="back-btn btn-rad right me-2 w-10"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => navigateTo("/academic-information")}
                >
                  {" "}
                  BACK{" "}
                </button>
                <button
                  className="btn-green btn-rad right w-10"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => navigateTo("/membership")}
                >
                  {" "}
                  NEXT{" "}
                </button>
              </div>
            </Row>
          </div>
        </div>
      </div>
      <ModalPopUp
        type="delete-certificates"
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleDelete={del}
        data={certificatessData}
        setData={handleUpdateChange}
      />
      <ModalPopUp
        type="update-certificates"
        show={showModalUpdate}
        handleClose={() => setShowModalUpdate(false)}
        data={certificatesData}
        handleUpdate={update}
      />
      <ModalPopUp
        type="update-certificates"
        show={showModalUpdate}
        handleClose={() => setShowModalUpdate(false)}
        handleUpdate={update}
        data={certificatesData}
        setData={handleUpdateChange}
      />
    </div>
  );
}

Certificates.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
};

export default Certificates;
