/***************************
 * Common Utilities
 *
***************************/
import { logoutUser } from "ApiCalls/authApi";


export const formatDate = (date) => {
  var formatDate = new Date(date);
  var stringDate = formatDate.toDateString().split(" ");

  return stringDate[1] + " " + stringDate[2] + ", " + stringDate[3]
}

export const numberWithCommas = (number) => {
  if (!number) {
    return undefined;
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function refreshPage() {
  window.location.reload();
}


export const formatDateSlash = (date) => {
  //if date is null or empty string return nothin
  if (!date || date === "") {
    return "";
  }

  var stringDate = date.split("-");

  return stringDate[1] + "/" + stringDate[2] + "/" + stringDate[0];
};

/***************************
 * Local Storage Utilities
***************************/

//return user data from local storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  // if (userStr) return JSON.parse(userStr);
  return null;
};

//return user name from local storage
export const getName = () => {
  const userStr = localStorage.getItem("name");
  if (userStr) return JSON.parse(userStr);
  return null;
};

//return user type from local storage
export const getType = () => {
  const userStr = localStorage.getItem("type");
  if (userStr) return JSON.parse(userStr);
  return null;
};

//return user role from local storage
export const getRole = () => {
  const userStr = localStorage.getItem("role");
  if (userStr) return JSON.parse(userStr);
  return null;
};

//return has reset from local storage
export const getHasReset = () => {
  const userStr = localStorage.getItem("has-reset");
  if (userStr) return JSON.parse(userStr);
  return null;
};

//return sign up from local storage
export const getSignup = () => {
  const userStr = localStorage.getItem("sign_up");
  if (userStr) return JSON.parse(userStr);
  return null;
};

//return role id from local storage
export const getRoleId = () => {
  return localStorage.getItem("role_id") || null;
};

export const getDataId = () => {
  const userStr = localStorage.getItem("id");
  // if (userStr) return JSON.parse(userStr);
  return null;
};
//return token from local storage
export const getToken = () => {
  const userStr = localStorage.getItem("token");
  // if (userStr) return JSON.parse(userStr;
  return null;
};

//return token expiry from local storage
export const getTokenExpiry = () => {
  return localStorage.getItem("token_expiry") || null;
};

//return user key from local storage
export const getUserKey = () => {
  return localStorage.getItem("user-key") || null;
};

//return current branch from local storage
export const getBranch = () => {
  return localStorage.getItem("branch") || null;
};

//return api key from local storage
export const getKey = () => {
  return localStorage.getItem("api_key") || null;
}

//return current branch from local storage
export const getBranchType = () => {
  return localStorage.getItem("branch_type").replace(/['"]+/g, "") || null;
};

// Return 1 if user already responded to exit interview and 0 otherwise
export const getHasExitInterview = () => {
  const userStr = localStorage.getItem("has_exit_interview");
  // if (userStr) return JSON.parse(userStr);
  return null;
};

//remove token from local storage
export const removeUserSession = (user) => {
  // refreshPage();
  localStorage.removeItem("user", JSON.stringify(user));
  // logoutUser();
};

//remove token from local storage
export const removeSession = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("type");
  localStorage.removeItem("role");
  localStorage.removeItem("user-key");
  localStorage.removeItem("name");
};

//set the token and user from local storage
export const setUserSession = (token,api_key, user) => {
  localStorage.setItem("token", token);
  localStorage.setItem("api_key", api_key);
  localStorage.setItem("user", JSON.stringify(user));
};

export const getMonths = () => {
  const Months = [
    {value: "1", label: "January"},
    {value: "2", label: "February"},
    {value: "3", label: "March"},
    {value: "4", label: "April"},
    {value: "5", label: "May"},
    {value: "6", label: "June"},
    {value: "7", label: "July"},
    {value: "8", label: "August"},
    {value: "9", label: "September"},
    {value: "10", label: "October"},
    {value: "11", label: "November"},
    {value: "12", label: "December"},
  ]

  return Months
}

export const getUpYears = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 1908;
  const Years = [];

  for (let year = startYear; year <= currentYear; year++) {
    const yearRange = `${year}-${year + 1}`;
    Years.push({ value: yearRange, label: yearRange });
  }

  return Years.reverse();
}
export const getUpSy = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 1908;
  const Years = [];

  for (let year = startYear; year <= currentYear; year++) {
    const yearRange = `${year}`;
    Years.push({ value: yearRange, label: yearRange });
  }

  return Years.reverse();
}
