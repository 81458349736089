import { getUser, getDataId, getToken, getKey, removeSession, refreshPage } from "Helpers/Utils/Common";
import { postAPICall, postAPICallCustom } from "../axiosMethodCalls";
import toast from "react-hot-toast";

/***************************
 * USER
 ***************************/
// const api_key = getKey();
const token = getToken();
const user = getUser();
const id = getDataId();
// const userKey = getUserKey();

//POST
export const createEmploymentInformation = async (data) => {

    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_employments/add", {
            requester: user,
            // token: token,
            token: token,  
            alumni_id: user,
            is_international:data.international_company === "on" ? 1:0,
            institution_type: data.institution_type,
            sector: data.sector,
            company_institution: data.company_institution,
            position: data.position,
            specialization: data.specialization,
            monthly_salary: data.monthly_salary,
            type: data.type,
            extent: data.extent,
            description: data.description,
            job_description: data.job_description,
            is_current: data.currently_working === "on"? 1:0,
            date_started: data.date_started,
            date_ended: data.date_ended,
        });
        
        return ({data:response});   
    } catch (error) {
        if (error.response.status === 401) {
          // toast.error(
          //   "Unauthorized access: another device is using this account"
          // );
          removeSession();
          refreshPage();
        }
        return ({error: error.response});
    }
  }
  export const getEmploymentInformation = async (id) => {
    try {
        const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_employments/get", {
            requester: user,
            token: token,
            alumni_id:id
        });
        return ({data:response.data.data});   
    } catch (error) {
        if (error.response.status === 401) {
          removeSession();
          refreshPage();
        }
        return ({error: error.response});
    }
  }


export const updateEmploymentInformation = async (id, data) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_employments/update", {
          requester: user,
          token: token,
          alumni_employment_id: id,
          is_international:data.international_company === "on" ? 1:0,
          institution_type: data.institution_type,
          sector: data.sector,
          company_institution: data.company_institution,
          position: data.position,
          specialization: data.specialization,
          monthly_salary: data.monthly_salary,
          type: data.type,
          extent: data.extent,
          description: data.description,
          job_description: data.job_description,
          is_current: data.currently_working === "on"? 1:0,
          date_started: data.date_started,
          date_ended: data.date_ended,
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        // toast.error(
        //   "Unauthorized access: another device is using this account"
        // );
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

export const deleteEmploymentInformation = async (id) => {
  try {
      const response = await postAPICall(process.env.REACT_APP_LINK + "alumni_employments/delete", {
          requester: user,
          token: token,
          alumni_employment_id: id,
          // package_type_id: id,
      });
      return ({data:response});   
  } catch (error) {
      if (error.response.status === 401) {
        toast.error(
          "Unauthorized access: another device is using this account"
        );
        removeSession();
        refreshPage();
      }
      return ({error: error.response});
  }
}

//POST
export const editUser = async (info, branches, file) => {
  var branchesData = [];

  branches.map((data) => (
    branchesData.push(data.value)
  ));
  try {
    const formData = new FormData();
    //Change param name according to the asked param from API
    formData.append("file_result", file[0]);
    const response = await postAPICallCustom(
      process.env.REACT_APP_LINK + "/login",
      {
        // requester: user,
        token: token,
        username: info.username,
        password: info.password,
        last_name: info.last_name,
        first_name: info.first_name,
        middle_name: info.middle_name,
        email: info.email_address,
        type: info.user_type,
        role_id: info.role,
        branch_ids: branchesData.join(","),
      },
      {
        "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
        // "user-key": userKey,
        "Content-Type": "multipart/form-data",
      },
    );

    return { data: response };
  } catch (error) {
    return { data: error.response.data };
  }
};