import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import InputError from "../../Components/InputError/InputError";
import "./Modal.css";

const ModalPopUp = ({
  type,
  data,
  setData,
  show,
  handleDelete,
  handleAdd,
  handleUpdate,
  handleClose,
  title,
  item,
  content,
  details,
}) => {
  const showHideClassName = show
    ? "modal-pop display-block"
    : "modal-pop display-none";
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const id = useParams();
  const [datas, setDatas] = useState(data);

  /////////////////////////////////////////////////////////////////////////////////////////
  //EMPLOYMENT
  const [employmentInformationDetails, setEmploymentInformationDetails] =
    useState({
      alumni_id: id,
      institution_type: "",
      sector: "",
      company_institution: "",
      is_international: "",
      position: "",
      specialization: "",
      monthly_salary: "",
      type: "",
      extent: "",
      description: "",
      is_current: "",
      date_started: "",
      date_ended: "",
    });

  const [pledgeData, setPledgeData] = useState([
    {
      alumni_id: "",
      project_id: id,
      in_kind: "",
      monetary: "",
      anonymous: "",
      status: "",
    },
  ]);
  /////////////////////////////////////////////////////////////////////////////////
  //MEMBERSHI[P]
  const [membershipDetails, setMembershipDetails] = useState({
    alumni_org_id: id,
    name: "",
    type: "",
    is_international: "",
    membership_period: "",
    position: "",
  });

  const [isErrorMembership, setIsErrorMembership] = useState({
    alumni_org_id: false,
    name: false,
    type: false,
    is_international: false,
    membership_period: false,
    position: false,
  });

  ///////////////////////////////////////////////////////////////////////////////////
  //Academic
  const [academicData, setacademicData] = useState({});

  const [academicInformationDetails, setAcademicInformationDetails] = useState({
    alumni_academic_id: id,
    institution: "",
    degree: "",
    specialization: "",
    scholarship_grant: "",
    is_current: "",
    date_started: "",
    date_ended: "",
  });

  async function checkSelected(value) {
    var sectorOthers = document.getElementById(
      "sector-others-modal",
    ) as HTMLElement;
    if (value === "others") {
      sectorOthers.hidden = false;
    } else {
      sectorOthers.hidden = true;
    }
  }

  const handleChangeAcad = (e) => {
    const { name, value } = e.target;
    setDatas({ ...datas, [name]: value });
    setData(e);

    // var selectedChoice = (document.getElementById("sector") as HTMLInputElement).value
    // var othersBox = document.getElementById("sector-others-modal") as HTMLElement
    // if (selectedChoice === "others"){
    //   othersBox.removeAttribute("hidden")
    // } else {
    //   othersBox.setAttribute("hidden","hidden")
    // }
    checkSelected(value);
  };

  const handleChangePledge = (event) => {
    const { name, value } = event.target;
    setPledgeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // handleChangeAcad(event);
  };

  const [checked, setIsChecked] = useState(false);

  //in-kind donation textbox
  const kndHandler = () => {
    var kind = document.getElementById("in_kind_remarks") as HTMLElement;
    setIsChecked(!checked);
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    if (checked === true) {
      kind.setAttribute("hidden", "hidden");
    } else {
      kind.removeAttribute("hidden");
    }

    // Don't miss the exclamation mark
  };

  const [isError, setIsError] = useState({
    alumni_academic_id: false,
    institution: false,
    degree: false,
    specialization: false,
    scholarship_grant: false,
    is_current: false,
    date_started: false,
    date_ended: false,
    completion_date: false,
    name: false,
  });

  /////////////////////////////////////////////////////////////////////////

  //Certificates
  const [certificatesData, setCertificatesData] = useState({});
  const [addPresent, setAddPresent] = useState(false);

  const [certificatesDetails, setCertificatesDetails] = useState({
    alumni_cert_id: id,
    name: "",
    institution: "",
    completion_date: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCertificatesDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  ////////////////////////////////////////////////////////////////////

  //UPDATE (academic, employment, membership, certificates)

  if (type === "update-academic") {
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-labels center">
              UPDATE ACADEMIC INFORMATION
            </div>
          </div>
          {/* FIRST ROW */}
          <hr></hr>
          <div className="row mb-4">
            <div className="col-4">
              <div className="form-group">
                <p className="input-subtitle">
                  INSTITUTION<span className="required-icon">*</span>
                </p>
                <input
                  type="text"
                  name="institution"
                  className="form-control input-subtitle input-shadow"
                  value={data.institution}
                  id="institution"
                  aria-describedby="institution"
                  onChange={handleChangeAcad}
                />
                <InputError
                  isValid={isError.institution}
                  message={"Institution is required*"}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <p className="input-subtitle">
                  DEGREE<span className="required-icon">*</span>
                </p>
                <input
                  type="text"
                  name="degree"
                  className="form-control input-subtitle input-shadow"
                  value={data.degree}
                  id="degree"
                  aria-describedby="degree"
                  onChange={(e) => handleChangeAcad(e)}
                />
                <InputError
                  isValid={isError.degree}
                  message={"Degree is required*"}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <p className="input-subtitle">SPECIALIZATION</p>
                <input
                  type="text"
                  name="specialization"
                  className="form-control input-subtitle input-shadow"
                  value={data.specialization}
                  id="specialization"
                  aria-describedby="specialization"
                  onChange={(e) => handleChangeAcad(e)}
                />
                <InputError
                  isValid={isError.specialization}
                  message={"Specializationis required*"}
                />
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-4">
              <div className="form-group">
                <p className="input-subtitle">SCHOLARSHIP GRANTS</p>
                <input
                  type="text"
                  name="scholarship_grant"
                  className="form-control input-subtitle input-shadow"
                  value={data.scholarship_grant}
                  id="scholarship_grant"
                  aria-describedby="scholarship"
                  onChange={(e) => handleChangeAcad(e)}
                />
                <InputError
                  isValid={isError.scholarship_grant}
                  message={"Scholarship grant is required*"}
                />
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-4">
              <input
                type="checkbox"
                className="custom-control-inpu mr-10 "
                id="currently_working"
                onChange={(e) => setAddPresent(e.target.checked)}
                name="is_current"
                checked={data.is_current === "0" ? false : true}
              />
              <InputError
                isValid={isError.is_current}
                message={"is_current is required*"}
              />
              <label
                className="custom-control-label input-subtitle grey pad-left5"
                htmlFor="purchase-limit"
              >
                I AM CURRENTLY ENROLLED / IN PROGRESS{" "}
              </label>
            </div>
            <div className="col">
              <div className="row">
                <div className="col-4">
                  <label
                    className="custom-control-label input-subtitle pad-left5"
                    htmlFor="purchase-limit"
                  >
                    START{" "}
                  </label>
                </div>
                <div className="col">
                  {/* <DatePicker className="form-control input-green-bg input-subtitle input-shadow in   put-green-bg"  /> */}
                  <DatePicker
                    className="form-control input-subtitle input-shadow input-green-bg"
                    value={data.date_started}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setAcademicInformationDetails({
                        ...academicInformationDetails,
                        date_started: new Date(date).toLocaleDateString(
                          "en-US",
                        ),
                      });
                    }}
                  />
                  <InputError
                    isValid={isError.date_started}
                    message={"Date started is required*"}
                  />
                </div>
              </div>
            </div>
            <div className="col">
              {addPresent ? (
                <ul>
                  <label
                    className="custom-control-label input-subtitle left"
                    htmlFor="addPresent"
                  >
                    PRESENT
                  </label>
                </ul>
              ) : (
                <div className="row">
                  <div className="col-2">
                    <label
                      className="custom-control-label input-subtitle pad-left5 "
                      htmlFor="addPresent"
                    >
                      TO{" "}
                    </label>
                  </div>
                  <div className="col-9">
                    <DatePicker
                      name="date_ended"
                      className="form-control input-subtitle input-shadow input-green-bg"
                      selected={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                        setEmploymentInformationDetails({
                          ...employmentInformationDetails,
                          date_ended: new Date(date).toLocaleDateString(
                            "en-CA",
                          ),
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <hr></hr>
          <button
            type="button"
            className="btn-mar right "
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="label-maroon right me-4"
            onClick={handleUpdate}
          >
            Update
          </button>
        </section>
      </div>
    );
  }

  if (type === "update-certificates") {
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-labels">UPDATE CERTIFICATE</div>
          </div>
          {/* FIRST ROW */}
          <hr></hr>
          <div className="row mb-4">
            <div className="col-6">
              <div className="form-group">
                <p className="input-subtitle">NAME OF TRAINING / EXAMINATION</p>
                <input
                  type="text"
                  name="name"
                  className="form-control input-subtitle input-shadow"
                  value={data.name}
                  id="name"
                  aria-describedby="institution"
                  onChange={(e) => handleChangeAcad(e)}
                />
                <InputError
                  isValid={isError.name}
                  message={"Max Weight is required*"}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <p className="input-subtitle">INSTITUTION</p>
                <input
                  type="text"
                  name="institution"
                  className="form-control input-subtitle input-shadow"
                  value={data.institution}
                  id="degree"
                  aria-describedby="degree"
                  onChange={(e) => handleChangeAcad(e)}
                />
                <InputError
                  isValid={isError.institution}
                  message={"Max Weight is required*"}
                />
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="form-group">
              <div className="col-4">
                <p className="input-subtitle">COMPLETION DATE</p>
              </div>
              <div className="col-3">
                <DatePicker
                  className=" form-control input-subtitle input-shadow input-green-bg"
                  value={data.completion_date}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setCertificatesDetails({
                      ...certificatesDetails,
                      completion_date: new Date(date).toLocaleDateString(
                        "en-US",
                      ),
                    });
                  }}
                />
                <InputError
                  isValid={isError.completion_date}
                  message={"Max Weight is required*"}
                />
              </div>
            </div>
          </div>
          <hr></hr>
          <button
            type="button"
            className="btn-mar right "
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="label-maroon right me-4"
            onClick={handleUpdate}
          >
            Update
          </button>
        </section>
      </div>
    );
  }

  if (type === "update-employment") {
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-labels">UPDATE EMPLOYMENT</div>
          </div>
          {/* FIRST ROW */}
          <hr></hr>
          <div className="row row-form mb-4">
            <div className="col-4">
              <div className="form-group">
                <p className="input-subtitle">
                  TYPE OF INSTITUTION<span className="required-icon">*</span>
                </p>
                <select
                  className="form-control input-subtitle  input-shadow"
                  name="institution_type"
                  value={data.institution_type}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select</option>
                  <option value="freelance">Freelance</option>
                  <option value="government">Government</option>
                  <option value="nga">NGA</option>
                  <option value="ngo">NGO</option>
                  <option value="private_company">Private Company</option>
                  <option value="personal_business">Personal Business</option>
                </select>
              </div>
            </div>
            <div className="col-4">
              {/* <div className="form-group">
                        <p className="input-subtitle">SECTOR<span className='required-icon'>*</span></p>
                        <input type="text" name="sector" className="form-control input-subtitle input-shadow"  value={data.sector} id="sector" aria-describedby="sector" onChange={(e) => handleChangeAcad(e)}/>
                    </div> */}

              <div className="form-group">
                <p className="input-subtitle">
                  SECTOR<span className="required-icon">*</span>
                </p>
                <select
                  className="form-control input-subtitle  input-shadow"
                  name="sector"
                  id="sector"
                  value={data.sector}
                  aria-describedby="sector"
                  onChange={(e) => handleChangeAcad(e)}
                >
                  <option value="">Select</option>
                  <option value="accounting">Accounting</option>
                  <option value="administration">
                    Administration and Office Support
                  </option>
                  <option value="advertising">
                    Advertising, Arts and Media
                  </option>
                  <option value="banking">
                    Banking and Financial Services
                  </option>
                  <option value="call_center">
                    Call Center and Customer Service
                  </option>
                  <option value="community_services">
                    Community Services and Development
                  </option>
                  <option value="construction">Construction</option>
                  <option value="consulting">Consulting and Strategy</option>
                  <option value="architecture">Design and Architecture</option>
                  <option value="education">Education and Training</option>
                  <option value="engineering">Engineering</option>
                  <option value="farming">
                    Farming, Animals and Conservation
                  </option>
                  <option value="government">Government and Defense</option>
                  <option value="healthcare">Healthcare and Medical</option>
                  <option value="tourism">Hospitality and Tourism</option>
                  <option value="human_resources">
                    Human Resources and Recruitment
                  </option>
                  <option value="ict">
                    Information and Communication Technology
                  </option>
                  <option value="insurance">Insurance</option>
                  <option value="legal">Legal</option>
                  <option value="manufacturing">
                    Manufacturing, Transport and Logistics
                  </option>
                  <option value="marketing">
                    Marketing and Communications
                  </option>
                  <option value="mining">Mining, Resources and Energy</option>
                  <option value="real_estate">Real Estate and Property</option>
                  <option value="retail">Retail and Consumer Products</option>
                  <option value="sales">Sales</option>
                  <option value="science_tech">Science and Technology</option>
                  <option value="sports">Sports and Recreation</option>
                  <option value="trades">Trades and Services</option>
                  <option value="others">Others</option>
                </select>
                {/* <InputError isValid={isError.sector} message={"Sector is required*"}/>  */}
              </div>
              <div className="form-group mt-2" id="sector-others-modal" hidden>
                <p className="input-subtitle">Please specify: </p>
                <input
                  type="text"
                  name="sector_other"
                  className="form-control input-subtitle input-shadow"
                  id="sector"
                  aria-describedby="sector"
                  onChange={(e) => handleChangeAcad(e)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <p className="input-subtitle">
                  COMPANY<span className="required-icon">*</span>
                </p>
                <input
                  type="text"
                  name="company_institution"
                  className="form-control input-subtitle input-shadow"
                  value={data.company_institution}
                  onChange={(e) => handleChangeAcad(e)}
                  id="company"
                  aria-describedby="company"
                />
                <input
                  type="checkbox"
                  className="custom-control-inpu mr-10 "
                  id="international_company"
                  onChange={(e) => handleChangeAcad(e)}
                  name="international_company"
                  checked={data.is_international}
                />
                <label
                  className="custom-control-label input-title pad-left5"
                  htmlFor="purchase-limit"
                >
                  INTERNATIONAL COMPANY
                </label>
              </div>
            </div>
          </div>
          <div className="row row-form mb-4">
            <div className="col-4">
              <div className="form-group">
                <p className="input-subtitle">
                  POSITION/RANK<span className="required-icon">*</span>
                </p>
                <input
                  type="text"
                  name="position"
                  className="form-control input-subtitle input-shadow"
                  value={data.position}
                  id="position"
                  aria-describedby="position"
                  onChange={(e) => handleChangeAcad(e)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <p className="input-subtitle">SPECIALIZATION</p>
                <input
                  type="text"
                  name="specialization"
                  className="form-control input-subtitle input-shadow"
                  value={data.specialization}
                  id="specialization"
                  aria-describedby="specialization"
                  onChange={(e) => handleChangeAcad(e)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <p className="input-subtitle">MONTHLY SALARY RATE</p>
                <select
                  className="form-control input-subtitle  input-shadow"
                  name="monthly_salary"
                  value={data.monthly_salary}
                  onChange={(e) => handleChangeAcad(e)}
                >
                  <option value="">Select</option>
                  <option value="less_than_15">Less than P15,000</option>
                  <option value="15-24">P15,000-P24,999</option>
                  <option value="25-34">P25,000-P34,999</option>
                  <option value="35-44">P35,000-P44,999</option>
                  <option value="45-59">P45,000-P59,999</option>
                  <option value="60-100">P60,000-P100,000</option>
                  <option value="more_than_100">More than P100,000</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row row-form mb-4">
            <div className="col-4">
              <div className="form-group">
                <p className="input-subtitle">
                  TYPE OF EMPLOYMENT<span className="required-icon">*</span>
                </p>
                <select
                  className="form-control input-subtitle  input-shadow"
                  name="type"
                  value={data.type}
                  onChange={(e) => handleChangeAcad(e)}
                >
                  <option value="">Select</option>
                  <option value="full_time">Full-Time</option>
                  <option value="part_time">Part-Time</option>
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <p className="input-subtitle">
                  EXTENT OF EMPLOYMENT<span className="required-icon">*</span>
                </p>
                <select
                  className="form-control input-subtitle  input-shadow"
                  name="extent_of_employment"
                  value={data.extent}
                  onChange={(e) => handleChangeAcad(e)}
                >
                  <option value="">Select</option>
                  <option value="contractual">Contractual</option>
                  <option value="permanent">Permanent</option>
                  <option value="project_based">Project Based</option>
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <p className="input-subtitle">JOB DESCRIPTION</p>
                <input
                  type="text"
                  name="description"
                  className="form-control input-subtitle input-shadow"
                  value={data.description}
                  id="job_description"
                  aria-describedby="job_description"
                  onChange={(e) => handleChangeAcad(e)}
                />
              </div>
            </div>
          </div>
          <div className="row row-form mb-4">
            <div className="col-1 w-5">
              <input
                type="checkbox"
                className="custom-control-inpu mr-10 "
                id="currently_working"
                name="is_current"
                onChange={(e) => setAddPresent(e.target.checked)}
                checked={data.is_current === "0" ? false : true}
              />
            </div>
            <div className="col">
              <label
                className="custom-control-label input-subtitle grey pad-left5"
                htmlFor="purchase-limit"
              >
                I AM CURRENTLY WORKING ON THIS ROLE{" "}
              </label>
            </div>

            <div className="col-4">
              <div className="row row-form">
                <div className="col-4">
                  <label
                    className="custom-control-label input-subtitle pad-left5"
                    htmlFor="purchase-limit"
                  >
                    FROM <span className="required-icon">*</span>
                  </label>
                </div>
                <div className="col">
                  <DatePicker
                    className="form-control input-subtitle input-shadow input-green-bg"
                    value={data.date_started}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setEmploymentInformationDetails({
                        ...employmentInformationDetails,
                        date_started: new Date(date).toLocaleDateString(
                          "en-CA",
                        ),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col">
              {addPresent ? (
                <ul>
                  <label
                    className="custom-control-label input-subtitle left"
                    htmlFor="addPresent"
                  >
                    PRESENT
                  </label>
                </ul>
              ) : (
                <div className="row">
                  <div className="col-2">
                    <label
                      className="custom-control-label input-subtitle pad-left5 "
                      htmlFor="addPresent"
                    >
                      TO{" "}
                    </label>
                  </div>
                  <div className="col-9">
                    <DatePicker
                      name="date_ended"
                      className="form-control input-subtitle input-shadow input-green-bg"
                      selected={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                        setEmploymentInformationDetails({
                          ...employmentInformationDetails,
                          date_ended: new Date(date).toLocaleDateString(
                            "en-CA",
                          ),
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <div className="row row-form mb-0">
                <div className="col-1 w-5">
                    <input type="checkbox" className="custom-control-inpu mr-10 " id="currently_unemployed" name="is_current" onChange={(e) => handleChange(e)}/>
                </div>
                <div className="col">
                    <label className="custom-control-label input-subtitle grey pad-left5" htmlFor="purchase-limit">I AM CURRENTLY UNEMPLOYED</label>
                </div>
            </div> */}
          <hr></hr>
          <button
            type="button"
            className="btn-mar right "
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="label-maroon right me-4"
            onClick={handleUpdate}
          >
            Update
          </button>
        </section>
      </div>
    );
  }

  if (type === "update-membership") {
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-labels">UPDATE MEMBERSHIP</div>
          </div>
          {/* FIRST ROW */}
          <hr></hr>
          <div className="row row-form mb-4">
            <div className="col-6">
              <div className="form-group">
                <p className="input-subtitle">
                  NAME OF ORGANIZATION<span className="required-icon">*</span>
                </p>
                <input
                  type="text"
                  name="name"
                  className="form-control input-subtitle input-shadow"
                  value={data.name}
                  onChange={(e) => handleChangeAcad(e)}
                  id="name"
                  aria-describedby="company"
                />
                <InputError
                  isValid={isErrorMembership.name}
                  message={"Institution is required*"}
                />
                <input
                  type="checkbox"
                  className="custom-control-inpu mr-10 "
                  id="international_company"
                  value={data.is_international}
                  name="is_international"
                  onChange={(e) => handleChangeAcad(e)}
                />
                <label
                  className="custom-control-label input-title pad-left5"
                  htmlFor="purchase-limit"
                >
                  INTERNATIONAL ORGANIZATION{" "}
                </label>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <p className="input-subtitle">
                  TYPE OF ORGANIZATION<span className="required-icon">*</span>
                </p>
                <select
                  className="form-control input-subtitle  input-shadow"
                  name="type"
                  value={data.type}
                  onChange={(e) => handleChangeAcad(e)}
                >
                  <option value="">Select</option>
                  <option value="freelance">Freelance</option>
                  <option value="government">Government</option>
                  <option value="nga">NGA</option>
                  <option value="ngo">NGO</option>
                  <option value="private_company">Private Company</option>
                  <option value="personal_business">Personal Business</option>
                </select>
                <InputError
                  isValid={isErrorMembership.type}
                  message={"Institution is required*"}
                />
              </div>
            </div>
          </div>
          <div className="row row-form mb-4">
            <div className="col-6">
              <div className="form-group">
                <p className="input-subtitle">
                  PERIOD OF MEMBERSHIP<span className="required-icon">*</span>
                </p>
                <input
                  type="text"
                  name="membership_period"
                  className="form-control input-subtitle input-shadow"
                  value={data.membership_period}
                  id="membership_period"
                  aria-describedby="membership_period"
                  onChange={(e) => handleChangeAcad(e)}
                />
                <InputError
                  isValid={isErrorMembership.membership_period}
                  message={"Institution is required*"}
                />
                {/* <DatePicker className="form-control input-subtitle input-shadow input-green-bg" value={data.membership_period} selected={startDate} onChange={(date) => {setStartDate(date); setMembershipDetails({...membershipDetails, membership_period:new Date(date).toLocaleDateString("en-CA")});}} /> */}
                {/* <input type="text" name="membership_period" className="form-control input-subtitle input-shadow" value={data.membership_period} id="position" aria-describedby="position" onChange={(e) => handleChangeAcad(e)}/> */}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <p className="input-subtitle">
                  POSITION<span className="required-icon">*</span>
                </p>
                <input
                  type="text"
                  name="position"
                  className="form-control input-subtitle input-shadow"
                  value={data.position}
                  id="specialization"
                  aria-describedby="specialization"
                  onChange={(e) => handleChangeAcad(e)}
                />
                <InputError
                  isValid={isErrorMembership.position}
                  message={"Institution is required*"}
                />
              </div>
            </div>
          </div>
          <hr></hr>
          <button
            type="button"
            className="btn-mar right "
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="label-maroon right me-4"
            onClick={handleUpdate}
          >
            Update
          </button>
        </section>
      </div>
    );
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////

  //DELETE (academic, employment, membership, certificates)
  if (type === "delete-certificates") {
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-labels center">
              DELETE CERTIFICATES
            </div>
          </div>
          <hr></hr>
          <div className="row pt-3 mt-3 ">
            <label
              className="subtitle center"
              onChange={(e) => handleChangeAcad(e)}
            >
              Are you sure you want to delete{" "}
              <span className="mt-5 yellow">{data.name}</span> ?
            </label>
          </div>
          <div className="row right mt-5">
            <div className="col-4 ">
              <button
                type="button"
                className="btn-maroon me-4"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
            <div className="col-4 ">
              <button
                type="button"
                className="btn-green me-5"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </div>
    );
  }

  if (type === "delete-employment") {
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-labels center">
              DELETE EMPLOYMENT INFORMATION
            </div>
          </div>
          <hr></hr>
          <div className="row pt-3">
            <b className="subtitle center">
              Are you sure you want to delete{" "}
              <span className="yellow center">{data.institution_type} </span>?
            </b>
          </div>
          <div className="row right mt-5">
            <div className="col-4 ">
              <button
                type="button"
                className="btn-maroon me-4"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
            <div className="col-4 ">
              <button
                type="button"
                className="btn-green me-5"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </div>
    );
  }

  if (type === "delete-membership") {
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-labels center">
              DELETE MEMBERSHIP INFORMATION
            </div>
          </div>
          <hr></hr>
          <div className="row pt-3">
            <b className="subtitle center">
              Are you sure you want to delete{" "}
              <span className="yellow">{data.name}</span> ?
            </b>
          </div>
          <div className="row right mt-4">
            <div className="col-4 ">
              <button
                type="button"
                className="btn-maroon me-4"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
            <div className="col-4 ">
              <button
                type="button"
                className="btn-green me-5"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </div>
    );
  }

  if (type === "delete-academic") {
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-labels center">
              DELETE ACADEMIC INFORMATION
            </div>
          </div>
          <hr></hr>
          <div className="row ">
            <b className="subtitle  ">
              Are you sure you want to delete{" "}
              <span className="yellow">{data.institution}</span> ?
            </b>
          </div>
          <div className="row right mt-5">
            <div className="col-4 ">
              <button
                type="button"
                className="btn-maroon me-4"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
            <div className="col-4 ">
              <button
                type="button"
                className="btn-green me-5"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </div>
    );
  }
  ////////////////////////////////////////////////////////////////////////////

  //PLEDE AND RESET PASSWORD

  if (type === "reset-password") {
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-label">Reset Password</div>
          </div>
          <hr className="modal-line" />
          <div className="modal-content-body">
            <div className="input-label mt-4">Email</div>
            <input type="text" className="input-2" />

            <div className="input-label mt-4">New Password</div>
            <input type="text" className="input-2" />

            <div className="input-label mt-4">Confirm Password</div>
            <input type="text" className="input-2" />
          </div>
          <div className="btn-cont">
            <button type="button" className="btn-cancel" onClick={handleClose}>
              Cancel
            </button>
            <button type="button" className="btn-done" onClick={handleClose}>
              Done
            </button>
          </div>
        </section>
      </div>
    );
  }

  if (type === "pledge") {
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-label">MAKE A PLEDGE</div>
          </div>
          <hr/>
          {/* <div className="row">
            <div className="cont"></div>
          </div> */}

          <div className="modal-content-body center">
            <div className="row center mt-5">
              <div className="col-auto center">
                <div className="input-group mb-2 center">
                  <div className="input-group-prepend center">
                    {/* <select
                      className="c-box btns mt-0 center ms-3 me-3"
                      onChange={(e) => handleChangeAcad(e)}
                    >
                      <option className="white" value="">
                        Select
                      </option>
                      <option value="php">PHP</option>
                      <option value="aud">AUD</option>
                      <option value="usd"> USD</option>
                    </select> */}
                  </div>
                  <span className="input-group-prepend c-box btns mt-0 center ms-3 me-3">PHP</span>
                  <input
                    className="c-box btns-green mt-0 center"
                    name="monetary"
                    id="monetary"
                    placeholder="000.00"
                    aria-describedby="monetory"
                    onChange={(e) => handleChangeAcad(e)}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className=" row pt-4 ms-5 ">
            <div className=" col-6 ms-4 pt-4">
              <input
                type="checkbox"
                className="ms-5 me-2 name-t"
                name="in_kind"
                id="in_kind"
                aria-describedby="in_kind"
                onChange={(e) => {
                  handleChangeAcad(e);
                  kndHandler();
                }}
              />
              <label className="input-label name-t mt-4">IN-KIND</label>
            </div>
            <div className="row ms-5 ps-3.5 pt-2 pb-4">
              <input
                type="text"
                className="row w-75 ms-3 mt-2 answer-txt"
                name="in_kind_remarks"
                id="in_kind_remarks"
                aria-describedby="in_kind_remarks"
                onChange={(e) => handleChangeAcad(e)}
                hidden
              />
            </div>
          </div>
          <div className=" row ms-5 pt-0 ">
            <div className=" col-6 ms-4 pt-0 ">
              <input
                type="checkbox"
                className="ms-5 me-2 name-t"
                name="anonymous"
                id="anonymous"
                aria-describedby="anonymous"
                onChange={(e) => handleChangeAcad(e)}
              />
              <div className="input-label name-t mt-4">MAKE ME ANONYMOUS</div>
            </div>
          </div>
          <br className="mb-5"></br>
          <Row className="d-flex justify-content-end">
            <Col xs={2}>
              <button
                type="button"
                className="btn-mar px-4 me-0"
                onClick={handleClose}
              >
                Cancel
              </button>
            </Col>
            <Col xs={2}>
              <button
                type="button"
                className="label-maroons ms-0"
                onClick={handleAdd}
              >
                PLEDGE
              </button>
            </Col>
          </Row>
        </section>
      </div>
    );
  }

  if (type === "scholarship-pledge") {
    return (
      <div className={showHideClassName}>
        <section className="modal-main modal-reset">
          <div className="modal-cont-header d-flex justify-content-center">
            <div className="modal-header-label">MAKE A PLEDGE</div>
          </div>
          <hr/>
          {/* <div className="row">
            <div className="cont"></div>
          </div> */}

          <div className="modal-content-body center">
            <div className="row center mt-5">
              {/* <div className="col-3 center"> */}
              <div className="col-auto center">
                <div className="input-group mb-2 center">
                  <div className="input-group-prepend center">
                    {/* <span className="c-box btns mt-0 center ms-3 me-3">PHP</span> */}
                    {/* <select
                      className="c-box btns mt-0 center ms-3 me-3"
                      onChange={(e) => handleChangeAcad(e)}
                    >
                      <option className="white" value="">
                        Select
                      </option>
                      <option value="php">PHP</option>
                      <option value="aud">AUD</option>
                      <option value="usd"> USD</option>
                    </select> */}
                  </div>
                   <span className="input-group-prepend c-box btns mt-0 center ms-3 me-3">PHP</span>
                  <input
                    className="c-box btns-green mt-0 center"
                    name="monetary"
                    id="monetary"
                    placeholder="0.00"
                    aria-describedby="monetory"
                    onChange={(e) => handleChangeAcad(e)}
                  ></input>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          <div className=" row pt-4 ms-5 ">
            <div className=" col-6 ms-4 pt-4">
              <input
                type="checkbox"
                className="ms-5 me-2 name-t"
                name="in_kind"
                id="in_kind"
                aria-describedby="in_kind"
                onChange={(e) => {
                  handleChangeAcad(e);
                  kndHandler();
                }}
              />
              <label className="input-label name-t mt-4">IN-KIND</label>
            </div>
            <div className="row ms-5 ps-3.5 pt-2 pb-4">
              <input
                type="text"
                className="row w-75 ms-3 mt-2 answer-txt"
                name="in_kind_remarks"
                id="in_kind_remarks"
                aria-describedby="in_kind_remarks"
                onChange={(e) => handleChangeAcad(e)}
                hidden
              />
            </div>
          </div>
          <div className=" row ms-5 pt-0 ">
            <div className=" col-6 ms-4 pt-0 ">
              <input
                type="checkbox"
                className="ms-5 me-2 name-t"
                name="anonymous"
                id="anonymous"
                aria-describedby="anonymous"
                onChange={(e) => handleChangeAcad(e)}
              />
              <div className="input-label name-t mt-4">MAKE ME ANONYMOUS</div>
            </div>
          </div>
          {/* <div className=" row ms-5 pt-0 ">
            <div className=" col-6 ms-4 pt-0 ">
              <input
                type="checkbox"
                className="ms-5 me-2 mt-3 name-t"
                name="anonymous"
                id="anonymous"
                aria-describedby="anonymous"
                onChange={(e) => handleChangeAcad(e)}
              />
              <div className="input-label name-t mt-4">MAKE ME ANONYMOUS</div>
            </div>
          </div> */}
          <br className="mb-5"></br>
          <Row className="d-flex justify-content-end">
            <Col xs={2}>
              <button
                type="button"
                className="btn-mar px-4 me-0"
                onClick={handleClose}
              >
                Cancel
              </button>
            </Col>
            <Col xs={2}>
              <button
                type="button"
                className="label-maroons ms-0"
                onClick={handleAdd}
              >
                PLEDGE
              </button>
            </Col>
          </Row>
        </section>
      </div>
    );
  }

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        Here
        <button type="button" onClick={handleClose}>
          Close
        </button>
      </section>
    </div>
  );
};

ModalPopUp.propTypes = {
  type: PropTypes.string,
  show: PropTypes.bool,
  data: PropTypes.any,
  setData: PropTypes.any,
  handleDelete: PropTypes.any,
  handleAdd: PropTypes.any,
  handleUpdate: PropTypes.any,
  handleChangeUpdate: PropTypes.any,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  item: PropTypes.string,
  content: PropTypes.string,
  details: PropTypes.string,
};

export default ModalPopUp;
