import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Row, Col } from "react-bootstrap";
import InputError from "../../Components/InputError/InputError";

//Import Validation
import { validateAddEmploymentInformation } from "../../Helpers/Validations/EmploymentInformationValidation";

//Import APICalls
import {
  createEmploymentInformation,
  getEmploymentInformation,
  updateEmploymentInformation,
  deleteEmploymentInformation,
} from "../../ApiCalls/EmploymentInformationApi/EmploymentInformationApi";

//Import Common
import { getUser, refreshPage } from "Helpers/Utils/Common";

//Import from Component
import Navbar from "../../Components/Navbar/Navbar";
import Table from "Components/Table/Table";
import ModalPopUp from "Components/Modal/Modal";

//Import .CSS
import "./EmploymentInformation.css";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";

function EmploymentInformation({ setIsChecked, isChecked }) {
  const navigateTo = useNavigate();
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [selected, setSelected] = useState("");
  const [isCurrent, setIsCurrent] = useState(true);
  const [isInternational, setIsInternational] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [id, setId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [employmentHistoryData, setEmploymentHistoryData] = useState([]);
  const [employmentData, setEmploymentData] = useState({});
  const [employmentDatas, setEmploymentDatas] = useState({});
  const [addPresent, setAddPresent] = useState(false);

  const [employmentInformationDetails, setEmploymentInformationDetails] =
    useState({
      alumni_employment_id: id,
      institution_type: "",
      sector: "",
      company_institution: "",
      is_international: "",
      position: "",
      specialization: "",
      monthly_salary: "",
      type: "",
      extent: "",
      description: "",
      is_current: "",
      date_started: "",
      date_ended: "",
    });

  //GET API
  async function fetchEmploymentInformation() {
    setIsLoading(true);
    setEmploymentHistoryData([]);
    const response = await getEmploymentInformation();
    if (response.data) {
      const res = response.data
        .filter((details) => details.alumni_id.includes(getUser()))
        .map((info) => {
          return info;
        });
      setEmploymentHistoryData(res);
      if (res.length > 0) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    } else {
      setIsLoading(true);
    }
  }

  async function employmentInformation(id) {
    const response = await getEmploymentInformation(id);
    if (response.data) {
      setEmploymentData(response.data);
      setShowModalUpdate(true);
    }
  }

  async function Employment(id) {
    const response = await getEmploymentInformation(id);
    if (response.data) {
      setEmploymentDatas(response.data);
      setShowModal(true);
    }
  }

  async function checkSelected() {
    var selectedOption = (document.getElementById("sector") as HTMLInputElement)
      .value;
    var othersBox = document.getElementById("sector-others") as HTMLElement;

    if (selectedOption === "others") {
      othersBox.removeAttribute("hidden");
    } else {
      othersBox.setAttribute("hidden", "hidden");
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmploymentInformationDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    checkSelected();
  };

  const handleIsCurrent = (e) => {
    setIsCurrent(!isCurrent);
    var value = "";
    if (isCurrent === true) {
      value = "on";
    } else {
      value = "";
    }
    setEmploymentInformationDetails((prevState) => ({
      ...prevState,
      currently_working: value,
    }));
  };

  const handleIsInternational = (e) => {
    setIsInternational(!isInternational);
    var value = "";
    if (isInternational === true) {
      value = "on";
    } else {
      value = "";
    }
    setEmploymentInformationDetails((prevState) => ({
      ...prevState,
      international_company: value,
    }));
  };

  //REQUIRED ERROR HANDLING
  const [isError, setIsError] = useState({
    alumni_employment_id: false,
    institution_type: false,
    sector: false,
    company_institution: false,
    is_international: false,
    position: false,
    specialization: false,
    monthly_salary: false,
    type: false,
    extent: false,
    description: false,
    is_current: false,
    date_started: false,
    date_ended: false,
  });
  const handleUpdateChange = (e) => {
    setEmploymentData({ ...employmentData, [e.target.name]: e.target.value });
    setEmploymentDatas({ ...employmentData, [e.target.name]: e.target.value });
  };

  //ADD API
  async function add() {
    if (
      validateAddEmploymentInformation(employmentInformationDetails, setIsError) && !isClicked
    ) {
      setIsClicked(true);
      const response = await createEmploymentInformation(
        employmentInformationDetails,
      );
      if (response.data && response.data.status === 200) {
        toast.success(response.data.data.response.toUpperCase());
        toast.success("EMPLOYMENT INFORMATION IS SUCCESSFULLY ADDED!");
      } else {
        toast.error(response.error.data.messages.error.toUpperCase());
        toast.success("EMPLOYMENT INFORMATION IS ADDED UNSUCCESSFULLY!");
      }
      setTimeout(function () {
        refreshPage();
        //   setSelected("cancel")
      }, 500);
    }
  }

  //UPDATE API
  async function update() {
    const response = await updateEmploymentInformation(id, employmentData);
    if (response.data && response.data.status === 200) {
      setIsClicked(true);
      toast.success(response.data.data.response.toUpperCase());
      setTimeout(function () {
        refreshPage();
      }, 500);
    } else {
      toast.error(response.error.data.messages.error.toUpperCase());
    }
  }

  const handleSelectChange = (e) => {
    const { id, name, value } = e.target;
    setId(id);
    if (value === "update") {
      employmentInformation(id);
    }
    if (value === "delete") {
      Employment(id);
    }
  };

  //Delete API
  async function del() {
    const response = await deleteEmploymentInformation(id);
    if (response.data?.status === 200) {
      toast.success(response.data.data.response.toUpperCase());
    } else {
      toast.error(response.error.data.response.toUpperCase());
    }
    setShowModal(false);
    setTimeout(function () {
      refreshPage();
    }, 500);
  }

  useEffect(() => {
    fetchEmploymentInformation();
  }, []);

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, []);

  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
      <div className="login-container">
        <div className="form-cont pt-4">
          <div className="container p-3">
            <h1 className="title">EMPLOYMENT INFORMATION</h1>

            {/* FIRST ROW */}
            <Row>
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    TYPE OF INSTITUTION<span className="required-icon">*</span>
                  </p>
                  <select
                    className="form-control input-subtitle  input-shadow"
                    name="institution_type"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select</option>
                    <option value="freelance">Freelance</option>
                    <option value="government">Government</option>
                    <option value="nga">NGA</option>
                    <option value="ngo">NGO</option>
                    <option value="private_company">Private Company</option>
                    <option value="personal_business">Personal Business</option>
                  </select>
                  <InputError
                    isValid={isError.institution_type}
                    message={"This field is required"}
                  />
                </div>
              </Col>

              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    SECTOR<span className="required-icon">*</span>
                  </p>
                  <select
                    className="form-control input-subtitle  input-shadow"
                    name="sector"
                    id="sector"
                    aria-describedby="sector"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select</option>
                    <option value="accounting">Accounting</option>
                    <option value="administration">
                      Administration and Office Support
                    </option>
                    <option value="advertising">
                      Advertising, Arts and Media
                    </option>
                    <option value="banking">
                      Banking and Financial Services
                    </option>
                    <option value="call_center">
                      Call Center and Customer Service
                    </option>
                    <option value="community_services">
                      Community Services and Development
                    </option>
                    <option value="construction">Construction</option>
                    <option value="consulting">Consulting and Strategy</option>
                    <option value="architecture">
                      Design and Architecture
                    </option>
                    <option value="education">Education and Training</option>
                    <option value="engineering">Engineering</option>
                    <option value="farming">
                      Farming, Animals and Conservation
                    </option>
                    <option value="government">Government and Defense</option>
                    <option value="healthcare">Healthcare and Medical</option>
                    <option value="tourism">Hospitality and Tourism</option>
                    <option value="human_resources">
                      Human Resources and Recruitment
                    </option>
                    <option value="ict">
                      Information and Communication Technology
                    </option>
                    <option value="insurance">Insurance</option>
                    <option value="legal">Legal</option>
                    <option value="manufacturing">
                      Manufacturing, Transport and Logistics
                    </option>
                    <option value="marketing">
                      Marketing and Communications
                    </option>
                    <option value="mining">Mining, Resources and Energy</option>
                    <option value="real_estate">
                      Real Estate and Property
                    </option>
                    <option value="retail">Retail and Consumer Products</option>
                    <option value="sales">Sales</option>
                    <option value="science_tech">Science and Technology</option>
                    <option value="sports">Sports and Recreation</option>
                    <option value="trades">Trades and Services</option>
                    <option value="others">Others</option>
                  </select>
                  <InputError
                    isValid={isError.sector}
                    message={"This field is required"}
                  />
                </div>
                <div className="form-group mt-2" id="sector-others" hidden>
                  <p className="input-subtitle">Please specify: </p>
                  <input
                    type="text"
                    name="sector_others"
                    className="form-control input-subtitle input-shadow"
                    id="sector_others"
                    aria-describedby="sector_others"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>

              <Col xs={12} md={6} lg={4} className="mt-4">
                <Row>
                  <div className="form-group">
                    <p className="input-subtitle">
                      COMPANY/INSTITUTION<span className="required-icon">*</span>
                    </p>
                    <input
                      type="text"
                      name="company_institution"
                      className="form-control input-subtitle input-shadow"
                      id="company"
                      aria-describedby="company"
                      onChange={(e) => handleChange(e)}
                    />
                    <InputError
                      isValid={isError.company_institution}
                      message={"This field is required"}
                    />  
                  </div>
                </Row>
                <Row>
                  <Col className="">
                    <input
                      type="checkbox"
                      className="custom-control-input left"
                      id="is_international"
                      onChange={(e) => handleIsInternational(e)}
                      name="is_international"
                    />
                    <label
                      className="custom-control-label input-title ms-2"
                      htmlFor="purchase-limit"
                      >
                      INTERNATIONAL COMPANY
                    </label>
                </Col>
                </Row>
              </Col>
            {/* </Row>
            
            <Row> */}
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    POSITION/RANK<span className="required-icon">*</span>
                  </p>
                  <input
                    type="text"
                    name="position"
                    className="form-control input-subtitle input-shadow"
                    id="position"
                    aria-describedby="position"
                    onChange={(e) => handleChange(e)}
                  />
                  <InputError
                    isValid={isError.position}
                    message={"This field is required"}
                  />
                </div>
              </Col>

              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">SPECIALIZATION</p>
                  <input
                    type="text"
                    name="specialization"
                    className="form-control input-subtitle input-shadow"
                    id="specialization"
                    aria-describedby="specialization"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>

              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">MONTHLY SALARY RATE</p>
                  <select
                    className="form-control input-subtitle  input-shadow"
                    name="monthly_salary"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select</option>
                    <option value="less_than_15">Less than P15,000</option>
                    <option value="15-24">P15,000-P24,999</option>
                    <option value="25-34">P25,000-P34,999</option>
                    <option value="35-44">P35,000-P44,999</option>
                    <option value="45-59">P45,000-P59,999</option>
                    <option value="60-100">P60,000-P100,000</option>
                    <option value="more_than_100">More than P100,000</option>
                  </select>
                </div>
              </Col>
            {/* </Row>

            <Row className="mt-5"> */}
              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    TYPE OF EMPLOYMENT<span className="required-icon">*</span>
                  </p>
                  <select
                    className="form-control input-subtitle  input-shadow"
                    name="type"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select</option>
                    <option value="full_time">Full-Time</option>
                    <option value="part_time">Part-Time</option>
                  </select>
                  <InputError
                    isValid={isError.type}
                    message={"This field is required"}
                  />
                </div>
              </Col>

              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">
                    EXTENT OF EMPLOYMENT<span className="required-icon">*</span>
                  </p>
                  <select
                    className="form-control input-subtitle  input-shadow"
                    name="extent"
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select</option>
                    <option value="contractual">Contractual</option>
                    <option value="permanent">Permanent</option>
                    <option value="project_based">Project Based</option>
                  </select>
                  <InputError
                    isValid={isError.extent}
                    message={"This field is required"}
                  />
                </div>
              </Col>

              <Col xs={12} md={6} lg={4} className="mt-4">
                <div className="form-group">
                  <p className="input-subtitle">JOB DESCRIPTION</p>
                  <input
                    type="text"
                    name="description"
                    className="form-control input-subtitle input-shadow"
                    id="description"
                    aria-describedby="job_description"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={4} md={12} className="mt-4">
                <input
                  type="checkbox"
                  className="custom-control-input left"
                  id="is_current"
                  checked={addPresent}
                  onChange={(e) => {
                    handleIsCurrent(e);
                    setAddPresent(e.target.checked);
                  }}
                  name="is_current"
                />
                <label
                  className="custom-control-label input-subtitle grey ms-1"
                  id="current"
                  htmlFor="addPresent"
                >
                  I AM CURRENTLY WORKING ON THIS ROLE
                </label>
              </Col>
              <Col xs={10} lg={4} md={6} className="mt-4">
                <Row className="">
                  <Col xs={2} className="">
                    <label
                      className="custom-control-label input-subtitle pad-left5"
                      htmlFor="purchase-limit"
                    >
                      FROM
                    </label>
                  </Col>
                  <Col className="">
                    <DatePicker
                      name="date_started"
                      className="form-control date-picker"
                      placeholderText={"mm/dd/yyyy"}
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setEmploymentInformationDetails({
                          ...employmentInformationDetails,
                          date_started: new Date(date).toLocaleDateString(
                            "en-CA",
                          ),
                        });
                      }}
                    />
                    <InputError
                      isValid={isError.date_started}
                      message={"This field is required*"}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={10} lg={4} md={6} className="mt-4">
                <Row className="">
                  <div className="col">
                    {addPresent ? (
                      <ul>
                        <label
                          className="custom-control-label input-subtitle left"
                          id="date_ended"
                          htmlFor="addPresent"
                        >
                          PRESENT
                        </label>
                      </ul>
                    ) : (
                      <Row className="">
                        <Col xs={2} className="">
                          <label
                            className="custom-control-label input-subtitle pad-left5 "
                            htmlFor="addPresent"
                          >
                            TO
                          </label>
                        </Col>
                        <Col className="">
                          <DatePicker
                            name="date_ended"
                            className="form-control date-picker"
                            placeholderText={"mm/dd/yyyy"}
                            selected={endDate}
                            onChange={(date) => {
                              setEndDate(date);
                              setEmploymentInformationDetails({
                                ...employmentInformationDetails,
                                date_ended: new Date(date).toLocaleDateString(
                                  "en-CA",
                                ),
                              });
                            }}
                          />
                          <InputError
                            isValid={isError.date_ended}
                            message={"This field is required"}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
            
            <Row className="justify-content-center mt-5">
                <Col xs={3}>
                    <button
                    className="btn-green py-2"
                    onClick={add}
                    >
                    ADD
                    </button>
                </Col>
            </Row>
            <hr></hr>
            <h2 className="title">HISTORY</h2>
            <div className="row row-form center mb-4">
              <Table
                type={"employment_info"}
                tableData={employmentHistoryData}
                clickable={false}
                headingColumns={[
                  "COMPANY/INSTITUTION",
                  "POSITION/RANK",
                  "START DATE",
                  "END DATE",
                  "ACTION",
                ]}
                onSelectChange={handleSelectChange}
                setID={setSelected}
                useLoader={isLoading}
                isReady={isReady}
              />
            </div>
        
            {/* <Row>
              <Col xs={8}></Col>
              <Col xs={2}>
                <button
                  className="back-btn"
                  onClick={() => navigateTo("/exit-interviewpage3")}
                >
                  BACK
                </button>
              </Col>
              <Col xs={2}>
                <button
                  className="btn-green"
                  onClick={() => navigateTo("/academic-information")}
                >
                  NEXT
                </button>
              </Col>
            </Row> */}
            <Row className="">
                <div className='col right'>
                    <button className="back-btn right me-2" onClick={() => navigateTo("/exit-interviewpage3")}> BACK </button>
                    <button className="btn-green right" onClick={()=>navigateTo("/academic-information")}> NEXT </button>
                </div>
            </Row>
          </div>
        </div>
      </div>
      <ModalPopUp
        type="delete-employment"
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleDelete={del}
        data={employmentDatas}
        setData={handleUpdateChange}
      />

      <ModalPopUp
        type="update-employment"
        show={showModalUpdate}
        handleClose={() => setShowModalUpdate(false)}
        handleUpdate={update}
        data={employmentData}
        setData={handleUpdateChange}
      />
    </div>
  );
}

EmploymentInformation.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
};

export default EmploymentInformation;
