import Navbar from "../../Components/Navbar/Navbar";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import dashhead from "../../Assets/Images/Dashboard/dashboard_head.png";
import sendicon from "../../Assets/Images/send.png";
import moment from "moment";
import { getUser } from "Helpers/Utils/Common";
import ModalPopUp from "../../Components/Modal/Modal";
import {
  getProjects,
  createPledge,
  createProjectMessage,
} from "../../ApiCalls/Projects/ProjectsApi";
import { ToastContainer, toast } from "react-toastify";
import { refreshPage } from "Helpers/Utils/Common";
import "./Projects.css";
import "react-datepicker/dist/react-datepicker.css";

function StudentCard({ setIsChecked, isChecked }) {
  const navigate = useNavigate();
  const passedParamter = useParams();
  const projectId = passedParamter.id;
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [projectData, setProjectData] = useState({
    project_name: "",
    budget: "",
    description: "",
    date: "",
    project_head: "",
    contact_person: "",
    tag_1: "",
    tag_2: "",
    tag_3: "",
    tag_4: "",
    tag_5: "",
  });


  const [pledgeData, setPledgeData] = useState({
    alumni_id: getUser(),
    project_id: projectId,
    scholarship_id: null,
    in_kind: false,
    anonymous: false,
    status: "",
  });

  const [message, setMessage] = useState({
    project_id: projectId,
    message: ""
  });


  const handlePledge = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const { id, checked } = e.target;
    let newData = {...pledgeData};
    if (id === "in_kind" || id === "anonymous") {
      newData[id] = checked;
    } else {
      newData[name] = value;
    }
    setPledgeData(newData);
  };

  async function fetchProjects() {
    const response = await getProjects(projectId);
    if (response.data) {
      setProjectData(response.data[0]);
    }
  }

  const handleChangeMessage = (e) => {
    const { name, value } = e.target;
    let newData = {...message};
    newData[name] = value;
    setMessage(newData);
  };

  //ADD API
  async function addPledge() {
    console.log(pledgeData);
    const response = await createPledge(pledgeData);
    if (response.data && response.data.status === 200) {
      setIsClicked(true);
      toast.success(response.data.data.response.toUpperCase());
      // setTimeout(function () {
      //   refreshPage();
      // }, 1500);
    } else {
      setIsClicked(false);
      toast.error(response.error.data.messages.error.toUpperCase());
    }
  }

  //ADD API
  async function addMessage() {
    const response = await createProjectMessage(projectId, message);
    if (response.data && response.data.status === 200) {
      setIsClicked(true);
      toast.success(response.data.data.response.toUpperCase());
      setTimeout(() => {
        navigate(-1);
        setTimeout(() => refreshPage(), 500)}, 500);
    } else {
      setIsClicked(false);
      toast.error(response.error.data.messages.error.toUpperCase());
    }
  }

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);

    fetchProjects();
  }, []);

  if (redirect === "back") {
    return <Navigate to="/projects" />;
  }

  return (
    <div className="page">
      <ToastContainer />
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />

      <div className="ml-100 pt-0">
        <div className="container pt-0 ml-0 pl-0 pr-0 ml--50">
          <div className="row mb-4 pl-0 pr-0 dash">
            <img src={dashhead} alt="dashboard-header" />
            <div className=" pt-0 dash-containerss form-control top-br">
              <div className="row">
                <div className="row p-40">
                  <h1 className="scholarship-name pl-0 ms-5">
                    {projectData.project_name
                      ? projectData.project_name.toLocaleUpperCase()
                      : ""}
                  </h1>

                  <div className="row ms-4">
                    <div className="col-2 left me-5">
                      <h3 className="form-control input-subtitle input-shadow input-green-bg">
                        {projectData.date?moment(projectData.date).format("MMMM DD, YYYY"):""}
                      </h3>
                    </div>
                    <div className="row right">
                      <Row className="mt-4">
                        {projectData.tag_1 ? (
                          <Col className="label-gold ms-1 me-1 mt-2">
                            {projectData.tag_1}
                          </Col>
                        ) : (
                          <p></p>
                        )}

                        {projectData.tag_2 ? (
                          <Col className="maroon-tag ms-1 me-1 mt-2">
                            {projectData.tag_2}
                          </Col>
                        ) : (
                          <p></p>
                        )}

                        {projectData.tag_3 ? (
                          <Col className="green-tag ms-1 me-1 mt-2">
                            {projectData.tag_3}
                          </Col>
                        ) : (
                          <p></p>
                        )}

                        {projectData.tag_4 ? (
                          <Col className="label-gold ms-1 me-1 mt-2">
                            {projectData.tag_4}
                          </Col>
                        ) : (
                          <p></p>
                        )}

                        {projectData.tag_5 ? (
                          <Col className="maroon-tag ms-1 me-1 mt-2">
                            {projectData.tag_5}
                          </Col>
                        ) : (
                          <p></p>
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Row className="mt-5 mb-3 pt-5">
              <Col className="ms-5 label input-subtitle">
                <h4>
                  PROJECT HEAD:{" "}
                  {projectData.project_head
                    ? projectData.project_head.toUpperCase()
                    : ""}
                </h4>
              </Col>
              <Col className="d-flex justify-content-end me-4">
                <h1 className="title">PHP {projectData.budget}</h1>
              </Col>
            </Row>

            <Container className="ms-5 pt-0 about-section-container">
              <Row>
                <h3 className="title pt-3 ms-5">ABOUT</h3>
              </Row>
              <Row className="px-4 description-container mx-4 py-3">
                {projectData.description}
              </Row>
            </Container>

            <div className="row right row-form mt-3">
              <div className="col-2  me-3">
                <button
                  className="label-maroon btn-rad right"
                  onClick={() => setShowModal(true)}
                >
                  PLEDGE
                </button>
              </div>
            </div>

            <Row className="ms-3">
              <h3 className="title pt-3">SEND US A MESSAGE</h3>
            </Row>
            <Container className="ms-5 pt-0 about-section-container">
              <Row>
                <h3 className="title pt-3">
                  <img alt="send" className="ms-3 send" src={sendicon}></img>
                  <p className="me-3 name-t input-subtitle ms-3 pt-3">
                    {projectData.contact_person
                      ? projectData.contact_person.toLocaleUpperCase()
                      : ""}
                  </p>
                </h3>
              </Row>
              <Row className="mx-2 py-3">
                <textarea
                  className="pt-2 input-send-text"
                  placeholder=" Type message..."
                  name="message"
                  onChange={(e) => handleChangeMessage(e)}
                />
              </Row>
              <Row className="me-1">
                <Col xs={12} className="me-5 mt-1">
                  <button
                    className="btn-maroon btn-rad right w-13"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={addMessage}
                  >
                    SEND
                  </button>
                </Col>
              </Row>
            </Container>

            <div className="row row-form mt-3">
              <div className="col right">
                <button
                  className="btn-green btn-rad right me-4 w-13"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => setRedirect("back")}
                >
                  BACK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopUp
        type="pledge"
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleAdd={addPledge}
        data={pledgeData}
        setData={handleChange}
      />
    </div>
  );
}

StudentCard.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
};

export default StudentCard;
