import Header from "../../Components/Header/Header";
import Navbar from "../../Components/Navbar/Navbar";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import "./ExitInterview.css";
import { getSignup, refreshPage } from "../../Helpers/Utils/Common";
import Table from "Components/Table/Table";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import {getExitInterview, createExitInterview} from "../../ApiCalls/ExitInterviewApi/ExitInterviewApi";
import {toast, ToastContainer} from "react-toastify";
import {validateAddExitInterviewInformation} from "../../Helpers/Validations/ExitInterview"
import InputError from "../../Components/InputError/InputError";

type LocationState = {

    state: {
        plans: Object,
        responses: Object,
    }
  }

function ExitInterviewPage2({ setIsChecked, isChecked }) {
  const [inactive, setInactive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const navigateto = useNavigate()
  const location = useLocation()
  const {state} = location as LocationState
  const [isClicked, setIsClicked] = useState(false);
  const [selected, setSelected] = useState("");
  const [redirect, setRedirect] = useState("");
  const [questions, setQuestions] = useState ([
    {id: "",
question: "",
}
  ]) 

const[responses, setResponses] = useState([{
    id:"", question:"", response:"",
  }
])
const [isError, setIsError] = useState({
  optionalInfo: false,
});

  async function fetchQuestions(){
    setQuestions([]);
  
   const response = await getExitInterview();
    if(response) {
          response.data.data.map((info)=>{
            info.response = "";
        });
        let result = response.data.data.filter((a,key) => key > 10)
        setResponses(result)
        setQuestions(result);
    }
}
const handleChange = (e) => {
    const { id, name, value } = e.target;
    responses.forEach(data=> {
        if(data.id===id){
            data.response = value
        }
    })
  }

  async function add() {
    if(validateAddExitInterviewInformation(responses, setIsError)) {
    setIsClicked(true);
    var answer = {};
    responses.map(data =>(
        answer[`response_${data.id}`] = data.response
    ))
    var responsesAll = {...answer, ...state.responses}
    if(getSignup() === "sign-up"){
      navigateto("/registration-exit-interviewpage3", {state: {plans:state.plans, responses:responsesAll}})
    }else{
      navigateto("/exit-interviewpage3", {state: {plans:state.plans, responses:responsesAll}})
    }
    }
  }

useEffect(() => {
    fetchQuestions();
  },[]);

  React.useEffect(() => {
    let loadingTime = setTimeout(() => {
      setIsReady(true);
    }, 5000);
  }, []);

  if (redirect === "next") {
    return <Navigate to="/exit-interviewpage3" />;
}

  return (
    <div className="page">
      {getSignup() === "sign-up" ? "" :
      <Navbar
        onCollapse={(e) => {
          setInactive(e);
        }}
        active={"DASHBOARD"}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
      }
      <div className="login-container">
      <div className={getSignup() === "sign-up" ? "form-cont-1 pt-4" : "form-cont pt-4"}>
          <div className="container pt-0">
            <div className="row">
            <h1 className="col-4 title">EXIT INTERVIEW</h1>
            <div className="col-8 message">
            <h4 className="message">CONGRATULATIONS! WE HOPE THAT YOUR STAY IN UP CEBU HAS BEEN A HELPFUL LEARNING EXPERIENCE. BEFORE YOU LEAVE THE UNIVERSITY AND PURSUE YOUR CHOSEN CAREER, PLEASE LET US KNOW ABOUT YOUR GENERAL PREPAREDNESS IN YOUR CAREER LIFE, ABOUT YOUR COLLEGE EXPERIENCE, AND FUTURE PLANS.</h4>
            </div>
            <div className="row mt-4">
            <h3 className="header-2">PLEASE RATE THE SKILLS DEVELOPED OR ENHANCED DURING YOUR STAY IN UP CEBU BY CHECKING THE APPROPRIATE BOX:</h3>
            </div>
            {/* FIRST ROW */}
                <td></td>
                    <div className="form-group">
                        <p className="input-subtitle mt-4">ACADEMIC SKILLS</p>
                        {questions.map((data,key)=>{
                    return (
                      <>
                        <div className="row mt-4">
                            <div className="col">
                                <label>{data.question}</label>
                            </div>
                            <div className="col-5">
                                <select
                                    className="form-control input-subtitle  input-shadow"
                                    name="responses"
                                    id={data.id}
                                    onChange={handleChange}
                                    >
                                    <option value="">Select</option>
                                    <option value="poor">POOR</option>
                                    <option value="fair">FAIR</option>
                                    <option value="good">GOOD</option>
                                    <option value="very_good">VERY GOOD</option>
                                    <option value="excellent">EXCELLENT</option>
                                </select> 
                                <InputError isValid={isError.optionalInfo} message={"Required*"}/> 
                            </div>
                            </div>
                            </>
                    )})}
                    </div>
            </div>
            <div className="row mt-5">
                <div className='col-12'>
                    <button className="btn-green btn-rad right w-13" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={add}> NEXT </button>
                </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
}

ExitInterviewPage2.propTypes = {
  setIsChecked: PropTypes.any,
  isChecked: PropTypes.bool,
  
};

export default ExitInterviewPage2;
